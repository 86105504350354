import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { withRouter } from 'react-router';
import InfiniteScroll from 'react-infinite-scroll-component';

import Spinner from './Spinner'
import * as schema from '../schema';
import { PointCard, LinkedClaimArrow } from './Point';
import PropTypes from 'prop-types'

export class PointListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideIrrelevant: true
    }
  }

  get parentPoint() {
    return this.props.parentPoint;
  }

  prefix = () => this.props.prefix || ''

  isLatestQuickCreate = (point) => (point.url === this.props.latestQuickCreate)

  isSingleEvidence = () => this.props.isSingleEvidenceChild

  badgeForPoint = (point) => {
    let featuredPoint = this.props.featuredPoint
    return featuredPoint && point && (featuredPoint.id === point.id) && "Featured"
  }

  renderPoint = (point, {badge, lastInList} = {}) => {
    return <PointCard key={point.url} url={point.url} point={point} latestQuickCreate={this.isLatestQuickCreate(point)}
                      parentPoint={this.parentPoint} prefix={this.prefix()} lastInList={lastInList}
                      onDelete={this.props.onDelete} badge={badge || this.badgeForPoint(point)}
                      showUpstreamLinks={this.props.showUpstreamLinks}
                      isSingleEvidenceChild={this.isSingleEvidence()}
                      isPrimaryClaimView={this.props.isPrimaryClaimView} />
  }

  renderEdge = (edge, {lastInList}) => {
    return <PointCard key={edge.node.url} point={edge.node} url={edge.node.url} latestQuickCreate={this.isLatestQuickCreate(edge.node)}
                      link={edge.link} parentPoint={this.parentPoint} prefix={this.prefix()}
                      onDelete={this.props.onDelete}
                      parentSupportsOriginalClaim={this.props.parentSupportsOriginalClaim}
                      isSingleEvidenceChild={this.isSingleEvidence()}
                      lastInList={lastInList} />
  }

  renderPoints = (points) => points.map((point, i) => this.renderPoint(point, {lastInList: (i + 1) === points.length}))

  renderInfinitePoints = (points) => (
      <InfiniteScroll dataLength={points.length} next={this.props.loadMorePoints} hasMore={this.props.hasMore}
                      loader={<div className="spinnerPointList"><Spinner /></div>} endMessage={<span className="pointListEndMessage">You've reached the end. Time to start a new argument!</span>}>
        {this.renderPoints(points)}
      </InfiniteScroll>
  )

  renderPointsWithMoreLink = (points) => <div>
    {this.renderPoints(points)}
    <a onClick={this.props.loadMorePoints}>Load More...</a>
  </div>

  renderEdges = (edges) => edges.map((edge, i) => this.renderEdge(edge, {lastInList: (i + 1) == edges.length}))

  showIrrelevant = () => this.setState({hideIrrelevant: false})

  hideIrrelevant = () => this.setState({hideIrrelevant: true})

  relevanceThreshold = () => this.props.relevanceThreshold

  renderShowIrrelevantLink = () => <div className="listedClaimGroup">
    <LinkedClaimArrow />
    <a className="toggleLowRelClaims" onClick={this.showIrrelevant}>Show Points Below <span className="number">{this.relevanceThreshold()}%</span> Relevance</a>
  </div>

  renderHideIrrelevantLink = () => <div className="listedClaimGroup">
    <LinkedClaimArrow />
    <a className="toggleLowRelClaims" onClick={this.hideIrrelevant}>Hide Points Below <span className="number">{this.relevanceThreshold()}%</span> Relevance</a>
  </div>

  renderRelevantEdges = () => {
    const threshold = this.relevanceThreshold()
    if (threshold) {
      let relevantEdges = this.props.edges.filter(({link: {relevance, stale}}) => relevance > threshold & !stale)
      if (relevantEdges.length != this.props.edges.length) {
        return <div>
          {this.renderEdges(this.state.hideIrrelevant ? relevantEdges : this.props.edges)}
          <span className="noRelevantClaimsArea">
            {(this.state.hideIrrelevant && relevantEdges.length == 0) && <span className="noRelevantClaimsMessage">No Relevant Points, so far.</span>}
            {this.state.hideIrrelevant ? this.renderShowIrrelevantLink() : this.renderHideIrrelevantLink()}
          </span>
        </div>
      } else {
        return this.renderEdges(this.props.edges)
      }
    } else {
      return this.renderEdges(this.props.edges)
    }
  }


  render(){
    if (this.props.point) {
      return this.renderPoint(this.props.point, {badge: this.props.badge});
    } else if (this.props.points) {
      if (this.props.loadMorePoints) {
        if (this.props.infiniteScroll) {
          return this.renderInfinitePoints(this.props.points)
        } else {
          return this.renderPointsWithMoreLink(this.props.points)
        }
      } else {
        return <div>
          {this.renderPoints(this.props.points)}
        </div>
      }
    } else if (this.props.loading || (this.props.data && this.props.data.loading)) {
      return <div className="spinnerPointList"><Spinner /></div>
    } else if (this.props.data && this.props.data.point) {
      return this.renderPoint(this.props.data.point);
    } else if (this.props.edges) {
      return this.renderRelevantEdges()
    } else {
      return <div/>
    }
  }
}

PointListComponent.propTypes = {
  featuredPoint: PropTypes.object,
  parentPoint: PropTypes.object,
  point: PropTypes.object,
  points: PropTypes.array,
  edges: PropTypes.array,
  badge: PropTypes.string,
  prefix: PropTypes.string,
  relevanceThreshold: PropTypes.number,
  latestQuickCreate: PropTypes.bool,
  showUpstreamLinks: PropTypes.bool,
  isPrimaryClaimView: PropTypes.bool,
  parentSupportsOriginalClaim: PropTypes.bool,
  isSingleEvidenceChild: PropTypes.bool,
  onDelete: PropTypes.func,
  data: PropTypes.object,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  infiniteScroll: PropTypes.bool,
  loadMorePoints: PropTypes.func
}

export const PointList = compose(
  withRouter,
  graphql(schema.GetPoint, {
    skip: ({featuredPoint}) => !featuredPoint,
    options: ({featuredPoint}) => ({variables: {url: featuredPoint.url}})
  })
)(PointListComponent);

export const PointListWithPoint = graphql(schema.GetPoint)(PointList);
