import React from 'react'
import MediaQuery from 'react-responsive';
import config from '../config'
import * as validations from '../validations';
import Form from './formNew.js';
import TitleText from './TitleText'
import Spinner from './Spinner'
import { gtmEvent } from '../gtm'
import { fbq } from '../fb'

export default class QuickCreateClaim extends React.Component {
  static focus(){
    const field = document.getElementById("newPointTextField");
    field && field.focus();
  }

  state = {submitting: false}

  submit = (values) => {
    this.setState({submitting: true});
    //ga('send', 'event', 'Main Page Action', 'Quick Create Point', 'Main Page Make A Point');
    //ga('send', 'event', 'Point', 'Create Point', 'Create Point Main Page');
    gtmEvent('Main Page Action', 'Quick Create Point', 'Main Page Make A Point');
    gtmEvent('Point', 'Create Point', 'Create Point Main Page');
    fbq('trackCustom', 'QuickCreate');
    fbq('trackCustom', 'CreateAny');
    this.props.onSubmit(values).then(
      (val) => {
        this.setState({submitting: false});
      },
      (err) => {
        this.setState({submitting: false});
      });
  }

  // TODO
  // replace <span className="">Add</span> with <span className="fa fa-edit"></span> for desired look
  // but this also makes the bug reappear:
  // when browser width goes from narrow to wide, errors are thrown and page is blank
  // (seems to work fine going from wide to narrow)
  // make work!
  submitButtonLabel = () => {
    return <span>
        <MediaQuery minWidth={config.extraSmallScreenThreshold}>
          Publish
        </MediaQuery>
        <MediaQuery maxWidth={config.extraSmallScreenThreshold}>
          <span className="">Add</span>
        </MediaQuery>
      </span>
  }

  submitButton = ({...rest}) => {
    if (this.state.submitting) {
      return <span className="quickCreateClaimFeedback">
        <Spinner /><span className="spinnerLabel">Adding your point...</span>
      </span>;
    } else {
      return <button className="buttonUX2 buttonUX2RespIcon createClaimFormButton" type="submit" {...rest}>{this.submitButtonLabel()}</button>;
    }
  }

  // TO DO: there is bug around validationFailures - its returning 0 when it shouldn't be
  //   for now, adding the condition (title.length > validations.titleMaxCharacterCount) to make the button disable when it should
  //   but really we should fix the bug
  //   see PR here: https://github.com/aaronlifshin/whysaurus/pull/82#pullrequestreview-111369929
  render(){
    let placeholder = this.props.placeholderText || 'Start with a claim, eg "Dogs are better than cats."'
    return <Form onSubmit={this.submit}
                 id="mainPageClaimCreationForm"
                 validate={values => ({title: validations.validateTitle(values.title)})}
    >
      { ({values: {title}, submitForm}) => (
          <>
            <div className="claimCreationFormFieldBlock">
              <TitleText title={title} id="newPointTextField" className="inputFieldUX2 titleTextField" onSubmit={submitForm} placeholder={placeholder} />
            </div>
            <div className="claimCreationFormButtonBlock">
              {this.submitButton({disabled: (!title || (title == "") || (title.length > validations.titleMaxCharacterCount) )})}
              {/*|| (validationFailures > 0)*/}
            </div>
          </>
      )}
    </Form>;
  }
}

QuickCreateClaim.defaultProps = {
  placeholderText: 'Start with a claim, eg "Dogs are better than cats."',
}
