import React, {Fragment} from 'react'
// import MediaQuery from 'react-responsive'
import {Modal,Tabs, Tab} from 'react-bootstrap';
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import { graphql } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash';

import * as schema from '../schema'
import config from '../config';
import TagPage from './TagPage';
import QuickCreateClaim from './QuickCreateClaim'
import Spinner from './Spinner'
import TopTags from './TopTags'
import { PointList } from './PointList'
import { PointCard } from './Point'
import { CloseLinkX } from './common'
import { withExpandedIndex } from './ExpandedIndex'
import { withAuth } from './Authorization'
import FontAwesomeIcon from './FontAwesomeIcon'
import {withOnboardingTips} from './OnboardingTips';
import {Helmet} from "react-helmet";
import {withYsAlerts} from "../AlertProvider";

const disableOnboardingTips = false


const NewPoints = graphql(schema.NewPoints, {
  options: ({pointsPerPage}) => ({variables: {limit: pointsPerPage || 3}}),
  props: ({ownProps: {pointsPerPage}, data: { loading, newPoints = {}, fetchMore}}) => ({
    loading: loading,
    points: newPoints.points,
    hasMore: newPoints.hasMore,
    infiniteScroll: true,
    loadMorePoints: () => {
      return fetchMore({
        query: schema.NewPoints,
        variables: {cursor: newPoints.cursor, limit: pointsPerPage || 3},
        updateQuery: (previousResult, { fetchMoreResult }) => {
          fetchMoreResult.newPoints.points = [...previousResult.newPoints.points, ...fetchMoreResult.newPoints.points]
          return fetchMoreResult
        }
      })
    }
  })
})(PointList)



class Section extends React.Component{
  state = {showMore: false}

  renderSectionIcon = (iconClass) =>  {
    return (
      <span className="sectionIcon"><span className={iconClass}></span></span>
    )
  }

  renderSectionTitle = (title, iconClass) => {
    return (
      <header className="contentSectionHeading" tabIndex="-1">
        <h1 className="contentSectionLabel">
          {iconClass && this.renderSectionIcon(iconClass)}
          <span className="contentSectionLabelCopy">{title}</span>
        </h1>
      </header>
    )
  }

  renderMore = ({name: seeMoreName, query, variables, props}) => {
    const {showMore} = this.state
    const Component = graphql(query, {
      props,
      options: {variables}
    })(PointList)

    // Animation on card stack doesn't work here bc the stack is being removed from the DOM on click; to make it work, I think we'd need to add a library -JF
    return (
      <Fragment>
        {showMore || <a onClick={() => this.setState({showMore: true})} className="contentSectionMoreStack">
                        <div className="stackCardGroup wideCard" tabIndex="0" onClick={this.handleToggleEvidence} ref={(input) => { this.cardToFocusOn = input;}}>
                          <div className="stackCard wideCard" tabIndex="-1">
                            <div className="stackCard wideCard" tabIndex="-1">
                               <div className="stackCard wideCard" tabIndex="-1">
                                <div className="point-card stackCard wideCard" tabIndex="-1">
                                  <span className="contentSectionLabel contentSectionLabelSeeMore">See more {seeMoreName}</span>
                                </div>
                               </div>
                            </div>
                          </div>
                        </div>
                      </a>}
        {showMore &&
          <div className="contentSectionHeading contentSectionHeadingSeeLess" tabIndex="-1">
            <h1 className="contentSectionLabel contentSectionLabelSeeMore">More {seeMoreName}</h1>
            <span className="editAreaClose"><a onClick={() => this.setState({showMore: false})} className="contentSectionSeeLessLink"><CloseLinkX/></a></span>
          </div>}
        {showMore && <Component/>}
      </Fragment>
    )
  }

  render(){
    const {title, iconClass, point, more, pointList, footer} = this.props
    return (
      <section className="contentSection">
        {this.renderSectionTitle(title, iconClass)}
        <div className="contentSectionCards">
          {(pointList == null && point == null) ? <span className="contentSectionLoading"><Spinner/></span>
            : (pointList || (point && <PointList point={point}/>))}
          <div className="contentSectionSeeMore">{(pointList || point) && more && this.renderMore(more)}</div>
          {footer && <div className="contentSectionFooter">{footer}</div>}
        </div>
      </section>
    )
  }
}

export class NewHomePageComponent extends React.Component {
  state = {
    termsOpen: true,
    explanationVisible: true,
    isQuickCreateQuestion: false,
  }

  createNewPoint = (variables) => {
    if (this.props.currentUser){
      if (this.state.isQuickCreateQuestion) {
        return this.props.createNewQuestion(variables)
          .then((newPoint) => newPoint && this.props.history.push(`/claim/${newPoint.url}`))
      } else {
        return this.props.createNewPoint(variables)
          .then((newPoint) => newPoint && this.props.history.push(`/claim/${newPoint.url}`))
      }
    } else {
      this.props.showLoginDialog()
      return Promise.reject("User not logged in");
    }
  }

  onQuickCreateTabsSelect = (key) => {
    this.setState({ isQuickCreateQuestion: key == "createQuestion" })
  }

  renderIllustration1 = () => {
     return <div className="explanationBlock">
          <span className="icon"><FontAwesomeIcon name="bullseye-arrow" type="fad" /></span>
          <div className="explanationText">
            <span className="headline">Find strong arguments</span>
            <span className="secondary">Each argument is made of bite-sized points.</span>
          </div>
     </div>
  }
  renderIllustration2 = () => {
     return  <div className="explanationBlock">
          <span className="icon"><FontAwesomeIcon name="balance-scale" type="far" /></span>
          <div className="explanationText">
            <span className="headline">Click "See Evidence" on any point</span>
            <span className="secondary">So you can always ask why. <br className="hidden-onlyOn-veryVerySmallWidthDisplays" />If there's no evidence, see that quickly.</span>
        </div>
      </div>
  }
  renderIllustration3 = () => {
     return  <div className="explanationBlock" >
        <span className="icon"><FontAwesomeIcon name="lightbulb-on" type="far" /></span>
        <div className="explanationText">
          <span className="headline">Share your ideas</span>
          <span className="secondary">
            Make the conversation richer by adding your perspective. <br className="hidden-onlyOn-verySmallWidthDisplays"/>The best contributions rise to the top.
          </span>
        </div>
     </div>
  }
  renderIllustration4 = () => {
     return  <div className="explanationBlock noLeftMargin">
       <img className="explanationImageCentered imgFindUseful" src="/static/img/homePageIllustration_UX2_v04_2x_FindUseful.png"/>
       <div className="explanationTextCentered lessWidth">Find evidence-based<br/>concise arguments</div>
     </div>
  }
  closeExplanation = (e) => {
    this.setState({ explanationVisible: false })
    if (this.props.currentUser) {
      console.log('Confirming walkthrough')
      this.props.setUserFlag(this.props.currentUser.url, 'confirmHeaderWalkthrough', 1)
    } else {
      // Do we want to force/prompt a login?
      console.log('No user to confirm')
    }
  }
  renderIllustrationCloseX = () => {
    return <div id="gotItExplanation" className="explanationBlock" onClick={this.closeExplanation}>
      <span className="editAreaClose">
        <a href='#' data-toggle="tooltip" title="Got It!"><CloseLinkX/></a>
      </span>
    </div>
  }
  renderIllustrationCloseButton = () => {
    return <div id="gotItExplanation" className="explanationBlock">
      <button className="buttonUX2 buttonGotIt" onClick={this.closeExplanation}>Got It!</button>
      {!disableOnboardingTips && <button className="buttonUX2 buttonSecondaryUX2black transparentBG" onClick={this.props.startTour}>Show me how it works</button>}
    </div>
  }
  illustrations = () => {
    if (!(this.props.userLoading || (this.props.currentUser && this.props.currentUser.hasConfirmedHeaderWalkthrough))) {
      return this.state.explanationVisible && (
          <div className="fullWidth" id="explanationRowHomepage">
              <div className="explanationLabel">
                Collecting The Best Arguments for Everything
              </div>
              {this.renderIllustration1()}
              {this.renderIllustration2()}
              {this.renderIllustration3()}
              {this.renderIllustrationCloseButton()}
          </div>
      )
    }
  }

  confirmTerms = () => {
    console.log('Accepting Terms..')
    this.setState({ termsOpen: false })
    this.props.acceptTerms(this.props.currentUser.url)
  }
  declineTerms = () => {
    console.log('Terms Declined')
    this.setState({ termsOpen: false })
    window.location = '/logout';
  }
  // make value pop-up behave as it should in production (don't show unless user needs to see it):
  //   <Modal show={ !this.props.currentUser...
  // force values pop-up to display (in order to edit style):
  //   <Modal show={ this.props.currentUser...
  termsAndConditionsPopup() {
    if (this.props.currentUser) {
      return (
        <Modal show={ !this.props.currentUser.hasConfirmedTermsAndConditions && this.state.termsOpen }
               backdrop="static"
               id="termsModal">
            <div id="termsPopup">
              <Modal.Header>
                <h1 className="header">These are our Community Values. Do you agree to uphold them?</h1>
              </Modal.Header>
              <Modal.Body className="text-content noMarginTop">
                <h2 className="firstItem">Our Goal</h2>
                <p className="smallerVerticalMargin">We’re here to improve public discourse by collecting and sharing the best evidence-based arguments for everything.</p>

                <h2>It’s the Journey</h2>
                <p className="smallerVerticalMargin">We won’t answer every question. But by arguing, we’ll get closer.</p>

                <h2>Inclusivity </h2>
                <p className="smallerVerticalMargin">It’s better and more fun if we don’t all think the same. All views are welcome! (Except hate speech)</p>

                <h2>We Limit Hierarchy</h2>
                <p className="smallerVerticalMargin">Top contributors will be recognized! But they won’t get more say about what’s true and what isn’t.</p>

                <h2>Creation ≠ Endorsement</h2>
                <p className="smallerVerticalMargin">Sometimes we create arguments to try them out. Sometimes we agree with our own points and sometimes we don’t.</p>

                <h2>Chip In</h2>
                <p className="smallerVerticalMargin">If you see how an argument could be better, contribute! If you see how Ys could improve, tell us!</p>

                <h2>Have Fun</h2>
                <p className="smallerVerticalMargin">Make jokes. Celebrate great work. Disagree without being disagreeable.</p>

                <h2>How we manage your data and privacy</h2>
                <p className="smallerVerticalMargin">We use cookies and store data. We keep your agrees and disagrees anonymous. Our <a target="_blank" href="/privacyPolicy">Privacy Policy</a> gives you the rights required by GDPR. Here is our <a target="_blank" href="/manifesto">Manifesto</a> and our <a target="_blank" href="/WhatIsWhysaurus">FAQ</a>. You can always <a target="_blank" href="/contact">contact us</a>.</p>

                <p className="smallerVerticalMargin termsValuesPageLink"><a target="_blank" href="/values">Learn how we put these values into practice.</a></p>
              </Modal.Body>
              <Modal.Footer className="actions">
                <button className="buttonUX2 buttonUX2marginR" onClick={this.confirmTerms}>I'm in</button>
                <button className="buttonUX2 buttonSecondaryUX2black" onClick={this.declineTerms}>No thanks, log me out!</button>
              </Modal.Footer>
            </div>
          </Modal>
      )
    }
  }

  componentDidMount(){
    const params = new URLSearchParams(this.props.location.search)
    const focus = params.get("focusQuickCreate")
    if (focus == "true") {
      QuickCreateClaim.focus()
    }
  }

  renderLatestPoints = (points, numLeadPoints) => {
    const title = "Latest"
    const iconClass = "fas fa-bolt"
    const moreName = "New Arguments"
    const moreQuery = schema.NewHomePageLatestPoints
    const moreVariables = {}
    const moreProps = ({ownProps, data: {homePage}}) => {
      return {...ownProps, points: homePage && homePage.latestPoints && homePage.latestPoints.slice(`${numLeadPoints}`)}
    }
    const footer = <span>
      <Link to="/create" className="buttonSection" alt="Make an argument">
        <div type="button" className="buttonUX2 buttonSecondaryUX2black">
          <span className="iconButton far fa-plus-square" />
          <span className="">Create an argument</span>
        </div>
      </Link>
    </span>
    return this.renderPointListSection(points, numLeadPoints, title, iconClass, moreName, moreQuery, moreVariables, moreProps, null, footer)
  }

  renderEditorsPicks = (points, numLeadPoints) => {
    const title = "Editor's Picks"
    const iconClass = "fas fa-trophy"
    const moreName = "Editor's Picks"
    const moreQuery = schema.NewHomePageEditorsPicks
    const moreVariables = {}
    const moreProps = ({ownProps, data: {homePage: {editorsPicks}}}) => ({...ownProps, points: editorsPicks && editorsPicks.points && editorsPicks.points.slice(`${numLeadPoints}`)})
    return this.renderPointListSection(points, numLeadPoints, title, iconClass, moreName, moreQuery, moreVariables, moreProps, "/editorspicks")
  }

  renderControversialPoints = (points, numLeadPoints) => {
    const title = "Controversial"
    const iconClass = "far fa-fire-alt"
    const moreName = "Controversial"
    const moreQuery = schema.NewHomePageControversialPoints
    const moreVariables = {}
    const moreProps = ({ownProps, data: {homePage}}) => ({...ownProps, points: homePage && homePage.controversialPoints && homePage.controversialPoints.slice(`${numLeadPoints}`)})
    return this.renderPointListSection(points, numLeadPoints, title, iconClass, moreName, moreQuery, moreVariables, moreProps)
  }

  renderSuggestedPoints = (points, numLeadPoints) => {
    const title = "For You"
    const iconClass = "far fa-hand-point-right"
    const moreName = "For You"
    const moreQuery = schema.NewHomePageSuggestedPoints
    const moreVariables = {}
    const moreProps = ({ownProps, data: {homePage}}) => ({...ownProps, points: homePage && homePage.suggestedPoints && homePage.suggestedPoints.slice(`${numLeadPoints}`)})
    return this.renderPointListSection(points, numLeadPoints, title, iconClass, moreName, moreQuery, moreVariables, moreProps)
  }

  renderSpecificTagPoints = ({tag: tag, points: points}, numLeadPoints) => {
    const title = <Link to={`/y/${tag.url}`}>{tag.text}</Link>
    const iconClass = "fal fa-hashtag"
    const moreName = `#${tag.text}`
    const moreQuery = schema.TagPoints
    const moreVariables = {tagUrl: tag.url}
    const moreProps = ({ownProps, data: {tagResult}}) => ({...ownProps, points: tagResult && tagResult.points && tagResult.points.slice(`${numLeadPoints}`)})
    return this.renderPointListSection(points, numLeadPoints, title, iconClass, moreName, moreQuery, moreVariables, moreProps)
  }

  // alt idea, might try this again later <span className="hashtagBig">#</span> -JF
  renderFeaturedTagPoints = ({tag: featuredTag, points: points}, numLeadPoints) => {
    const title = <Link to={`/y/${featuredTag.url}`}>{featuredTag.text}</Link>
    const iconClass = "fas fa-hashtag"
    const moreName = `#${featuredTag.text}`
    const moreQuery = schema.TagPoints
    const moreVariables = {tagUrl: featuredTag.url}
    const moreProps = ({ownProps, data: {tagResult}}) => ({...ownProps, points: tagResult && tagResult.points && tagResult.points.slice(`${numLeadPoints}`)})
    return this.renderPointListSection(points, numLeadPoints, title, iconClass, moreName, moreQuery, moreVariables, moreProps)
  }

  renderFeaturedAuthorPoints = ({creator: featuredAuthor, points: points}, numLeadPoints) => {
    const title = <Fragment>Featured Community Member: <a href={`/user/${featuredAuthor.url}`}>@{featuredAuthor.name}</a></Fragment>
    const iconClass = "fas fa-user"
    const moreName = `by @${featuredAuthor.name}`
    const moreQuery = schema.AuthorPoints
    const moreVariables = {authorUrl: featuredAuthor.url}
    const moreProps = ({ownProps, data: {authorResult}}) => ({...ownProps, points: authorResult && authorResult.points && authorResult.points.slice(`${numLeadPoints}`)})
    return this.renderPointListSection(points, numLeadPoints, title, iconClass, moreName, moreQuery, moreVariables, moreProps)
  }

  renderPointListSection = (points, numLeadPoints, title, iconClass, moreName, moreQuery, moreVariables=null, moreProps, fullResultURL=null, footer=null) => {
    return <Fragment>
      <Section pointList={points && <PointList points={points.slice(0, numLeadPoints)}/>}
               title={title}
               iconClass={iconClass}
               more={points && points.length > numLeadPoints && {
                      name: `${moreName}`,
                      query: moreQuery,
                      variables: moreVariables,
                      props: moreProps
               }}
               footer={footer}
      />
      {fullResultURL && <Link className="contentSectionAfterLink" to={fullResultURL}>See all Editor's Picks</Link>}
    </Fragment>
  }

  renderQuestions = (points, numLeadPoints) => {
    const title = "Questions"
    const iconClass = "fas fa-question-circle"
    const moreName = "Questions"
    const moreQuery = schema.NewHomePageQuestions
    const moreVariables = {}
    const moreProps = ({ownProps, data: {homePage}}) => ({...ownProps, points: homePage && homePage.questionPoints && homePage.questionPoints.slice(`${numLeadPoints}`)})
    const footer = <span>
      <Link to="/createquestion" className="buttonSection" alt="Ask A New Question">
        <div type="button" className="buttonUX2 buttonSecondaryUX2black">
          <span className="iconButton far fa-plus-square" />
          <span className="">Ask A New Question</span>
        </div>
      </Link>
    </span>
    return this.renderPointListSection(points, numLeadPoints, title, iconClass, moreName, moreQuery, moreVariables, moreProps, null, footer)
  }

  renderSections = () => {
    const {getLuckyPoint, refreshGetLucky, featuredPoint, suggestedPoints, questionPoints, specificTagAndPoints, featuredTagAndPoints, featuredTagAndPointsSecondary, featuredAuthorAndPoints, controversialPoints, latestPoints, editorsPicks} = this.props
    return (
      <Fragment>
        <Section title="Featured" point={featuredPoint} iconClass={"fas fa-star"}/>
        {suggestedPoints && suggestedPoints.length > 0 && this.renderSuggestedPoints(suggestedPoints, 2)}
        {this.renderLatestPoints(latestPoints, 3)}
        {specificTagAndPoints && this.renderSpecificTagPoints(specificTagAndPoints, 3)}
        {editorsPicks && this.renderEditorsPicks(editorsPicks.points, editorsPicks.numLeadPoints)}
        {this.renderControversialPoints(controversialPoints, 3)}
        {featuredTagAndPoints && (this.renderFeaturedTagPoints(featuredTagAndPoints, 3))}
        {featuredTagAndPointsSecondary && (this.renderFeaturedTagPoints(featuredTagAndPointsSecondary, 3))}
        {questionPoints && (this.renderQuestions(questionPoints, 3))}
        {featuredAuthorAndPoints && (this.renderFeaturedAuthorPoints(featuredAuthorAndPoints, 2))}
        <Section point={getLuckyPoint} title={(
            <Fragment><span>Get Lucky!</span><a onClick={() => refreshGetLucky()} className="contentSectionSecondaryLink">Refresh</a></Fragment>
        )} iconClass={"fas fa-dice"} />
        <Section title="More Arguments" pointList={<NewPoints/>} iconClass={"fas fa-bolt"}/>
      </Fragment>
    )
  }

  // <div className="mainPageClaimCreationSecondaryText">Next, you'll add evidence</div>
  render(){
 
    return <div>
        <Helmet>
          <title>Whysaurus -- Collecting the best arguments for everything</title>
          <meta property="og:title" content="Whysaurus -- Collecting the best arguments for everything" />
          <meta property="og:description" content="May the best ideas win" />
          <link rel="canonical" href={"https://www.whysaurus.com/"} />
          <meta property="og:url" content={"https://www.whysaurus.com/"} />
        </Helmet>
        {this.illustrations()}
        <div id="mainContainer">
          <div className="infiniteWidth">
            {/*<div className="mainPageClaimCreationArea">*/}
            {/*  <Tabs id="tabs-quick-create" defaultActiveKey="createClaim" onSelect={this.onQuickCreateTabsSelect}>*/}
            {/*    <Tab eventKey="createClaim" title="Create an argument">*/}
            {/*      <h3 className="mainPageClaimCreationLabel">Make a statement. <span className="visible-xxs"></span>Then add evidence.</h3>*/}
            {/*      <QuickCreateClaim onSubmit={this.createNewPoint} placeholderText='Dogs are better than cats.'/>*/}
            {/*    </Tab>*/}
            {/*    <Tab eventKey="createQuestion" title="Ask a question">*/}
            {/*      <h3 className="mainPageClaimCreationLabel">Start with a question</h3>*/}
            {/*      <QuickCreateClaim onSubmit={this.createNewPoint} placeholderText='Why are dogs better than cats?' />*/}
            {/*    </Tab>*/}
            {/*  </Tabs>*/}
            {/*</div>*/}
            <div className="mainPageContentArea">
              <div id="mainPageMainArea">
                {this.renderSections()}
              </div>
              {this.termsAndConditionsPopup()}
            </div>
          </div>
        </div>
      </div>
  }
}

NewHomePageComponent.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object.isRequired,
  tagsTop: PropTypes.array,
  featuredPoint: PropTypes.object,
  specificTagAndPoints: PropTypes.object,
  featuredTagAndPoints: PropTypes.object,
  featuredAuthorAndPoints: PropTypes.object,
  controversialPoints: PropTypes.array,
  questionPoints: PropTypes.array,
  editorsPicks: PropTypes.object,
  suggestedPoints: PropTypes.array,
  showLoginDialog: PropTypes.func.isRequired,
  createNewPoint: PropTypes.func.isRequired,
  createNewQuestion: PropTypes.func.isRequired,
  acceptTerms: PropTypes.func.isRequired,
  setUserFlag: PropTypes.func.isRequired,
  history: PropTypes.object,
  userLoading: PropTypes.bool
}

const steps = [
  {  
    element: ".seeEvidenceBlock",
    intro: <span>Click <b>"See Evidence"</b> on<br/>any <b>point</b>.</span>,
    reposition : true,
    highlightClass: ".onboardTipHiliteSeeEvidence"
  },
  {
    element: '.agreeDisagree',
    intro: <span>Rate points with <b>agree</b> and <b>disagree</b>. These votes are anonymous.</span>,
    reposition : false
  },
  {
    element: '.scoreAnimContainerReset',
    intro: <span>The <b>score</b> measures a point's strength—so far.
      It’s based on <b>evidence</b>, <b>relevance</b>, <b>agrees</b> and <b>disagrees</b>.</span>
  }
]

export default withOnboardingTips(compose(
  withAuth,
  withRouter,
  withYsAlerts,
  graphql(schema.NewHomePageFeaturedPoint, {
    props: ({ownProps, data: {homePage}}) => ({
      featuredPoint: homePage && homePage.featuredPoint
    })
  }),
  graphql(schema.NewHomePageLatestPoints, {
    props: ({ownProps, data }) => {
      if (data.error) {
        ownProps.handleError(data.error)
        return
      }
      return {
        latestPoints: data && data.homePage && data.homePage.latestPoints
      }
    }
  }),
  graphql(schema.NewHomePageEditorsPicks, {
    props: ({ownProps, data: {homePage}}) => ({
      editorsPicks: homePage && homePage.editorsPicks
    })
  }),
  graphql(schema.NewHomePageSpecificTagAndPoints, {
    props: ({ownProps, data: {homePage}}) => ({
      specificTagAndPoints: homePage && homePage.specificTagAndPoints
    })
  }),
  graphql(schema.NewHomePageFeaturedTagAndPoints, {
    props: ({ownProps, data: {homePage}}) => ({
      featuredTagAndPoints: homePage && homePage.featuredTagAndPoints
    })
  }),
  graphql(schema.NewHomePageFeaturedTagAndPointsSecondary, {
    props: ({ownProps, data: {homePage}}) => ({
      featuredTagAndPointsSecondary: homePage && homePage.featuredTagAndPointsSecondary
    })
  }),
  graphql(schema.NewHomePageFeaturedAuthorAndPoints, {
    props: ({ownProps, data: {homePage}}) => ({
      featuredAuthorAndPoints: homePage && homePage.featuredAuthorAndPoints
    })
  }),
  graphql(schema.NewHomePageControversialPoints, {
    props: ({ownProps, data: {homePage}}) => ({
      controversialPoints: homePage && homePage.controversialPoints
    })
  }),
  graphql(schema.NewHomePageSuggestedPoints, {
    props: ({ownProps, data: {homePage}}) => ({
      suggestedPoints: homePage && homePage.suggestedPoints
    })
  }),
  graphql(schema.NewHomePageQuestions, {
    props: ({ownProps, data: {homePage}}) => ({
      questionPoints: homePage && homePage.questionPoints
    })
  }),
  graphql(schema.GetLuckyPoint, {
    props: ({ownProps, data: {getLuckyPoint, refetch: refreshGetLucky}}) => ({
      ...ownProps,
      getLuckyPoint,
      refreshGetLucky
    })
  }),
  graphql(schema.TopTags, {
    props: ({ownProps, data: { loading, moderatorPage }}) => ({
      tagsTop: moderatorPage && moderatorPage.tagsTop
    })
  }),
  graphql(schema.NewPoint, {
    props: ({ mutate, ...rest }) => ({
      createNewPoint: (variables) => mutate({variables}).
        then(({data: {newPoint}}) => newPoint && newPoint.point)
    })
  }),
  graphql(schema.NewQuestion, {
    props: ({ mutate, ...rest }) => ({
      createNewQuestion: (variables) => mutate({variables}).
        then(({data: {newQuestion}}) => newQuestion && newQuestion.point)
    })
  }),
  graphql(schema.AcceptTerms, {
    props: ({ mutate }) => ({
      acceptTerms: (userUrl) => mutate({variables: {userUrl}})
    })
  }),
  graphql(schema.SetUserFlag, {
    props: ({ mutate }) => ({
      setUserFlag: (userUrl, flag, value) => mutate({variables: {userUrl, flag, value}})
    })
  })
)(NewHomePageComponent), {steps, disable: disableOnboardingTips})
