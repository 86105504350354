import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash';

import * as schema from '../schema'
import {withExpandedIndex} from './ExpandedIndex'
import {withAuth} from "./Authorization";

class AdminPage extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    tabIndex: 0
  }

  componentDidMount(){

  }

  addTag = () => {
    var tagId = prompt("New Tag Id/Url? (Case Sensitive)");
    if (tagId == null || tagId == "") {
        console.log("Tag Add Bypassed!")
        return;
    }
    console.log('Admin: Attempting To Create Tag: ' + tagId)

    this.props.addNewTag(tagId)
  }

  createClassroom = () => {
    var tagId = prompt("New Private Area Id?");
    if (tagId == null || tagId == "") {
        console.log("Tag Add Bypassed!")
        return;
    }
    console.log('Not Implemented: Attempting To Create Private Area: ' + tagId)

    // Not Implemented - Name and schema please
  }

  adminTest = () => {
    console.log('Admin: Test..')

    //throw "AdminTest Exception"

    this.props.adminTest()
  }

  render(){
    const {userLoading, currentUser, showLoginDialog} = this.props
    if (userLoading) {
      return <div></div>
    } else if (currentUser && currentUser.admin) {
      return <div className="infiniteWidth">
        <div>
          <div className="adminTopButtons">
            <a href='/users'>Users (sorted by last log-in)</a>
            <a href='/usersAll'>Users (sorted by last update)</a>
            <a className="adminTopLink" href='/dailyReport' id='dailyReport'>Daily report</a>
            <br />
            <button id='addTag' onClick={this.addTag}>Add Tag</button>
            <button id='createPrivateArea' disabled onClick={this.createClassroom}>Create Classroom</button>
            <button id='adminTest' onClick={this.adminTest}>Test</button>
          </div>
        </div>
      </div>
    } else {
      return <div>Not Authorized</div>
    }
  }
}

export default compose(
  withExpandedIndex,
  withAuth,
  graphql(schema.AdminPage),
  graphql(schema.AddNewTag, {
    props: ({ mutate }) => ({
      addNewTag: (id, isTop) => mutate({variables: {id, isTop}})
    })
  }),
  graphql(schema.AdminTest, {
    props: ({ mutate }) => ({
      adminTest: () => mutate()
    })
  }),
)(AdminPage);
