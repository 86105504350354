import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
// import { getMainDefinition } from '@apollo/client/utilities';
// import {createRtdbLink} from 'apollo-link-firebase';

// import fb from './firebase';

// const rtdbLink = createRtdbLink({
//   database: fb.database()
// });

export const classic = new ApolloClient({
    // link: split(
    //   ({ query }) => {
    //     const { kind, operation } = getMainDefinition(query);
    //     return kind === 'OperationDefinition' && operation === 'subscription';
    //   },
    //   rtdbLink,
    //   new HttpLink({ uri: '/graphql', credentials: 'same-origin' })
    // ),
    uri: '/graphql',
    credentials: 'same-origin',
    cache: new InMemoryCache()
});

// export const raptor = new ApolloClient({
//   link: new HttpLink({ uri: '/api', credentials: 'same-origin' }),
//   cache: new InMemoryCache()
// });

// export const firebase = new ApolloClient({
//   link: rtdbLink,
//   cache: new InMemoryCache()
// });
