import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types'

import * as validations from '../validations'

import CharCount from './CharCount'
import ClaimSuggestor from './ClaimSuggestor'

// use onmousedown here to try to get in before blur hides the UI (see note in TitleText onBlur below)
// TODO: think about ways to make the "suggestion UI hide" condition be "clicking on anything that is not the text input or suggestion ui itself"
// <li>example claim</li>
const ExistingClaimPicker = ({claims, onSelectClaim}) =>
  <div className="existingClaimPicker">
    <div className="existingClaimPickerHeading">Save time! Link an existing point:</div>
    <ul className="existingClaimList">
          {claims && claims.map((claim) => <li onMouseDown={e => onSelectClaim(claim, e)} key={claim.id}>
                                {claim.title}
                                </li>)}

    </ul>
  </div>



const TitleText = ({
  name,
  point,
  evidenceType,
  addExistingClaim,
  suggestExistingClaims,
  onSubmit,
  validate = {},
  ...restOfProps
}) => {
  const [titleTextFocused, setTitleTextfocused] = useState(false);

  const methods = useFormContext();
  const title = methods.watch(name);
  const error = methods.errors[name];

  const selectExistingClaim = (claim) => addExistingClaim(evidenceType, claim);

  const renderFeedbackArea = (suggestions, searching) => {
    let errorClasses = `titleTextErrorArea ${error && "titleTextErrorAreaContent"}`
    let claimFeedbackAreaClasses = `claimFeedbackArea ${evidenceType==="counter" && "counter" }`

    let errorText = ""
    if (error != null) {
      if (error.message != null && error.message != "") {
        errorText = error.message;
      } else if (error.type === "required") {
        errorText = "This point doesn't seem to have any words."
      } else {
        errorText = "Invalid: " + String(error.type)
      }
    }
    return <div className={claimFeedbackAreaClasses}>
      {error && <div className={errorClasses}>{errorText}</div>}
      {suggestions && suggestions.length > 0 && <ExistingClaimPicker claims={suggestions} onSelectClaim={selectExistingClaim}/>}
    </div>
  };

  const showFeedbackArea = (suggestions) =>
  {
    return titleTextFocused && (error || (suggestExistingClaims && suggestions && suggestions.length > 0 ))
  }

  const renderCountedTextField = (suggestions, searching) => (
    <CharCount countedValue={title || ""} maxChars={validations.titleMaxCharacterCount} render={({charsLeft}) => (
      <span>
        <textarea
          {...restOfProps}
          name={name}
          ref={methods.register(validate)}
          onKeyDown={(e) => {
            if (e.keyCode === 13){
              e.preventDefault()
              if (title && (title !== "")) {
                onSubmit();
              }
            }
          }}
          onFocus={() => setTitleTextfocused(true)}
          // use the setTimeout here to allow the mousedown event in existingclaimpicker to fire consistently
          // right now this fires before the onClick in ExistingClaimPicker and hides that UI before the click event can be fired
          // TODO: think about ways to make the "suggestion UI hide" condition be "clicking on anything that is not the text input or suggestion ui itself"

          // comment this out to make FeedbackArea persistant for styling
          onBlur={() => setTimeout(() => setTitleTextfocused(false), 100)}
        />
        <span className={"charCounter " + (charsLeft && charsLeft < 0 ? ' overMaxChars' : '')}>{charsLeft}</span>
        {showFeedbackArea(suggestions) && renderFeedbackArea(suggestions, searching)}
      </span>
    )}/>
  );

  return (
    <div className="claimTitleField">
      {
        suggestExistingClaims
        ? <ClaimSuggestor
            query={title}
            point={point}
            evidenceType={evidenceType}
            render={({suggestions, searching}) => renderCountedTextField(suggestions, searching)}
          />
        : renderCountedTextField()}
    </div>
  );
};

TitleText.propTypes = {
  name: PropTypes.string.isRequired,
  point: PropTypes.object,
  evidenceType: PropTypes.string,
  addExistingClaim: PropTypes.func,
  suggestExistingClaims: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.object,
};

export default TitleText;
