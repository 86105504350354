import React from 'react'

export default class Spinner extends React.Component {

  // TO DO: add options to change color
  render(){
    const className = this.props.className
    return <img className={`spinner${className ? ` ${className}` : ''}`} src="/static/img/Reload-1s-200px_blue_transp.svg" alt="spinner" />
  }
}
