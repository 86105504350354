export default {
  filestack: {
    key: "AinmHvEQdOt6M2iFVrYowz"
  },
  cdn: {
    baseURL: "https://d38pzqn4b9ae2a.cloudfront.net/"
  },
  relevanceThreshold: 30,
  newPointsPageSize: 10,
  // Responsive:
  singleColumnThreshold: 1048.5,
  commentLabelThreshold: 660, /* TO DO: ideally this isn't a one-off */
  extraSmallScreenThreshold: 640,
  extraextraSmallScreenThreshold: 480
}
