import React from 'react';
import PropTypes from 'prop-types'
import { graphql } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash';
import * as schema from '../schema';
import {PointList} from "./PointList";
// import Spinner from "./Spinner";
import { withAuth } from './Authorization'
import FontAwesomeIcon from './FontAwesomeIcon'

const RecommendedPointsList = compose(
  graphql(schema.RecommendedPoints, {
    options: ({pointUrl, userUrl}) => ({
      variables: {pointUrl: pointUrl, userUrl: userUrl}
    }),
    props: ({ownProps, data: { loading, recommendedPoints, refetch }}) => ({
      loading: loading,
      points: recommendedPoints && recommendedPoints.points,
      refetchRecommendedPoints: refetch
    })
  })
)(PointList);

class RecommendedPointsComponent extends React.Component {
  static propTypes = {
    pointUrl: PropTypes.string,
    currentUser: PropTypes.object
  }

  // TO DO: unify rendering of the section header with the headers in NewHomePage.jsx —JF
  // TO DO: standardize implementation of FontAwesome icon —JF
  render(){
    if (this.props.currentUser) {
      return <section className="recPointsContainer">
        <header className="row-fluid recPointsArea hideBorderTop">
          <header className="contentSectionHeading">
            <h1 className="contentSectionLabel">
              <span className="sectionIcon">
                <i className="far fa-hand-point-right" />
              </span>
              <span className="contentSectionLabelCopy">Recommended Arguments</span>
            </h1>
          </header>

        </header>
        <div className="row-fluid recPointsArea hideBorderTop" onClick={e => e.stopPropagation()}>
          <RecommendedPointsList pointUrl={this.props.pointUrl} userUrl={this.props.currentUser.url}/>
        </div>
      </section>
    } else {
      // Prod don't even need to display a spinner - just let it expand iff complete, blank otherwise
      // return <Spinner/>
      return null
    }
  }
}

const RecommendedPoints = withAuth(RecommendedPointsComponent)

export default RecommendedPoints
