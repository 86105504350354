import React from 'react'
import { withRouter } from 'react-router';
import {matchPath} from "react-router-dom";

const Context = React.createContext(
  {index: {},
   expand: () => {},
   collapse: () => {},
   isExpanded: () => {}
  }
);

class ExpandedIndexProviderComponent extends React.Component {
  constructor(props) {
    super(props);

    // create state this way because we need a consistent object identity to
    // pass to the provider value - see the caveats here:
    // https://reactjs.org/docs/context.html
    this.state = {
      index: this.urlExpandedIndex(),
      indexLocation: props.location.pathname,
      expand: this.expand,
      collapse: this.collapse,
      isExpanded: this.isExpanded,
      isURLExpanded: this.isURLExpanded,
      expandURL: this.expandURL,
      collapseURL: this.collapseURL,
      getFullUrlExpandedExtension: this.getFullUrlExpandedExtension,
      setupRouteMonitoringForPoint: this.setupRouteMonitoringForPoint,
    }
  }

  expandedIndex2Param = (index) => {
    return Object.keys(index).filter(key => index[key]).toString()
  }

  expandedParam2Index = (param) => {
    try {
      return param ? param.split(",").reduce((i, k) => {i[k] = true; return i}, {}) : {}
    } catch (err) {
      console.log("Error parsing URL expanded index:")
      console.log(param)
      console.log(err)
      return {}
    }
  }

  getUrlExpandedParam = () => {
    const fullUrl = this.props.location.search
    if (!fullUrl) {
      return null
    }
    return new URLSearchParams(fullUrl).get("expanded")
  }

  // For a parent url transition, add both the parent to the index and as a prefix to all elements in the index
  getFullUrlExpandedExtension = (newRootUrl) => {
    if (!newRootUrl) {
      const param = this.getUrlExpandedParam()
      if (!param) return ""
      return "?expanded=" + param
    }
    let index = this.urlExpandedIndex()
    let newIndex = {}
    if (!index) return null
    newIndex = {}
    if (newRootUrl) {
      newIndex[newRootUrl] = true
    }
    Object.keys(index).forEach(k => {
      // Avoid url+url, we add anyway
      if(k !== newRootUrl) {
        newIndex[newRootUrl + k] = true;
      }
    });
    return "?expanded=" + encodeURIComponent(this.expandedIndex2Param(newIndex))
  }

  urlExpandedIndex = (urlSearchParams) => {
    let params = urlSearchParams || new URLSearchParams(this.props.location.search)
    return this.expandedParam2Index(params.get("expanded"))
  }

  updateURLExpandedIndex = (urlSearchParams, updatedIndex) => {
    let params = urlSearchParams || new URLSearchParams(this.props.location.search)
    if (Object.entries(updatedIndex).length > 0) {
      params.set("expanded", this.expandedIndex2Param(updatedIndex))
    } else {
      params.delete("expanded")
    }
    const { location, history } = this.props
    history.push({path: location.pathname, search: params.toString()})
  }

  modifyStateAndURL = (indexModifier) => {
    const {location} = this.props
    let params = new URLSearchParams(location.search);
    let index = this.urlExpandedIndex()
    indexModifier(index)
    // console.log(`modifyStateAndURL: ${location.pathname} | ${location.search}`)
    this.setState({
      index: index,
      indexLocation: location.pathname
    })
    this.updateURLExpandedIndex(params, index)
  }

  expansionIndexKey = (url, prefix) => (prefix || "") + url

  expand = (point, prefix) => this.expandURL(point.url, prefix)

  expandURL = (url, prefix) => {
    if (this.isURLExpanded(url, prefix)) {
      return
    } else {
      // console.log('expandURL: expanding (' + url + ') index: ' + Object.entries(this.state.index).length)
    }

    this.modifyStateAndURL(index => index[this.expansionIndexKey(url, prefix)] = true)
  }

  // Used for expanding the current page on a route update - needs to use the current location as it's
  // not yet updated yet
  expandUrlInternal = (url, location) => {
    if (this.isURLExpanded(url, '')) {
      console.debug('expandUrlInternal: Already Expanded: ' + url + " Index: " + Object.entries(this.state.index).length + " Location: " + location.pathname + " LastLocation: " + this.state.indexLocation)
      return
    }
    let params = new URLSearchParams(location.search);
    let index = this.urlExpandedIndex(params)
    const key = this.expansionIndexKey(url, '')
    const keyExists = key in index && index[key];
    if (keyExists) {
      if (location.pathname === this.state.indexLocation) {
        console.warn('expandUrlInternal Already (Second Check): ' + url + " Index: " + Object.entries(this.state.index).length + " Location: " + location.pathname + " IndexLocation: " + this.state.indexLocation)
        return
      } else {
        // Here, we've updated but the index is stale (or hasn't yet set) - so let's set it but not re-route
        console.log('expandUrlInternal expanded with stale index (Second Check): ' + url + " Index: " + Object.entries(this.state.index).length + " Location: " + location.pathname + " IndexLocation: " + this.state.indexLocation)
      }
    } else {
      index[key] = true
    }

    this.setState({
      index: index,
      indexLocation: location.pathname
    })

    if (keyExists) {
      console.log('expandUrlInternal: index update (' + url + ') index: ' + Object.entries(index).length + ' keyExists: ' + keyExists)
      return
    } else {
      console.log('expandUrlInternal: expanding (' + url + ') index: ' + Object.entries(index).length + ' keyExists: ' + keyExists)
    }

    this.updateURLExpandedIndex(params, index)
  }

  setupRouteMonitoringForPoint = (location, match, history) => {
    // console.log('setupRouteMonitoringForPoint: ' + match.params.url)
    this._expandIndex(location, match)

    return history.listen((location) => {
      const curPath = this.props.location.pathname;
      // if (location.pathname === curPath) {
      //   console.log('Bypassing expandIndex for unchanged location path: ' + curPath)
      //   return
      // }
      console.log('Route Change: ' + location.pathname + ' curPath: ' + curPath)
      this._expandIndex(location, match)
    })
  }

  _expandIndex(location, match) {
    const path = location.pathname
    let newMatch = matchPath(location.pathname, {
      path: match.path
    });
    if (!newMatch) {
      console.warn('expandIndex: No params parsed for path: ' + location.pathname)
      return
    }
    const url = newMatch.params.url
    if (!url) {
      console.error('expandIndex: Invalid Point Load Without URL: ' + path)
      return
    }

    // console.log('expandIndex: newParams.url: ' + url + ' Path: ' + path)
    this.expandUrlInternal(url, location)
  }

  collapse = (point, prefix) => this.collapseURL(point.url, prefix)

  collapseURL = (url, prefix) => {
    this.modifyStateAndURL(index => delete index[this.expansionIndexKey(url, prefix)])
  }

  isExpanded = (point, prefix) => {
    const isEx = this.isURLExpanded(point.url, prefix)
    // const icnt = Object.entries(this.state.index).length
    // const ex = icnt > 0 ? Object.keys(this.state.index)[0] : ""
    // console.log(`isExpanded(${point.url}): ${isEx} Index: ${icnt} | ${ex} | ${this.state.indexLocation}`)
    return isEx
  }

  isURLExpanded = (url, prefix) => !!this.state.index[this.expansionIndexKey(url, prefix)]

  render(){
    // IMPORTANT: value must always point at state so that the context has a consistent reference
    // see the "caveats" section of https://reactjs.org/docs/context.html for more information
    return <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
  }
}

export const ExpandedIndexProvider =  withRouter(ExpandedIndexProviderComponent);


export function withExpandedIndexForPoint(Component) {
  return function ExpandedIndexComponent(props){
    const {point, prefix} = props
    return (
      <Context.Consumer>
        {expansion => {
          const isEx = expansion.isExpanded(point, prefix);
          // console.log(`expanded (prop): ${isEx} | ${point.url} | ${props.location && props.location.pathname}`)
          return <Component {...props}
                     expanded={isEx}
                     onExpand={() => expansion.expand(point, prefix)}
                     onCollapse={() => expansion.collapse(point, prefix)}
                     expansion={expansion} />
        }}
      </Context.Consumer>
    )
  }
}

export function withExpandedIndex(Component) {
  return function ExpandedIndexComponent(props){
    return (
      <Context.Consumer>
        {expansion => <Component {...props} expansion={expansion} />}
      </Context.Consumer>
    )
  }
}
