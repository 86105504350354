import React, {Component, Fragment} from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash'
import * as schema from '../schema'
import { withAuth } from './Authorization'
import { gtmEvent } from '../gtm'
import Notifications from './Notifications'
import { Link, withRouter } from 'react-router-dom'
import OutsideClickDetector from './OutsideClickDetector'
import Form from './formNew';
import {Helmet} from "react-helmet";
import { uuid } from "../utils/uuid";

class UserAreaComponent extends Component{
  state = {dropdownVisible: false}

  setUserArea = (area) => {
    this.props.setUserArea(area).then(() => {
      window.location = "/"
    })
  }

  render() {
    const {userLoading, currentUser, showLoginDialog} = this.props
    if (userLoading) {
      return <div></div>
    } else if (currentUser) {
      const {displayName, avatarURL, url, privateAreas, currentArea} = currentUser;
      const profileURL = `/user/${url}`
      return (
        <Fragment>
          <div id="userMenuArea" className="headerItem headerUserName pull-right open"
               onClick={() => this.setState({dropdownVisible: !this.state.dropdownVisible})}>
            <a className="blackLink">
              <span className="username headerLinkLabel hidden-onlyOn-verySmallWidthDisplays">
                {displayName}
              </span>
              <img id="avatar" src={avatarURL}/>
            </a>
            {this.state.dropdownVisible && (
              <OutsideClickDetector component='ul'
                                    action={() => this.setState({dropdownVisible: false})}
                                    id="userMenuSignedIn" className="dropdown-menu"
                                    role="menu" aria-labelledby="dropdownMenu">
                <li><Link to={profileURL} id="usernameInMenu" className="visible-onlyOn-verySmallWidthDisplays">{displayName}</Link></li>
                <li className="divider visible-onlyOn-verySmallWidthDisplays"></li>
                {(currentArea || (privateAreas && (privateAreas.length > 0))) && (
                  <Fragment>
                    {currentArea ? (
                      <li><a className="areaChoose" onClick={() => this.setUserArea("public")}>Go to public area</a></li>
                    ) : (
                      <Fragment>
                        <li className="menuHeading">Go to Classroom:</li>
                        {privateAreas.map(area => (
                          <li key={area}><a className="areaChoose" onClick={() => this.setUserArea(area)}>{area}</a></li>
                        ))}
                      </Fragment>
                    )}
                    <li className="divider"></li>
                  </Fragment>
                )}
                {currentUser.admin && (<li><a tabIndex="-1" href="/newadmin" className="admin"> Admin </a></li>)}
                {currentUser.moderator && (<li><a tabIndex="-1" href="/moderator" className="admin"> Moderator </a></li>)}

                <li><Link tabIndex="-1" to={profileURL} onClick={() => gtmEvent('UserMenu', 'Profile')}> Profile </Link></li>
                <li><Link tabIndex="-1" to="/settings"> Settings </Link></li>
                <li><a tabIndex="-1" href="/logout"> Sign Out </a></li>
              </OutsideClickDetector>
            )}
          </div>
          <Notifications user={currentUser}/>
        </Fragment>
      )
    } else {
      return (
      <div id="userMenuArea" className="headerItem pull-right">
        <a className="blackLink easierToClickOn" id="signInFromHeader" onClick={showLoginDialog}>Sign In</a>
      </div>
      )
    }
  }
}

const UserArea = compose(
  withAuth,
  graphql(schema.SetUserArea, {
    props: ({mutate}) => ({
      setUserArea: (areaName) => mutate({
        variables: {areaName}
      })
    })
  })
)(UserAreaComponent)

const PrivateAreaHeader = ({currentUser: {currentAreaDisplayName}}) => (
  <div className="privateAreaHeader">
    <div className="container">
      <div className="row">
        <div id="areaLine" className="span12">
          <strong>{currentAreaDisplayName}</strong>
        </div>
      </div>
    </div>
  </div>
)

export class Header extends Component {
  state = {aboutDropdownVisible: false}

  render() {
    const {aboutDropdownVisible} = this.state;
    const {history, currentUser} = this.props;
    const inPrivateArea = currentUser && currentUser.currentArea;
    let classesSearchArea = `headerItem ${!currentUser && "noUser"}`
    const linkTarget = { pathname: "/", key: uuid() }
    return (
      <Fragment>
        <Helmet>
          <title>Whysaurus</title>
          <meta property="og:title" content="Whysaurus" />
          <meta property="og:description" content="Whysaurus" />
          <link rel="canonical" href="https://www.whysaurus.com/" />
          <meta property="og:url" content="https://www.whysaurus.com/" />
        </Helmet>
        <div id="header"
             className={`navbar navbar-fixed-top ${inPrivateArea && "privateSandbox"}`}>
          <div className="container">
            <a id="headerLogo" className="headerItem" href="/">
                <img className="logoDino" src="/static/img/Ys_Triceratops_PROFILE.png"/>
                <img className="logoText hidden-onlyOn-verySmallWidthDisplays" src="/static/img/Ys_WhysaurusCAPS_v07.svg"/>
                <img className="logoText twoletter visible-onlyOn-verySmallWidthDisplays" src="/static/img/Ys_v07.svg"/>
            </a>

            <span className="headerItem">
              <Link to="/create" id="CreatePoint" className="blackLink easierToClickOn" alt="Make a Point">
                <span id="makePointIcon"  className="headerLinkIcon far fa-plus-square"></span>
                <span className="headerLinkLabel hidden-onlyOn-verySmallWidthDisplays">Create</span>
              </Link>
            </span>

            <div id="searchArea" className={classesSearchArea}>
              <Form onSubmit={({searchTerms}) => history.push(`/search?q=${searchTerms}`)}>
                {() => {
                  return <>
                    <Form.Text id="searchBox" className="inputFieldUX2" name="searchTerms" placeholder="Search"/>
                  </>
                }}
              </Form>
              <a><span className="headerLinkIcon searchIcon easierToClickOn pull-right fa fa-search"></span></a>
            </div>

            <span className="headerItem">
              <div id="aboutMenu">
                <a onClick={() => this.setState({aboutDropdownVisible: !aboutDropdownVisible})}
                 className="blackLink easierToClickOn open" >
                  <span className="headerLinkIcon fas fa-bars visible-onlyOn-smallWidthDisplays"></span>
                  <span id="headerLabelAbout" className="headerLinkLabel hidden-onlyOn-smallWidthDisplays">About</span>
                  <span className="headerLinkIcon menuCaret fas fa-caret-down hidden-onlyOn-smallWidthDisplays"></span>
                </a>
                {aboutDropdownVisible && (
                  <OutsideClickDetector component='ul'
                                        action={() => this.setState({aboutDropdownVisible: false})}
                                        onClick={() => this.setState({aboutDropdownVisible: false})}
                                        id="aboutDropdown" className="dropdown-menu" role="menu" aria-labelledby="dropdownMenu">

                    <li><Link id="help1" to="/about">About</Link></li>
                    <li><Link id="help1" to="/values" >Values</Link></li>
                    <li><Link id="help2" to="/faq" >FAQ </Link></li>
                    <li><Link id="help3" to="/manifesto" >Manifesto </Link></li>
                    <li><Link id="help4" to="/education" >Education</Link></li>
                    <li className="divider"></li>
                    <li><Link id="help6" to="/privacyPolicy" >Privacy Policy </Link></li>
                    <li className="divider"></li>
                    <li><Link id="help5" to="/join">Join our Team</Link></li>
                    <li><Link id="help7" to="/contact">Contact</Link></li>
                    <li className="divider visible-onlyOn-smallWidthDisplays"></li>
                    <li>
                      <span className="dropdownSocialMediaIconArea visible-onlyOn-smallWidthDisplays">
                        <a className="headerSocialMediaIcon easierToClickOn" target="_blank" href="https://twitter.com/Whysaurus" rel="noreferrer" >
                          <span id="headerIconDropdownTw" className="headerLinkIcon fab fa-twitter"></span>
                        </a>
                        <a className="headerSocialMediaIcon easierToClickOn" target="_blank" href="https://www.facebook.com/Whysaurus" rel="noreferrer" >
                          <span id="headerIconDropdownFb" className=" headerLinkIcon fab fa-facebook-f"></span>
                        </a>
                        <a className="headerSocialMediaIcon easierToClickOn" target="_blank" href="https://medium.com/whysaurus" rel="noreferrer" >
                          <span id="headerIconDropdownMd" className="headerLinkIcon fab fa-medium-m"></span>
                        </a>
                      </span>
                    </li>
                  </OutsideClickDetector>
                )}
              </div>
            </span>

            <UserArea currentUser={currentUser} />
            <span className="headerItem headerSocialMediaIconArea pull-right hidden-onlyOn-smallWidthDisplays">
              <a className="headerSocialMediaIcon easierToClickOn" target="_blank" href="https://twitter.com/Whysaurus" rel="noreferrer" >
                <span id="" className="headerLinkIcon  fab fa-twitter"></span>
              </a>
              <a className="headerSocialMediaIcon easierToClickOn" target="_blank" href="https://www.facebook.com/Whysaurus" rel="noreferrer" >
                <span id="headerIconFb" className="headerLinkIcon  fab fa-facebook-f"></span>
              </a>
              <a className="headerSocialMediaIcon easierToClickOn" target="_blank" href="https://medium.com/whysaurus" rel="noreferrer" >
                <span id="" className="headerLinkIcon  fab fa-medium-m"></span>
              </a>
            </span>
          </div>
        </div>
        {inPrivateArea && (
          <PrivateAreaHeader currentUser={currentUser}/>)
        }
      </Fragment>
    )
  }
}

export default withAuth(withRouter(Header))
