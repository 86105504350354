import React from 'react'
import Form from './formNew';
import {withRouter} from "react-router";
import {withYsAlerts} from "../AlertProvider";
import {flowRight as compose} from "lodash";
import {graphql} from "@apollo/client/react/hoc";
import * as schema from "../schema";
import Spinner from "./Spinner";
import {withReactAlerts} from "./withReactAlerts";


// class ChooseUsernameForm extends React.Component {
//   static propTypes = {
//   }
//
//   onSubmit = (values) => {
//     const selected_username = values['selected_username']
//     console.log('Submit: ' + selected_username)
//
//     fetch('/complete/google-oauth2/', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify(values)
//     })
//       .then(response => console.log('Username submitted: ' + response.status))
//       .catch(error => this.props.handleError(error))
//   }
//
//   render(){
//     let submitClasses = `buttonUX2 createClaimFormButton`
//     let suggested_username = new URLSearchParams(this.props.location.search).get("suggested_username")
//
//     return (
//       <Form
//         onSubmit={this.onSubmit}
//         className="addEvidenceForm"
//         validationMode="onSubmit"
//         defaultValues={{selected_username: suggested_username}}
//       >
//         {({ submitForm, errors, values }) => {
//           const hasErrors = Object.keys(errors).length > 0;
//
//           return (
//             <>
//               <span className="addEvidenceAreaHeading">
//                 <span className="addEvidenceAreaHeadingText">Welcome!<br/>Please choose a username or accept our suggestion.</span>
//               </span>
//               <div className="claimCreationFormFieldBlock">
//                 <Form.Text name="selected_username" className="inputFieldUX2 inputFieldUX2multi" placeholder="username" />
//               </div>
//               <div className="claimCreationFormButtonBlock">
//                 <button type="submit" onMouseDown={submitForm} className={submitClasses} disabled={hasErrors}>Create Account!</button>
//               </div>
//             </>
//           )
//         }}
//       </Form>
//     )
//   }
// }

class ChooseUsernameForm extends React.Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);
    let suggested_username = new URLSearchParams(this.props.location.search).get("suggested_username")
    this.state = {
      username: suggested_username,
      usernameValid: false,
      validating: false,
      errorMessage: ''
    };
    this.props.usernameCheckLoading = false
  }

  // onSubmit = (values) => {
  //   const selected_username = values['selected_username']
  //   console.log('Submit: ' + selected_username)
  //
  //   fetch('/complete/google-oauth2/', {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify(values)
  //   })
  //     .then(response => console.log('Username submitted: ' + response.status))
  //     .catch(error => this.props.handleError(error))
  // }

  componentDidMount() {

  }

  handleChange = (event) => {
    const username = event.target.value
    this.setState({
      username: username,
      // validating: true,
    });
    if (!username || username.length < 5) {
      this.setState({
        errorMessage: 'Please select a longer username.',
        usernameValid: false,
        validating: false
      })
    } else {
      this.setState({validating: true, usernameValid: null})
      this.props.checkUsername(username)
        .then((result) => {
          let success = result.success
          console.log('username (' + username + ') result: ' + success)
          this.setState({
            validating: false,
            usernameValid: success,
            errorMessage: success ? '' : result.message || 'That username is invalid or taken',
          })
        },
        (err) => {
          // TODO: Gene: Better error handling here
          this.handleError(err)
          this.setState({
            validating: false,
            usernameValid: null,
            errorMessage: 'An unknown error occurred'})
        })
    }
  }

  render(){
    let submitClasses = `buttonUX2 createClaimFormButton`
    let suggested_username = new URLSearchParams(this.props.location.search).get("suggested_username")
    let disabled = !this.state.usernameValid

    return (
      <div id="mainContainer">
        <div className="fullPageForm">
          <form method="post" action="/complete/google-oauth2/" className="" >
          <span className="addEvidenceAreaHeading">
            <span className="addEvidenceAreaHeadingText">Welcome!<br/>Please choose a username or accept our suggestion.</span>
          </span>
          <div className="">
            <input name="selected_username" className="inputFieldUX2" placeholder="username" value={this.state.username} onChange={this.handleChange} />
            <div>
            {this.state.errorMessage && <div className="alert alert-error">{this.state.errorMessage}</div>}
            </div>
          </div>
          <div className="">
            <button type="submit" className={submitClasses} disabled={disabled}>Create Account!</button>
            {(this.state.validating || this.props.usernameCheckLoading) && <Spinner />}
          </div>
        </form>
      </div>
    </div>
    )
  }
}

export default compose(
  withYsAlerts,
  withRouter,
  withReactAlerts,
  graphql(schema.CheckUsername, {
    props: ({ mutate }) => ({
      checkUsername: (username) => mutate({variables: {username}})
        .then(({data: {checkUsername}}) => checkUsername)
    }),
  })
)(ChooseUsernameForm);
