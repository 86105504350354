import React from 'react';
import { graphql } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types'

import Spinner from './Spinner'
import {CommentsListComponent} from './Comments'
import * as schema from '../schema';
import {CloseLinkX, Hover, HoverTip} from './common'
import { withAuth } from './Authorization'
import Comments from "./Point";
import FontAwesomeIcon from './FontAwesomeIcon';


class RelevanceCommentsLink extends React.Component {
  static propTypes = {
    link: PropTypes.object.isRequired,
    onClick: PropTypes.func
  }

  render(){
    let numComments = this.props.link.numComments
    let classesCommentLink = `cardBottomAction commentLink ${(numComments > 0) && "hasComments"}`
    let classesCommentIcon = `iconWithStat ${(numComments > 0) ? "fas fa-comment" : "far fa-comment"} `
    return <Hover delay="1" onHover={<HoverTip title="Comment"/>}>
      <a className="cardBottomActionBlock" onClick={this.props.onClick}>
        <span className={classesCommentLink}>
          <span className="iconWithStat">
            <FontAwesomeIcon name="comment" type={numComments > 0 ? 'fas' : 'far'} />
          </span>
          <span className={classesCommentIcon}></span>
          { (numComments > 0) && <span className="number inlineBlock">{numComments}</span> }
        </span>
      </a>
    </Hover>
  }
}


// TODO: Gene: Really, the comments should be combined
const RelevanceCommentsList = compose(
  withAuth,
  graphql(schema.RelevanceComments, {
    options: ({pointUrl, showArchived}) => ({
      variables: {linkUrl: pointUrl, showArchived: !!showArchived}
    }),
    props: ({ownProps, data: {loading, relevanceComments, refetch}}) => ({
      ...ownProps,
      loadingComments: loading,
      comments: relevanceComments && relevanceComments.comments,
      hasArchived: relevanceComments && relevanceComments.archivedCount > 0,
      refetchComments: refetch
    })
  }),
  graphql(schema.NewRelevanceComment, {
    props: ({ mutate, ownProps: {showArchived} }) => ({
      add: (linkUrl, text, richText, parentCommentID, quickCommentType) => {
        return mutate({variables: {linkUrl, text, richText, parentCommentID, quickCommentType: quickCommentType},
                       refetchQueries: [{query: schema.RelevanceComments, variables: {linkUrl, showArchived: !!showArchived}}]})
      }
    })
  }),
  graphql(schema.ArchiveRelevanceComment, {
    props: ({ mutate, ownProps: {showArchived} }) => ({
      archive: (linkUrl, commentId) =>
        mutate({variables: {linkUrl, commentId},
                refetchQueries: [{query: schema.RelevanceComments, variables: {linkUrl, showArchived: !!showArchived}}]})
    })
  })
)(CommentsListComponent)


class RelevanceComments extends React.Component {
  static propTypes = {
    link: PropTypes.object.isRequired,
    indent: PropTypes.bool,
    onCancel: PropTypes.func.isRequired
  }

  state = {showArchived: false}

  toggleShowArchived = () => {
    this.setState({showArchived: !this.state.showArchived})
  }

  render(){
    let classesCommentsIndent = `${this.props.indent && "addOneIndent"}`
    return <div className={classesCommentsIndent}>
      <div className="row-fluid claimEditArea pointCardPaddingH commentsArea hideBorderTop" onClick={e => e.stopPropagation()}>
        {/*Would obviously like to remove pointUrl here*/}
        <RelevanceCommentsList linkUrl={this.props.link.id} pointUrl={this.props.link.id} isRelevance={true}
                               onCancel={this.props.onCancel} showArchived={this.state.showArchived}
                               toggleArchived={this.toggleShowArchived}/>
      </div>
    </div>
  }
}


class RelevanceComponent extends React.Component {

  state = {
    rating: false,
    commentsVisible: true,
    commentsButtonVisible: false
  }
  componentUnmounting = false

  componentWillUnmount() {
    this.componentUnmounting = true
  }

  static propTypes = {
    point: PropTypes.object.isRequired,
    parentPoint: PropTypes.object.isRequired,
    link: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    showLoginDialog: PropTypes.func
  }

  get relevance() {
    return this.props.link.relevance
  }

  get relevanceVoteCount() {
    return this.props.link.voteCount
  }

  get myRelevanceVote() {
    return this.props.link.relevanceVote
  }

  handleClickFn = (vote) => (event) => {
    const {point, parentPoint, link} = this.props
    if (this.props.currentUser){
      this.setState({rating: true})
      this.props.rate(point, parentPoint, link.type, vote)
        .then( res => {
          if (!this.componentUnmounting)
            this.setState({rating: false})
        });
    } else {
      this.props.showLoginDialog();
    }
  }

  linkClassFor = (vote) =>
    "relVoteLink number relVoteLink" + vote + ((this.myRelevanceVote == vote) ? (" myRelevanceVote" + vote) : "")

  isDisabled = () =>
    this.state.rating

  voteButton= (vote) =>
    <button disabled={this.isDisabled()} className={this.linkClassFor(vote)} onClick={this.handleClickFn(vote)}>{vote}<span className="perctSignSmall">%</span></button>

  showComments = () => {
    this.setState({ commentsVisible: true })
    this.setState({ commentsButtonVisible: false })
  }

  handleCloseComments = () => {
    this.setState({ commentsVisible: false })
    this.setState({ commentsButtonVisible: true })
  }

  renderComments = () => {
    return <div>
      {this.state.commentsButtonVisible && <RelevanceCommentsLink link={this.props.link} onClick={this.showComments} />}
      {this.state.commentsVisible && <RelevanceComments link={this.props.link} indent={false} onCancel={this.handleCloseComments} />}
    </div>
  }


  // TODO: add number of Votes so far to relevanceStats
  // TODO: Gene: Remove the sortScore display (or ideally make it admin?)
  render() {
    return <div className="relCtrlGroup" >
      <div className="relCtrlVoteArea">
        <span className="claimEditAreaHeading">
          <span className="heading">RATE THIS LINK</span>
          <span className="editAreaClose"><a onClick={this.props.onClose}><CloseLinkX/></a></span>
        </span>
        <div className="claimEditAreaNote">How Relevant is this point for you?</div>
         <div className="relCtrlVoteOptions">
           {this.voteButton(100)}
           {this.voteButton(66)}
           {this.voteButton(33)}
           {this.voteButton(0)}
           {this.state.rating && <Spinner />}
         </div>
          <div className="relevanceStats">{this.relevance}% average on {this.relevanceVoteCount} {this.relevanceVoteCount == 1 ? 'vote' : 'votes'} so far</div>
      </div>
      <div className="relCtrlCommentsArea">
        {this.renderComments()}
      </div>
	  <div className="relevanceExplanation">
        <div className="relevanceLearnMore">Relevance has a big impact on scores! <a target="_blank" href="/faq#WhatIsRelevance"><FontAwesomeIcon name="info-circle" />Learn more</a>.</div>
      </div>
    </div>
  }
}

// A Claim's Score * its Avg Relevance = its contribution to its parent's score

export default compose(
  graphql(schema.RelevanceVoteQuery, {
    props: ({mutate}) => ({
      rate: (point, parentPoint, linkType, vote) => mutate({
        variables: {linkType: linkType, url: point.url,
                    parentRootURLsafe: parentPoint.rootURLsafe, rootURLsafe: point.rootURLsafe,
                    vote: vote}
      })
    })
  }),
  withAuth
)(RelevanceComponent)
