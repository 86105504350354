import React, { Component } from 'react';
import { Subscription } from '@apollo/client/react/components'
import { graphql } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash';
import * as schema from '../schema'
import { Link } from 'react-router-dom'
import OutsideClickDetector from './OutsideClickDetector'
import { withAuth } from './Authorization'

const notificationURL = (notification) => {
  if (notification && notification.point) {
    const queryString =  (notification.reasonCode === 3) ? `?expanded=comments-${notification.point.url}` : ""
    return `/claim/${notification.point.url}${queryString}`
  } else {
    return ''
  }
}

export const Notification = ({notification, onClick}) => {
  const { sourceUser, cleared } = notification;
  return (
    <li onClick={onClick}>
      <Link to={notificationURL(notification)}
            className={`notificationMenuItem row-fluid Submenu_text_main ${cleared && 'notificationCleared'}`}>
        <div className="notificationLeft">
          { ((notification.reasonCode == 1) || (notification.reasonCode == 2)) ? (
            <img className="notificationAvatar" src="/static/img/Ys_Triceratops_HEADER.png"/>
          )
            :
            (
              sourceUser && sourceUser.avatarURL ? (
                <img className="notificationAvatar" src={sourceUser.avatarURL}/>
              ) : (
                <img className="notificationAvatar" src="/static/img/Ys_Triceratops_HEADER.png"/>
              )
            )
          }
        </div>
        <div className="notificationText">
          <div>
            {notification.preLinkText}
            <br/>
            <em>{notification.point && notification.point.title}</em>
          </div>
          <div className="notificationTimeStamp">
            {notification.timeText}
          </div>
        </div>
      </Link>
    </li>
  )
}

export class Notifications extends Component {
  state = {}

  clearNotifications = () => {
    const {user, clearNotifications} = this.props
    user && user.notifications && clearNotifications(user.notifications)
  }

  render() {
    const {user, clearNotifications} = this.props;
    const {showNotifications} = this.state;
    const shouldRenderNotificationCount = !!(user && user.newNotificationCount && (user.newNotificationCount > 0))
    return (
      <div id="notifications" className="headerItem pull-right">
        <a className="blackLink" onClick={() => this.setState({showNotifications: !showNotifications})}>
          <img id="notificationIcon" src="/static/img/notificationIcon.png"/>
          {shouldRenderNotificationCount && (
            <div id="notificationCount">
              { user.newNotificationCount }
            </div>
          )}
        </a>
        {showNotifications && (
          <OutsideClickDetector component='ul'
                                action={() => this.setState({showNotifications: false})}
                                className="dropdown-menu notification-menu" role="menu" aria-labelledby="dropdownMenu">
            <li id="notificationMenuHeader" className="Heading_GreyMid_Caps">
              Notifications
              {shouldRenderNotificationCount && (
                <div className="pull-right notificationsMarkAllRead">
                  <a onClick={this.clearNotifications}>Mark All Read</a>
                </div>
              )}
              <div className="pull-right notificationsSeeAll">
                <Link to="/notifications">See All</Link>
              </div>
            </li>
            { user && user.notifications && user.notifications.map((notification, i) => (
              <Notification notification={notification} key={i} onClick={() => clearNotifications([notification])}/>
            ))}
            <li id="notificationMenuFooter">
              <div  className="Heading_GreyMid_Caps notificationsSeeAll">
                <Link to="/notifications">See All</Link>
              </div>
            </li>
          </OutsideClickDetector>
        )}
      </div>
    )
  }
}

const withNotificationSubscription = (Component) => (props) => {
  const {currentUser, refetchUser} = props;
  if (currentUser) {
    return (
      <Subscription
        subscription={schema.NotificationSubscription}
        onSubscriptionData={({client, subscriptionData}) => {
          refetchUser()
        }}
        variables={{ref: `/channels/${currentUser.token}`}}>
        {({data}) => (
          <Component notificationSubscription={data} {...props}/>
        )}
      </Subscription>
    )
  } else {
    return <Component {...props} />
  }
}

export default compose(
  withAuth,
  // withNotificationSubscription,
  graphql(schema.ClearNotifications, {
    props: ({ownProps: {refetchUser}, mutate }) => ({
      clearNotifications: (notifications) =>
        mutate({variables: {notificationIDs: notifications.map(({id}) => id)}}).then(() => {
          refetchUser()
        })
    })
  })
)(Notifications)
