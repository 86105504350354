import React, {Fragment, Component} from 'react';

import { withRouter } from 'react-router-dom'
import { graphql } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash';
import { withAuth } from './Authorization'
import { PointList } from './PointList'
import { Link } from 'react-router-dom'

import * as schema from '../schema'
import Spinner from "./Spinner";

export class UserPageComponent extends React.Component {
  state = {selectedSection: 'created'}

  render(){
    const {currentUser, user, userLoading, createdPoints, createdLoading, editedPoints, editedLoading} = this.props;
    if (userLoading) {
      return <Spinner />
    }
    else if (user) {
      const {displayName, avatarURL, createdCount, editedCount, bio, websiteURL, notes} = user;
      return (
        <div id="mainContainer" className="userPage">
          <header id="userInfoArea" className="profileAreaColumn">
          
            <div className="blockUnstackOnNarrow">
              {avatarURL ? (
                <img src={avatarURL}
                     className="profilePic"/>
              ) : (
                <img src="/static/img/Ys_Triceratops_PROFILE.png"
                     className="profilePic"/>
              )}
              <div className="row-fluid">
                <h1 id="display_userName" className="">
                  {displayName}
                </h1>
              </div>
            </div>
            
            <div className="blockUnstackOnNarrow">
              { user && user.moderator && <div className="profileStatsArea profileBodyText">
                <div>Moderator<span className="moderatorIcon">&#9670;</span></div>
              </div>}
              <div className="profileStatsArea profileBodyText ">
                <div>{createdCount} Points created</div>
                <div>{editedCount} Contributed to</div>
              </div>
              <div id="display_userBio" className="profileBodyText" >
                {bio && (<div className="profileItem">{bio}</div>)}
                {websiteURL && (
                  <div className="profileItem">
                    <a href={websiteURL} target="_blank" rel="noreferrer">
                      {websiteURL}
                    </a>
                  </div>
                )}
                {currentUser && (currentUser.role == 'Admin') && (
                  <Fragment>
                    <div className="admin">
                      <h5>Notes</h5>
                      <p>{notes}</p>
                    </div>
                  </Fragment>
                ) }
                { currentUser && (currentUser.url == user.url) && (
                  <Link to="/settings" className="profileItem editProfileLink">
                    Edit Settings
                  </Link>
                ) }
              </div>
              
              
            </div>
          </header>
          <div id="profileTabbedArea" className="profileAreaColumn infiniteWidth">
            <div id="profileDisclaimer">
              <span>Remember, Whysaurus users don't always agree with points they create!</span>
              <img className="emoji" src="/static/img/emoji/smiling-face-with-horns_1f608.png"/>
            </div>
            <div className="contentSectionHeading">
              <button onClick={() => this.setState({selectedSection: 'created'})}
                      className={`tabUX2 ${this.state.selectedSection == 'created' && 'selectedTab'}`} id="createdPoints">
                CREATED
              </button>
              <button onClick={() => this.setState({selectedSection: 'contributed'})}
                      className={`tabUX2 ${this.state.selectedSection == 'contributed' && 'selectedTab'}`} id="editedPoints">
                CONTRIBUTED TO
              </button>
            </div>
            {(this.state.selectedSection == "created") && (
              createdLoading ? <Spinner /> : (
              <section id="createdPointsArea" className="">
                {createdPoints && <PointList points={createdPoints}/>}
              </section>
              )
            )}
            {(this.state.selectedSection == "contributed") && (
              editedLoading ? <Spinner /> : (
              <section id="editedPointsArea" className="">
                {editedPoints && <PointList points={editedPoints}/>}
              </section>
              )
            )}
          </div>

        </div>
      )
    } else {
      return ""
    }
  }
}

export default compose(
  withAuth,
  withRouter,
  graphql(schema.ProfileUser, {
    options: ({match}) => ({
      variables: {url: match.params.url}
    }),
    props: ({ownProps, data: {user, loading, error}}) => ({
      user,
      userLoading: loading,
      userError: error
    })
  }),
  graphql(schema.CreatedPoints, {
    options: ({match}) => ({
      variables: {url: match.params.url}
    }),
    props: ({ownProps, data: {createdPoints, loading}}) => ({
      createdPoints,
      createdLoading: loading
    })
  }),
  graphql(schema.EditedPoints, {
    options: ({match}) => ({
      variables: {url: match.params.url}
    }),
    props: ({ownProps, data: {editedPoints, loading}}) => ({
      editedPoints,
      editedLoading: loading
    })
  })
)(UserPageComponent)
