import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import * as validations from '../validations';
import * as schema from '../schema';
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
// import TitleText from './TitleText'
import Spinner from './Spinner'
import { CloseLinkX } from './common'
import ImagePicker from './ImagePicker';
import config from '../config'

const EditImageForm = ({
  point,
  onSubmit,
  onClick,
  onClose,
}) => {
  const [imageUpdated, setImageUpdated] = useState(false);
  const { register, handleSubmit, watch, control } = useForm({
    defaultValues: {
      imageURL: point.imageURL,
      imageDescription: point.imageDescription,
    },
  });

  const imageDescription = watch('imageDescription');
  const imageURL = watch('imageURL');
  const captionButtonLabel = `${point.imageDescription ? "Update " : "Add a " }`

  const submitForm = handleSubmit(onSubmit);

  return (
    <form onSubmit={submitForm}>
      <Controller
        name="imageURL"
        control={control}
        render={({ onChange }) => (
          <ImagePicker
            onChange={onChange}
            onUploaded={(file) => {
              setImageUpdated(true);
              submitForm();
            }}
            onTransformed={(result) => {
              console.log("Saved transformed versions of image:")
              console.log(result)
            }}
          />
        )}
      />
      {imageURL && (
        <img
          className="imageBigDisplay"
          src={config.cdn.baseURL + imageURL}
          alt={imageDescription}
        />
      )}
      <div>
        <label className="inputLabel">{captionButtonLabel} caption, credit, description, etc</label>
        <input
          name="imageDescription"
          ref={register({ minLength: 3, maxLength: validations.titleMaxCharacterCount, })}
          type="text"
          placeholder="Neil Leifer for TIME"
          className="inputFieldUX2"
          onClick={onClick}
        />
      </div>
      <div>
        <button
          type="submit"
          onClick={(imageUpdated) || (imageDescription !== point.imageDescription) ? onClick : onClose}
          className="buttonUX2 buttonSecondaryUX2black pull-right"
        >
          Done
        </button>
      </div>
    </form>
  );
};

//                  <button onClick={this.props.onClose} className="buttonUX2 pull-right">Done</button>



class EditImageComponent extends React.Component {
  state = {saving: false}

  get point() {
    return this.props.point;
  }

  handleClickNoProp = (e) => {
    e.stopPropagation();
  }

  handleClickSave = (values, ev) => {
    values.url = this.point.url
    this.setState({saving: true})
    this.props.save(values)
        .then((result) => {
              this.setState({saving: false})
              //this.props.onClose(e)
            },
            (err) => this.setState({saving: false}))
  }

  renderForm = () => {
    let editImageFormClasses = ""
    if (this.state.saving) {
      return <div className={editImageFormClasses}>
        <span className="claimEditAreaSavingFeedback"><Spinner /><span className="spinnerLabel">Saving...</span></span>
      </div>;
    } else {
      return <div className={editImageFormClasses}>
        <EditImageForm onClick={this.handleClickNoProp} onSubmit={this.handleClickSave} onClose={this.props.onClose} point={this.point} />
      </div>;
    }
  }

  render() {
    let classesEditImageIndent = `${this.props.parentPoint && "addOneIndent"}`
    let classesContainer = `row-fluid claimEditArea editImage ${this.props.wideCard ? "wideCard": ''}`
    let editImageLabel = `${this.props.hasImage ? "Edit Image" : "Add Image"}`
    return <div className={classesEditImageIndent}>
        <div className={classesContainer}>
          <span className="claimEditAreaHeading">
            <span className="heading">{editImageLabel}</span>
            <span className="editAreaClose"><a onClick={this.props.onClose} href="/#"><CloseLinkX/></a></span>
          </span>
          <div className="imageEditForm">
            {this.renderForm()}
          </div>
        </div>
      </div>
  }
}

export default compose(
  graphql(schema.EditPointQuery, {
    props: ({mutate}) => ({
      save: (values) => mutate({
        variables: values
      })
    })
  }),
)(EditImageComponent)
