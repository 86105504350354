import React from 'react'
import MediaQuery from 'react-responsive';
import config from '../config'
import Form from './formNew';
import Spinner from './Spinner'
import { CommentType, QuickCommentBadgeComponent } from  './Comments'
import { editorUtils } from './Editor';

export default class NewComment extends React.Component {
  componentDidMount() {
    const textarea = document.getElementById('newCommentTextField')
    if (textarea) textarea.focus()
  }

  state = {submitting: false}

  submit = (values) => {
    this.setState({submitting: true});
    const payload = {
      ...values,
      richText: JSON.stringify(values.richText),
      text: editorUtils.getTextFromRawContent(values.richText),
    }
    this.props.onSubmit(payload);
  }

  submitButtonLabel = () => {
    return <span>
        <MediaQuery minWidth={config.extraSmallScreenThreshold}>
          Publish
        </MediaQuery>
        <MediaQuery maxWidth={config.extraSmallScreenThreshold}>
          <span className="">Add</span>
        </MediaQuery>
      </span>
  }

  submitButton = ({...rest}) => {
    if (this.state.submitting) {
      return <span className="newCommentFeedback">
        <Spinner /><span className="spinnerLabel">Adding your comment...</span>
      </span>;
    } else {
      return <div>
        <button className="buttonUX2 newCommentPublishButton" type="submit" {...rest}>{this.submitButtonLabel()}</button>
        <button className="cancelButton cancelButtonNewComment" onClick={this.props.onCancel}>Cancel</button>
      </div>
    }
  }

  // labelText = () => {
  //   switch (this.props.commentType) {
  //     case CommentType.CLARIFICATION:
  //       return { labelText: "Describe what needs to be clarified! (Optional)",
  //                defaultText: null }
  //     case CommentType.EVIDENCE:
  //       return { labelText: "Describe why this claim needs evidence! (Optional)",
  //                defaultText: "This claim needs evidence!" }
  //     case CommentType.OTHER:
  //       return { labelText: "Suggest a way to improve this claim",
  //                defaultText: null }
  //     default:
  //       return { labelText: "Suggest a way to improve this claim",
  //                defaultText: null }
  //   }
  // }

  // TO DO: Using the default for replies. Replies probably wants an explicit commentType and distinct placeholderText.
  placeholderText = () => {
    if (this.props.parent) {
      return {
        placeholderText: "Add your reply",
        defaultText: null
      }
    }

    switch (this.props.commentType) {
      case CommentType.CLARIFICATION:
        return { placeholderText: "Describe what needs to be clarified! (Optional)",
                 defaultText: null }
      case CommentType.EVIDENCE:
        return { placeholderText: "Describe why this point needs evidence! (Optional)",
                 defaultText: "Your comment" }
      case CommentType.OTHER:
        return { placeholderText: "Suggest a way to improve this point",
                 defaultText: null }
      default:
        return { placeholderText: "Your comment",
                 defaultText: null }
    }
  }

  quickCommentBadge = () => {
    if (this.props.commentType && this.props.commentType > 0) {
      return <QuickCommentBadgeComponent quickCommentType={this.props.commentType} />
    }
  }

  render(){
    const {placeholderText, defaultText} = this.placeholderText()
    // const {labelText} = this.labelText()

    return (
      <Form
        onSubmit={this.submit}
        className="newCommentCreationForm"
        defaultValues={{
          richText: {},
        }}
      >
        {({ errors }) => {
          const hasErrors = Object.keys(errors).length > 0;

          return (
            <>
              <span className="quickCommentBadgeSpan">
                {this.quickCommentBadge()}
                {this.quickCommentBadge() && <div className="formInstruction">A badge will be added to the point card. Contributors to the point will be notified. The badge can be removed by you or a moderator. </div>}
              </span>
              <div className="newCommentFormFieldBlock">
                {/* <Form.TextArea
                  name="text"
                  className="newCommentTextField inputFieldUX2"
                  placeholder={placeholderText}
                  validate={{ required: true, }}
                /> */}
                {/*<label className="commentHeading">{labelText}</label>*/}
                <Form.Editor
                  name="richText"
                  placeholder={placeholderText}
                />
              </div>
              <div className="newCommentFormButtonBlock">
                {this.submitButton({ disabled: hasErrors && this.props.commentType === 0 })}
              </div>
            </>
          );
        }}
      </Form>
    );
  }
}
