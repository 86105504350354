import React from 'react'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash';
import MediaQuery from 'react-responsive'
import AnimateOnChange from 'react-animate-on-change'
import { withYsAlerts } from './../AlertProvider'
import Modal from "./Modal"
import PropTypes from 'prop-types'
import { Hover, HoverTip } from './common'
import pluralize from 'pluralize'

import * as schema from '../schema'
import config from '../config'

import {PointList} from './PointList'
import AddEvidence from './AddEvidence'
import EditPoint from './EditPoint'
import EditImage from './EditImage'
import EditSources from './EditSources'
import EditTags from './EditTags'
import RelevanceRater from './RelevanceRater'
import EditEditorsPick from './EditEditorsPick'
import Comments from './Comments'
import {QuickCommentBadgeList} from './Comments'
import { timestampLastEdit } from './common'
import Spinner from './Spinner'
import {withExpandedIndex, withExpandedIndexForPoint} from './ExpandedIndex'
import { withAuth } from './Authorization'
import { fbq } from '../fb'
import FB from 'react-facebook-pixel';
import { gtmEvent, gtmCustom } from '../gtm'
import OutsideClickDetector from './OutsideClickDetector'
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link'
import { InlineIcon } from "@iconify/react";
import explodingHead from '@iconify/icons-noto/exploding-head';
import blackHeart from '@iconify/icons-emojione-monotone/black-heart';
import redTrianglePointedUp from '@iconify/icons-noto/red-triangle-pointed-up';
import laughSquint from '@iconify/icons-fa-regular/laugh-squint';
import FontAwesomeIcon from './FontAwesomeIcon'



export const EvidenceType = Object.freeze({
    ROOT: Symbol("root"),
    SUPPORT:  Symbol("supporting"),
    COUNTER: Symbol("counter")
});

// TODO: Where does this belong?
export const EmojiTypes = [
  { code: "Δ", text: "Changed My Mind", icon: redTrianglePointedUp, iconClass: "emojiDelta", isQuestionRelevant: false },
  { code: "🤯", text: "Mind Blown", icon: explodingHead, iconClass: "emojiMindBlown", isQuestionRelevant: false },
  { code: "🤣", text: "Laugh", icon: laughSquint, iconClass: "emojiLaugh" },
  { code: "❤", text: "Love", icon: blackHeart, iconClass: "emojiLove" },
];

// TODO: Where should this be centralized with the backend?
export const PointTypeQuestion = 2;

export class AuthorComponent extends React.Component {
  handleClickNoProp = (e) => {
    e.stopPropagation();
  }

  render() {
    const {userUrl, userName} = this.props
    return <span className="authorSpan">
      By <a className="bylineAuthor" onClick={this.handleClickNoProp} tabIndex="-1" href={"/user/" + userUrl}>@{userName}
      { this.props.userLoading ? <span /> : this.props.user && this.props.user.moderator && <span className="moderatorIcon">&#9670;</span> }</a>
    </span>
  }
}

AuthorComponent.propTypes = {
  userName: PropTypes.string,
  userUrl: PropTypes.string
}

export const Author = compose(
  // withAuth,
  // withRouter,
  graphql(schema.UserQuery, {
    options: ({ userUrl }) => ({
      variables: { url: userUrl }
    }),
    props: ({ownProps, data: { loading, user}}) => ({
      user,
      userLoading: loading
    })
  })
)(AuthorComponent)

// TODO: EmojiList and QuickCommentList probably shouldn't be inside the Byline component
export class BylineComponent extends React.Component {
  state = {showContributorsMenu: false}

  handleClickNoProp = (e) => {
    e.stopPropagation();
  }

  get contributorsPlusAuthor() {
    return this.props.point.numUsersContributed + 1
  }

  contributorsToDisplayOnByline = () =>
    this.contributorsPlusAuthor > 1 ? this.contributorsPlusAuthor : ""

  contributorsDropdown = () => {
    const {showContributorsMenu} = this.state;
    if (this.contributorsPlusAuthor > 1) {
      let contributorsTooltip = `${this.contributorsPlusAuthor}\xa0Contributor${(this.contributorsPlusAuthor > 1) ? "s" : ""}`
      return <span className="cardTopRowItem noMarginRight">
        <span className="dropdown contributors">
          <Hover delay="1" onHover={<HoverTip title={contributorsTooltip}/>}>
            <a className="easierToClickOn dropdown-toggle" data-toggle="dropdown"
               onClick={(e) => e.stopPropagation() || this.setState({showContributorsMenu: !showContributorsMenu})}>
              <span className="iconWithStat">
                <FontAwesomeIcon name="user" type="far" />
              </span>
              <span className="number">{this.contributorsToDisplayOnByline()}</span>
            </a>
            {showContributorsMenu && (
              <OutsideClickDetector component="ul"
                                    action={() => this.setState({showContributorsMenu: false})}
                                    className="contributorsMenu dropdown-menu dropdown-menu-with-caret" role="menu" aria-labelledby="dropdownMenu">
                <div className="dropdown-caret"><div className="caret-outer"/><div className="caret-inner"/></div>
                <li><span><span className="number">{this.contributorsPlusAuthor}</span> Contributors</span></li>
              </OutsideClickDetector>
            )}
          </Hover>
        </span>
      </span>
    }
    else return ""
  }

  //TO DO: make this work; possibly make it global, its currently being declared twice in this document
  currentUserIsAdmin = () => (
    this.props.currentUser && this.props.currentUser.admin
  )

  editorsPickBadge = () => {
    return  <span className="cardTopRowItem ">
              <span className="iconEditorsPick">
                <FontAwesomeIcon name="trophy" />
              </span>
              <span className="flareLabel">Editor's Pick</span>
              { this.currentUserIsAdmin() && <span className="admin bylineAdminItem">sort: {this.props.point.root.editorsPickSort}</span>}
          </span>
  }

  render(){
    const {dateEdited, isRecentlyUpdated, creatorURL, creatorName} = this.props.point
    return <span className="byline pointCardPaddingH">
      <span className="cardTopRowItem"><Author userUrl={creatorURL} userName={creatorName} /></span>
      {this.contributorsDropdown()}
      <span className="cardTopRowItem lastEdit">{timestampLastEdit(dateEdited, isRecentlyUpdated)}</span>
      {this.props.point.root.editorsPick && this.editorsPickBadge()}
      { this.currentUserIsAdmin() && this.props.point.stale && <span className="cardTopRowItem"><span className="admin bylineAdminItem"><span className="iconWithStat"><FontAwesomeIcon name="bread-slice" /></span>stale</span></span>}
      <Tags point={this.props.point}/>
      <EmojiListComponent point={this.props.point}/>
    </span>
  }
}

BylineComponent.propTypes = {
  point: PropTypes.shape({
    isRecentlyUpdated: PropTypes.bool,
    dateEdited: PropTypes.string.isRequired,
    authorName: PropTypes.string.isRequired,
    authorURL: PropTypes.string.isRequired,
    creatorName: PropTypes.string,
    creatorURL: PropTypes.string
  })
}

export const Byline = compose(
  withAuth
  // withRouter,
)(BylineComponent)

// TO DO: create a string var for description, caption and quote
export class ShareAreaComponent extends React.Component {
  postOnFacebook = (e) => {
    e.preventDefault();
    e.stopPropagation();
    var url = this.props.point.url;
    var linkUrl = "https://www.whysaurus.com/claim/" + url;
    var pointTitle = this.props.point.title;
    var dialogParams = {
        app_id: 144595249045851,
        method: 'feed',  // 'feed' / 'share'
        name: pointTitle,
        link: linkUrl,
        href: linkUrl,
        description: 'See evidence and add your voice on @Whysaurus:\n\n' + pointTitle,
        caption: 'See evidence and add your voice on @Whysaurus:\n\n' + pointTitle,
        quote:  'See evidence and add your voice on @Whysaurus:\n\n' + pointTitle,
        display: 'popup'  // 'popup' / 'iframe'
    };
    var imageUrl = this.props.point.imageURL || null;
    if (!imageUrl) {
        // if there is no image in the page, pass the logo
        imageUrl = window.location.protocol + "//" + window.location.host + "/static/img/whysaurus_logo.png";
        dialogParams['picture'] = imageUrl;
    } else {
        imageUrl = imageUrl.slice(2);
    }

    //ga('send', 'event', 'SharePoint', 'FacebookSharePoint', this.url);
    gtmEvent('SharePoint', 'FacebookSharePoint', this.url);
    let userUrl = null
    if (this.props.currentUser) {
      gtmEvent('SharePointUser', 'FacebookShareUser', this.props.currentUser.url);
      userUrl = this.props.currentUser.url
    }
    else {
      console.error('No user for FB share')
    }
    fbq('trackCustom', 'SharePointFacebook', {
      url: this.url,
      userUrl: userUrl,
    });

    FB.ui(dialogParams, function(response){
      if (response && response.error_message) {
        console.error('FB.ui Error: %s' % response.error_message)
      }
    });
  }

  // Code from when Twitter's character length was < Ys character length:
  /*
    var len = pointTitle.length;
    var text = "";
    if (len > 225) {
        text = pointTitle.substring(0,275) + "..." + "https://www.whysaurus.com/claim/" + url;
    } else {
        text = pointTitle + " — See evidence and add your voice on @Whysaurus: https://www.whysaurus.com/claim/" + url;
    }
  */
  shareOnTwitter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    var url = this.props.point.url;
    var pointTitle = this.props.point.title;
    const text = pointTitle + " — See evidence and add your voice on @Whysaurus: https://www.whysaurus.com/claim/" + url;
    var webUrl = "http://twitter.com/intent/tweet?text="+encodeURIComponent(text);

    //ga('send', 'event', 'SharePoint', 'TwitterSharePoint', url);
    gtmEvent('SharePoint', 'TwitterSharePoint', url);
    let userUrl = null;
    if (this.props.currentUser) {
      userUrl = this.props.currentUser.url;
      gtmEvent('SharePointUser', 'TwitterShareUser', this.props.currentUser.url);
    }
    else {
      console.error('No user for Twitter share')
    }
    fbq('trackCustom', 'SharePointTwitter', {
      url: this.url,
      userUrl: userUrl,
    });

    window.open(webUrl,'_blank');
  }

  copyPointUrl = (e) => {
    e.preventDefault();
    e.stopPropagation();
    var url = this.props.point.url;
    var fullUrl = this.generateFullLinkUrl();

    navigator.clipboard.writeText(fullUrl).then(function() {
      console.log('Copied URL to clipboard: ' + fullUrl);
      //this.props.alert.show('Copied URL to clipboard: ' + fullUrl)
    }, function(err) {
      console.error('Couldnt copy URL to clipboard: ' + fullUrl, err);
      //this.props.alert.show('URL (unable to copy): ' + fullUrl)
    });

    gtmEvent('SharePoint', 'CopySharePoint', url);
    let userUrl = null;
    if (this.props.currentUser) {
      userUrl = this.props.currentUser.url;
      gtmEvent('SharePointUser', 'CopyShareUser', this.props.currentUser.url);
    }
    else {
      console.error('No user for CopyUrl share')
    }
    fbq('trackCustom', 'SharePointCopy', {
      url: this.url,
      userUrl: userUrl,
    });

    //showAlert('Copied URL: ' + fullUrl);
    this.props.alert.show('Copied URL to clipboard — ' + fullUrl);
  }

  generateFullLinkUrl = () => "https://www.whysaurus.com/claim/" + this.props.point.url

  generateMailToUrl = () => "mailto:?subject=Someone is wrong on the internet&body=Check out this argument on Whysaurus and add your voice!%0D%0A%0D%0A" + this.props.point.title + ". %0D%0Ahttps://www.whysaurus.com/claim/" + this.props.point.url + ""

  render(){
    return this.props.children({
      copyPointUrl: this.copyPointUrl,
      postOnFacebook: this.postOnFacebook,
      shareOnTwitter: this.shareOnTwitter,
      generateFullLinkUrl: this.generateFullLinkUrl,
      generateMailToUrl: this.generateMailToUrl
    })
  }
}

export const ShareArea = withYsAlerts(withAuth(ShareAreaComponent))

export const ShareIconArea = ({point}) => <ShareArea point={point}>
  {({copyPointUrl, generateFullLinkUrl, postOnFacebook, shareOnTwitter, generateMailToUrl}) => (
    <span className="shareIconArea">
      <a onClick={copyPointUrl} href={generateFullLinkUrl()}>
        <div className="claimShareIcon">
          <FontAwesomeIcon name="link" />
        </div>
      </a>
      <a onClick={postOnFacebook}>
        <div className="claimShareIcon">
          <FontAwesomeIcon name="facebook-square" type="fab" />
        </div>
      </a>
      <a onClick={shareOnTwitter}>
        <div className="claimShareIcon">
          <FontAwesomeIcon name="twitter" type="fab" />
        </div>
      </a>
      <a target="_blank" href={generateMailToUrl()} rel="noreferrer">
        <div className="claimShareIcon">
          <FontAwesomeIcon name="envelope" type="far" />
        </div>
      </a>
    </span>
  )}
</ShareArea>

ShareIconArea.propTypes = {
  point: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imageURL: PropTypes.string
  })
}


function SupportingCount(props){
  return <span className="cardTopRowItem"><span className="iconWithStat"><FontAwesomeIcon name="level-up-alt" /></span><span className="number">{props.point.supportedCount}</span> Other Links</span>
}

function EngagementScore(props){
  return <span><span className="number">{props.point.engagementScore}</span><span> Engagement</span></span>
}

function ControversyScore(props){
  return <span><span className="number">{props.point.controversyScore}</span><span> Controversy</span></span>
}

function UsersContributed(props){
  const numUsersContrib = props.point.numUsersContributed || 0
  return <span><span className="number">{numUsersContrib}</span><span> User{numUsersContrib !== 1 ? "s" : null} Contributed</span></span>
}

export class StatsComponent extends React.Component {

  get sortScore() {
    if (this.props && this.props.link) {
      return this.props.link.sortScore
    }
    else {
      return -1
    }
  }

  adminStats() {
    if (this.props.currentUser && this.props.currentUser.admin)
      return <div>
        <p className="admin">
          <EngagementScore point={this.props.point} /><br/>
          <ControversyScore point={this.props.point} /><br/>
          <UsersContributed point={this.props.point} /><br/>
          { this.props && this.props.link &&  <span><span className="number">{this.sortScore}</span> Sort<br/></span> }
        </p>
        <div className="menuDivider"/>
      </div>
  }

  render() {
    return <div className="stats hoverTip">
      <div className="dropdown-caret"><div className="caret-inner"/></div>
      <p>
        <span className="number">{this.props.point.numSupporting}</span> Supporting Point{this.props.point.numSupporting !== 1 ? "s" : null}<br/>
        <span className="number">{this.props.point.numCounter}</span> Counter Point{this.props.point.numCounter !== 1 ? "s" : null}<br/>
      </p>
      <div className="menuDivider"/>
      <p>
        <span className="number">{this.props.point.upVotes}</span> Agrees<br/>
        <span className="number">{this.props.point.downVotes}</span> Disagrees<br/>
        { this.props.point.controversyScore > 1 && <span><span className="controversyIcon"><FontAwesomeIcon type="far" name="fire-alt" /></span>Controversial</span> }
      </p>
      <div className="menuDivider"/>
      {this.adminStats()}
      <p>
        <span className="scoreLearnMore"><HashLink to="/faq#HowAreClaimsScored"><FontAwesomeIcon name="info-circle" /><span className="hashLinkCopy">Learn More about Scores</span></HashLink></span>
      </p>
    </div>
  }
}

StatsComponent.propTypes = {
  point: PropTypes.shape({
    numUsersContributed: PropTypes.number,
    engagementScore: PropTypes.number.isRequired,
    controversyScore: PropTypes.number.isRequired,
    numSupporting: PropTypes.number.isRequired,
    numCounter: PropTypes.number.isRequired,
    upVotes: PropTypes.number.isRequired,
    downVotes: PropTypes.number.isRequired
  }),
  link: PropTypes.shape({
    sortScore: PropTypes.number.isRequired
  }),
  currentUser: PropTypes.shape({
    admin: PropTypes.bool
  })
}

const Stats = withAuth(StatsComponent)

// used in PointCard and in PointList for the irrelevant claims links
export class LinkedClaimArrow extends React.Component {
  render(){
    let classeslLinkedClaimArrow = `linkedClaimArrow ${this.props.relRaterExpanded && "relRaterExpanded"}`
    let classesArrowStemEvidence = `arrowStemEvidence connectingLineElbow ${this.props.evidenceType}`
    let classesArrowHead = `${this.props.evidenceType} arrowHeadUp`
    return <span className={classeslLinkedClaimArrow}>
      <div className={classesArrowStemEvidence}/>
      <div className={classesArrowHead}/>
    </span>
  }
}


export class PointComponent extends React.Component {
  handleToggleEvidence = (e) => {
    e.preventDefault();
    e.stopPropagation(); //JF: prevents click from passing up to parent, which seems to break the functionality (even though they do the same thing)
    this.props.onClick && this.props.onClick()
  }

  titleUI = () => {
    const HX = this.props.isPrimaryClaimView ? `h1` : `h2`;
    return <HX className="pointTitle">
      <a tabIndex="-1" onClick={this.handleToggleEvidence} href={"/claim/" + this.props.point.url}>{this.props.point.title}</a>
    </HX>
  }

  // TODO: make AnimateOnChange only animate where it should -JF
  render(){
    const score = this.props.point.pointValue
    let classesContainer = `claimTextDisplay pointCardPaddingH pointCardPaddingHExtra  ${this.props.wideCard ? "wideCard": ''}`
    return <div className={classesContainer}>
      {this.titleUI()}
      <span className="scoreAnimContainerMax score">
        <span className="scoreAnimContainerReset">
          <Hover onHover={<Stats point={this.props.point} link={this.props.link} />}>
            <span className="ux2ScoreInLine number">
              <span className={score < 0 ? "negativeScore": "positiveScore"}>
                <AnimateOnChange baseClassName="scorePreAnimate" animationClassName="Score--bounce" animate={score.diff !== 0}>
                  {score >= 0 && "+"}{score}
                </AnimateOnChange>
                {this.props.point.controversyScore > 1 &&
                  <span className="controversyIcon">
                    <FontAwesomeIcon type="far" name="fire-alt" />
                  </span>
                }
              </span>
            </span>
          </Hover>
        </span>
      </span>
    </div>
  }
}

PointComponent.propTypes = {
  onClick: PropTypes.func,
  point: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    pointValue: PropTypes.number.isRequired
  }),
  link: PropTypes.shape({
    sortScore: PropTypes.number.isRequired
  })
}

const Point = PointComponent

class Sources extends React.Component {
  render(){
    const sources = this.props.point.sources
    return <div className="sources pointCardPaddingH">
      {sources && sources.map(({name, url}, i) =>
        <div key={i} className="sourceRow">
          <a key={i} className="source" tabIndex="-1" target="_blank" href={url} rel="noreferrer">
            <span className="iconSourcesSmall">
              <FontAwesomeIcon name="book-open" />
            </span>
            <span className="sourceLabel">{name || url}</span>
          </a>
        </div>
      )}
    </div>
  }
}

/*          <span className="iconTagsSmall">
            <span className="fas fa-hashtag"></span>
          </span>*/
class Tags extends React.Component {
  render(){
    const tags = this.props.point.tags
    let classesTags = `${tags && "cardTopRowItem"} tags`
    return <div className={classesTags}>
      {tags && tags.map(({text, url}, i) =>
        <span key={i} className="tag" tabIndex="-1" >
          {/*<a key={i} className="tag" tabIndex="-1" target="_blank" href={"/tags/" + url} />*/}
          <a className="tagLink" tabIndex="-1" href={"/y/" + url}>
          <span className="tagLabel"><span className="hashtag">#</span>{text || url}</span>
          </a>
        </span>
      )}
    </div>
  }
}

class EmojiComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hovering: false
    }
  }

  handleRemoveEmoji = (e, id) => {
    const pointUrl = this.props.point.url;
    e.stopPropagation()
    // console.log("handleRemoveEmoji: " + id)
    this.props.removeEmoji(id, pointUrl).then(
        (success) => {

        },
        (error) => {
          // TODO: Actual error handling!
          console.error("handleRemoveEmoji error: " + error)
        })
  }

  handleAddEmoji = (e, id) => {
    const pointUrl = this.props.point.url;
    e.stopPropagation()
    // console.log("AddEmoji: " + id)
    this.props.addQuickEmoji(id, pointUrl).then(
        (success) => {

        },
        (error) => {
          // TODO: Actual error handling!
          console.error("Add emoji error: " + error)
        })
  }

  handleEmojiClick = (e, id, isUserEmoji) => {
    if (!this.props.currentUser){
      console.log('Login Required To Add Emoji')
      e.stopPropagation()
      gtmEvent('Required login',  'Require login add emoji')
      fbq('trackCustom', 'RequiredLogin');
      this.props.showLoginDialog()
      return
    }

    if (isUserEmoji) {
      this.handleRemoveEmoji(e, id);
    } else if (isUserEmoji === false) {
      this.handleAddEmoji(e, id);
    } else {
      // Likely not logged in
    }
  }

  render() {
    const {id, emojiCode, count, isUserEmoji} = this.props;
    let emojiType = EmojiTypes.find(({ code }) => code === emojiCode)
    let icon = null
    let classesEmoji = `emojiBlock`;
    let classesEmojiIcon = "emoji";
    if (emojiType) {
      icon = emojiType.icon
      if (emojiType.iconClass) {
        classesEmojiIcon += " " + emojiType.iconClass
      }
    }
    // TODO: Would be nice to pass first/top/etc other user to include in the tooltip
    let tooltipText = ""
    if (isUserEmoji) {
      if (count > 1) {
        tooltipText = "You & " + (count - 1) + " other" + (count > 2 ? "s" : "");
      } else {
        tooltipText = "Your emoji";
      }
      tooltipText += " - Click to remove";
    } else {
      tooltipText = "" + count + " other user" + (count > 1 ? "s" : "") + " - Click to add";
    }
    const handleMouseEnter = () => this.setState({hovering : true});
    const handleMouseLeave = () => this.setState({hovering : false});
    // {isUserEmoji && <span className="emojiClose emojiHoverIcon"><CloseLinkX/></span>}
    // {isUserEmoji == false && <span className="fas fa-plus emojiHoverIcon" aria-hidden="true" />}
    return <span className={classesEmoji} tabIndex="-1" onClick={(e)=>this.handleEmojiClick(e, id, isUserEmoji)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Hover delay="1" onHover={<HoverTip title={tooltipText} />}>
        <span className={classesEmojiIcon}><InlineIcon icon={icon} /></span>
        {(count > 1) && <span className="number inlineBlock">{count}</span>}
      </Hover>
    </span>
  }
}

const Emoji = compose(
  withAuth,
  graphql(schema.RemoveEmoji, {
    props: ({ mutate }) => ({
      removeEmoji: (id, pointUrl) => mutate({variables: {id},
        refetchQueries: [{query: schema.GetPoint, variables: {url: pointUrl}}]})
    })
  }),
  graphql(schema.AddQuickEmoji, {
    props: ({ mutate }) => ({
      addQuickEmoji: (id, pointUrl) => mutate({variables: {id},
        refetchQueries: [{query: schema.GetPoint, variables: {url: pointUrl}}]})
    })
  }),
)(EmojiComponent)

class EmojiListComponent extends React.Component {
  render(){
    const emojis = this.props.point.emojis
    return <span className="emojiList">
      {emojis && emojis.map(({id, emojiCode, count, isUserEmoji}, i) =>
        <span key={i}>
          <Emoji id={id} point={this.props.point} emojiCode={emojiCode} count={count} isUserEmoji={isUserEmoji} />
        </span>
        // <span key={i} className="emoji" tabIndex="-1" onClick={(e)=>this.handleEmojiClick(e, id, isUserEmoji)}>
        //   <span className="tagLabel">{emojiCode}</span>
        //   {(count > 1) && <span className="number inlineBlock">{count}</span>}
        //   {isUserEmoji &&  <a onClick={(e)=>this.handleRemoveEmoji(e, id)} data-toggle="tooltip" title="Remove My Emoji"><span className="fas fa-window-close" aria-hidden="true" /></a>}
        //   {isUserEmoji == false &&  <a onClick={(e)=>this.handleAddEmoji(e, id)} data-toggle="tooltip" title="Add My Emoji"><span className="fas fa-plus" aria-hidden="true" />1</a>}
        // </span>
      )}
    </span>
  }
}

//JF: Gene: Needs styling - classes reused from CommentsLink
// fa-smile-o ? fa-thumbs-o-up ?
class EmojiLinkComponent extends React.Component {
  state = {dropdownVisible: false}

  onClick = (e) => {
    console.log('Emoji Click?')
    e.stopPropagation();
    if (this.props.currentUser){
      // this.setState({commenting: true, commentType: commentType})
    } else {
      console.log('Emoji Click')
      // $("#loginDialog").modal("show");
      // ga('send', 'event', 'Required login ',  'Require login create comment')
    }
  }

  emojiSelectChange = (e) => {
    const {point, newEmoji} = this.props;
    e.stopPropagation();
    const ccValue = e.target.value;
    if (!ccValue) {
      console.error('Invalid Emoji: %s', ccValue);
      return;
    }
    console.log('Add Emoji: %s', ccValue);
    gtmEvent('Emoji', 'AddEmoji', ccValue);
    fbq('trackCustom', 'AddEmoji', {
      url: point.url,
      emoji: ccValue,
    });
    newEmoji(point.url, ccValue)
  }

  emojiSelectClick = (e) => {
    if (!this.props.currentUser){
      console.log('Login Required To Add Emoji');
      //$("#loginDialog").modal("show");
      this.props.showLoginDialog();
      gtmEvent('Required login',  'Require login add emoji');
      fbq('trackCustom', 'RequiredLogin');
      return
    }

    e.stopPropagation();
  }

  emojiLiClick = (e) => {
    this.setState({dropdownVisible: false});
    e.stopPropagation();
  }

  emojiClick = (emojiCode, isUserEmoji) => {
    this.setState({dropdownVisible: false});
    //e.stopPropagation();
    this.emojiSelected(emojiCode, isUserEmoji);
  }

  emojiSelected = (emojiCode, isUserEmoji) => {
    const {point, newEmoji, removeEmojiForUser} = this.props;
    if (!emojiCode) {
      console.error('Invalid Emoji Code: %s', emojiCode);
      return;
    }
    if (!this.props.currentUser){
      console.log('Login Required To Add Emoji')
      this.props.showLoginDialog()
      gtmEvent('Required login',  'Require login add emoji')
      fbq('trackCustom', 'RequiredLogin')
      return
    }
    if (isUserEmoji)  {
      console.log('Remove Emoji: %s', emojiCode);
      gtmEvent('Emoji', 'RemoveEmoji', emojiCode);
      fbq('trackCustom', 'RemoveEmoji', {
        url: point.url,
        emoji: emojiCode,
        userUrl: this.props.currentUser.url
      });
      removeEmojiForUser(point.url, emojiCode)
    } else {
      console.log('Add Emoji: %s', emojiCode);
      gtmEvent('Emoji', 'AddEmoji', emojiCode);
      fbq('trackCustom', 'AddEmoji', {
        url: point.url,
        emoji: emojiCode,
        userUrl: this.props.currentUser.url
      });
      newEmoji(point.url, emojiCode)
    }
  }

  render(){
    const {point} = this.props;
    let userEmojiCodes = [];
    if (point.emojis) {
      for (var emoji of point.emojis) {
        if (emoji && emoji.isUserEmoji) {
          userEmojiCodes.push(emoji.emojiCode);
        }
      }
    }
    let isQuestion = point && point.pointType === PointTypeQuestion;
    let emoijiEmojiMenuClasses = `dropdown-menu ${isQuestion && "question"}`
    return <span>
      <button onClick={(e) => { this.setState({dropdownVisible: !this.state.dropdownVisible}); e.stopPropagation(); }}
           className="btn btn-link cardBottomActionBlock iconBlock emojiMenuBlock" >
        <span className="cardBottomAction emojiMenuLink"><span className="emoji"><FontAwesomeIcon name="smile" type="far" /></span><span className="emojiMenuPlus">+</span></span>
      </button>
      {this.state.dropdownVisible && (
          <span className="dropdown">
            <OutsideClickDetector component='ul'
                              action={() => this.setState({dropdownVisible: false})}
                              onClick={this.emojiLiClick}
                              id="emojiMenu" className={emoijiEmojiMenuClasses} role="menu" aria-labelledby="dropdownMenu">
              {EmojiTypes.map(({code, text, icon, iconClass, isQuestionRelevant}, i) => {
                if (isQuestion && isQuestionRelevant === false) {
                  return ''
                }
                let isUserEmoji = userEmojiCodes.includes(code)
                let classesEmoji = `emoji ${iconClass}`
                return <li key={i} className={isUserEmoji?"emojiUserSelected":""} onClick={()=>this.emojiClick(code, isUserEmoji)}>
                  <a><span className={classesEmoji}><InlineIcon icon={icon} /></span><span className="menuItemLabel">{text}</span></a>
                </li>
              }
              )}
              {/* <li onClick={()=>this.emojiClick("Δ")}><a><span className={this.getEmojiClass("Δ", userEmojiCodes)}>Δ</span><span className="menuItemLabel">Changed My Mind</span></a></li>
              <li onClick={()=>this.emojiClick("🤯")}><a><span className={this.getEmojiClass("🤯", userEmojiCodes)}>🤯</span><span className="menuItemLabel">Mind Blown</span></a></li>
              <li onClick={()=>this.emojiClick("❤")}><a><span className={this.getEmojiClass("❤", userEmojiCodes)}>❤</span><span className="menuItemLabel">Love</span></a></li>
              <li onClick={()=>this.emojiClick("🤣")}><a><span className={this.getEmojiClass("🤣", userEmojiCodes)}>🤣</span><span className="menuItemLabel">Laugh</span></a></li> */}
            </OutsideClickDetector>
        </span>
      )}
    </span>
  }
}

// TODO: Change refetch queries to point!
const EmojiLink = compose(
  withAuth,
  graphql(schema.NewEmoji, {
    props: ({ mutate }) => ({
      newEmoji: (pointUrl, emojiCode) => mutate({variables: {pointUrl, emojiCode}, 
        refetchQueries: [{query: schema.GetPoint, variables: {url: pointUrl}}]
      })
    })
  }),
  graphql(schema.RemoveEmojiForUser, {
    props: ({ mutate }) => ({
      removeEmojiForUser: (pointUrl, emojiCode) => mutate({variables: {pointUrl, emojiCode}, 
        refetchQueries: [{query: schema.GetPoint, variables: {url: pointUrl}}]
      })
    })
  }),
)(EmojiLinkComponent)

/*   TO DO set comment label MediaQuery*/
class CommentsOnClaimToggle extends React.Component {
  render(){
    let classesCommentLink = `cardBottomAction commentLink ${(this.props.point.root.numComments > 0) && "hasComments"}`
    let classesCommentIcon = `iconWithStat ${(this.props.point.root.numComments > 0) ? "fas fa-comment" : "far fa-comment"} `
    let commentLabel = `${(this.props.point.root.numComments > 1) ? "Comments" : "Comment"}`
    return <Hover delay="1" onHover={<HoverTip title="Comments"/>}>
      <a className="cardBottomActionBlock iconBlock" onClick={this.props.onClick}>
        <span className={classesCommentLink}>
          <span className={classesCommentIcon}/>
          { (this.props.point.root.numComments > 0) && <span className="number inlineBlock">{this.props.point.root.numComments}</span> }
          <MediaQuery minWidth={config.commentLabelThreshold}>
            <span>{commentLabel}</span>
          </MediaQuery>
        </span>
      </a>
    </Hover>
  }
}

class CommentOnLinkIcon extends React.Component {
  render(){
    let classesCommentIcon = `iconWithStat commentLink ${(this.props.count > 0) ? "fas" : "far"} fa-comment`
    return <span className="commentIcon">
        <span className={classesCommentIcon}/>
        <span className="linkCommentIconCount number">{this.props.count > 0 ? String(this.props.count) : ""}</span>
      </span>
  }
}

class EvidenceLink extends React.Component {
  hasEvidence = () => {
    const {point} = this.props;
    return point.numSupporting > 0 || point.numCounter > 0 || point.numAnswers > 0;
  }

  isAnswerLink = () => {
    const {point} = this.props;
    return point.pointType === PointTypeQuestion;
  }

  handleClickSee = (e) => {
    e.stopPropagation(); // prevents click from passing up to parent, which seems to break the functionality (even though they do the same thing)
    this.props.onSee && this.props.onSee()
  }

  handleClickHide = (e) => {
    e.stopPropagation(); // prevents click from passing up to parent, which seems to break the functionality (even though they do the same thing)
    this.props.onHide && this.props.onHide()
  }

  seeEvidenceText = () => {
    if (this.isAnswerLink()) {
      return "See Answers"
    } else {
      return "See Evidence"
    }
  }

  addEvidenceText = () => {
    if (this.isAnswerLink()) {
      return "Add Answer"
    } else {
      return "Add Evidence"
    }
  }

  // <MediaQuery minWidth={config.extraextraSmallScreenThreshold}>See </MediaQuery>
  render() {
    let classesEvidenceBlock = `cardBottomActionBlock seeEvidenceBlock`;
    let classesEvidenceAction = `cardBottomAction seeEvidenceAction`;
    if (this.hasEvidence()) {
      if (this.props.expanded) {
        if (this.props.expansionLoading) {
          return <span className={classesEvidenceBlock}><span className={classesEvidenceAction}>Loading...</span></span>
        } else {
          return <a className={classesEvidenceBlock} onClick={this.handleClickHide}><span className={classesEvidenceAction}><FontAwesomeIcon name="chevron-down" />Close Below</span></a>
        }
      } else {
        return <a className={classesEvidenceBlock} onClick={this.handleClickSee} onMouseOver={this.props.mouseOverPreload}><span className={classesEvidenceAction}><FontAwesomeIcon name="chevron-right" />{this.seeEvidenceText()}</span></a>
      }
    } else {
      if (this.props.expanded) {
        return <a className={classesEvidenceBlock} onClick={this.handleClickHide}><span className={classesEvidenceAction}><FontAwesomeIcon name="chevron-down" />Close Below</span></a>
      } else {
        return <a className={classesEvidenceBlock} onClick={this.handleClickSee}><span className={classesEvidenceAction}><FontAwesomeIcon name="chevron-right" />{this.addEvidenceText()}</span></a>
      }
    }
  }
}

class AgreeDisagreeComponent extends React.Component {
  state = {
    voting: false
  }

  get url() {
    return this.props.point.url
  }

  get currentVote() {
    return this.props.point.currentUserVote
  }


  //JF: This is something I started, but then got backburnered. It would move focus to the next point card, using tabbable.js plugin
  focusOnNextCard = () => {
    // TV: this is tricky with the current setup - disable for now but revisit in the future
    //    setTimeout(function () { $.tabNext() } , 900)
  }

  setVoting = () => this.setState({voting: true})
  unsetVoting = () => this.setState({voting: false})

  handleClickAgree = (e) => {
    e.stopPropagation(); // prevents click from passing up to the parent, which would toggle expansion
    console.log("AgreeDisagreeComponent : agree");
    if (this.currentVote === 1) {
      gtmEvent('Vote', 'NeutralFromUp', this.url, 0);
    }
    else {
      gtmEvent('Vote', 'Up', this.url, 1);
    }
    // GB: TODO: Handle revotes
    fbq('trackCustom', 'Agree', {
      url: this.url,
      value: 1,
      priorValue: this.currentVote,
    });
    if (this.props.currentUser){
      (this.currentVote === 1 ? this.props.withdrawVote() : this.props.agree()).
        then(res => this.unsetVoting())
      this.setVoting()
      this.focusOnNextCard()
    } else {
      this.props.showLoginDialog && this.props.showLoginDialog()
    }
  }

  handleClickDisagree = (e) => {
    e.stopPropagation(); // prevents click from passing up to the parent, which would toggle expansion
    console.log("AgreeDisagreeComponent : disagree");
    if (this.currentVote === -1) {
      gtmEvent('Vote', 'NeutralFromDown', this.url, 0);
    }
    else {
      gtmEvent('Vote', 'Down', this.url, -1);
    }
    // GB: TODO: Handle revotes
    fbq('trackCustom', 'Disagree', {
      url: this.url,
      value: -1,
      priorValue: this.currentVote
    });
    if (this.props.currentUser){
      (this.currentVote === -1 ? this.props.withdrawVote() : this.props.disagree()).
        then(res => this.unsetVoting())
      this.setVoting()
      this.focusOnNextCard()
    } else {
      this.props.showLoginDialog && this.props.showLoginDialog()
    }
  }

  render(){
    let classesAgreeBlock = `cardBottomActionBlock agree ${this.props.point.currentUserVote === 1 && " current-vote"}`
    let classesDisagreeBlock = `cardBottomActionBlock disagree ${this.props.point.currentUserVote === -1 && " current-vote"}`
    let classesAgreeAction = `cardBottomAction agree ${this.props.point.currentUserVote === 1 && " current-vote"}`
    let classesDisagreeAction = `cardBottomAction disagree ${this.props.point.currentUserVote === -1 && " current-vote"}`
    return <span className="agreeDisagree" >
      <span className={classesAgreeBlock} disabled={this.state.voting} onClick={this.handleClickAgree}>
        <a className={classesAgreeAction}>Agree</a>
      </span>
       <span className={classesDisagreeBlock} disabled={this.state.voting} onClick={this.handleClickDisagree}>
        <a className={classesDisagreeAction}>Disagree</a>
      </span>
    </span>
    }
}

export const AgreeDisagree = compose(
  graphql(schema.VoteQuery,{
    props: ({ mutate, ownProps: {point, parentPoint} }) => {
      const vote = (v) => mutate({variables: {url: point.url, parentURL: parentPoint && parentPoint.url, vote: v}})
      return {
        agree: (url, parentURL) => vote(1),
        disagree: (url, parentURL) => vote(-1),
        withdrawVote: (url, parentURL) => vote(0)
      }
    }
  }),
  withAuth
)(AgreeDisagreeComponent)


export class UpstreamLinksComponent extends React.Component {
  state = {expanded: false}

  render() {
    const {expanded} = this.state
    if (this.props.loading) {
      return ""
    } else if (!this.props.point) {
      return ""
    } else {
      const {point: {supportingBacklinks, counterBacklinks}} = this.props
      const backlinks = [...supportingBacklinks, ...counterBacklinks]
      if (backlinks.length < 1) {
        return ""
      } else if (backlinks.length === 1) {
        const {url, title} = backlinks[0]
        const expandedUrlExtension = this.props.expansion.getFullUrlExpandedExtension(url)
        return <div className="upstreamLinks dropdown">
          <Link to={"/claim/" + url + expandedUrlExtension}>
            <div className="arrowBacklinks">
              <div className="arrowHeadUp"/>
              <div className="arrowStemEvidence"/>
            </div>
            <span className="showParentLabel">Show Parent Point—</span>
            <div className="parentClaimTitle">{title}</div>
          </Link>
        </div>
      } else {
        return (
          <div className="upstreamLinks dropdown">
            <a onClick={(e) => e.stopPropagation() || this.setState({expanded: !expanded})}
               className="dropdown-toggle" data-toggle="dropdown">
              <div className="arrowBacklinks">
                <div className="arrowHeadUp"/>
                <div className="arrowStemEvidence"/>
              </div>
              <span className="number">{backlinks.length}</span> Parent {pluralize('Claim', backlinks.length)}
              <span className="menuCaret fas fa-caret-down"></span>
            </a>
            {expanded && (
              <OutsideClickDetector component="ul"
                                    action={() => this.setState({expanded: false})}
                                    className="dropdown-menu" aria-labelledby="dropdownMenu">
                <div className="dropdown-caret"><div className="caret-outer"/><div className="caret-inner"/></div>
                {backlinks.map(
                  ({url, title}) => {
                    const expandedUrlExtension = this.props.expansion.getFullUrlExpandedExtension(url)
                    return <li className="dropdown-menu-items" key={url}>
                      <Link onClick={(e) => e.stopPropagation()}
                            to={"/claim/" + url + expandedUrlExtension}>{title}</Link>
                    </li>
                  }
                )}
              </OutsideClickDetector>
            )}
          </div>
        )
      }
    }
  }
}

export const UpstreamLinks = compose(
    withExpandedIndex,
    graphql(schema.Backlinks, {
      props: ({ownProps, data: {loading, point}}) => ({point, loading, ...ownProps})
    })
)(UpstreamLinksComponent)


export class PointCardComponent extends React.Component {
  static defaultProps = {
    updateLastVisit: () => true,
  }

  state = {
    relevanceRater: false,
    displayImageBig: false, // is declaring this up here needed/best practice? -JF
    editingClaimImage: this.props.editingClaimImage,
    editingClaimTags: this.props.editingClaimTags,
    editingClaimText: this.props.editingClaimText,
    editingClaimSources: this.props.editingClaimSources,
    showMoveDialog: this.props.showMoveDialog ?? false,
  }

  // static getDerivedStateFromProps(nextProps) {
  //   return {
  //     editingClaimImage: nextProps.editingClaimImage,
  //     editingClaimTags: nextProps.editingClaimTags,
  //     editingClaimText: nextProps.editingClaimText,
  //     editingClaimSources: nextProps.editingClaimSources,
  //   }
  // }

  handleClickNoProp = (e) => {
    e.stopPropagation();
    this.setState({showMoreMenu: false});
  }

  handleClickEditClaimText = (e) => {
    e.stopPropagation();
    this.setState({showMoreMenu: false});
    if (this.props.currentUser){
      this.setState({editingClaimText: true})
    } else {
      console.log('Logon Required')
      this.props.showLoginDialog && this.props.showLoginDialog()
      gtmEvent('RequiredLogin', 'Require login add source')
      fbq('trackCustom', 'RequiredLogin')
    }
  }

  handleClickEditClaimSources = (e) => {
    e.stopPropagation();
    this.setState({showMoreMenu: false});
    if (this.props.currentUser){
      this.setState({editingClaimSources: true})
    } else {
      console.log('Logon Required')
      this.props.showLoginDialog && this.props.showLoginDialog()
    }
  }

  handleClickEditClaimTags = (e) => {
    e.stopPropagation();
    this.setState({showMoreMenu: false});
    this.setState({editingClaimTags: true})
  }

  handleClickEditClaimImage = (e) => {
    e.stopPropagation();
    this.setState({showMoreMenu: false});
    if (this.props.currentUser){
      this.setState({editingClaimImage: true})
    } else {
      console.log('Logon Required')
      this.props.showLoginDialog && this.props.showLoginDialog()
      gtmEvent('RequiredLogin', 'Require login add image')
      fbq('trackCustom', 'RequiredLogin')
    }
  }

  get expansion() {
    return this.props.expansion
  }

  showComments = () => this.expansion && this.expansion.expand(this.point, this.commentPrefix())

  hideComments = () => this.expansion && this.expansion.collapse(this.point, this.commentPrefix())

  handleClickEditComments = (e) => {
    e.stopPropagation();
    if (this.expansion.isExpanded(this.point, this.commentPrefix())) {
      this.hideComments()
      gtmEvent('CommentView', 'Hide Comments', this.point.url);
    }
    else {
      this.showComments()
      gtmEvent('CommentView', 'Show Comments', this.point.url);
    }
  }

  handleCancelEditClaimText = (e) => {
    e.stopPropagation()
    this.setState({editingClaimText: false})
  }
  handleCancelEditClaimSources = (e) => {
    e.stopPropagation()
    this.setState({editingClaimSources: false})
  }
  handleCancelEditClaimTags = (e) => {
    e.stopPropagation()
    this.setState({editingClaimTags: false})
  }
  handleCloseEditClaimImage = (e) => {
    e && e.stopPropagation()
    this.setState({editingClaimImage: false})
  }
  handleCloseComments = (e) => {
    e && e.stopPropagation()
    this.hideComments()
    gtmEvent('CommentView', 'Hide Comments', this.point.url);
  }

  editingSomething = () => {
    return (this.state.editingClaimText || this.state.editingClaimSources || this.state.editingClaimTags || this.state.editingClaimImage || this.state.editingComments)
  }

  get point() {
    return (this.props.data && this.props.data.point) ? this.props.data.point : this.props.point
  }

  isQuestion = () => {
    const point = this.point
    // TODO: Gene: How do we want to share PointType constants
    return point && point.pointType === PointTypeQuestion
  }

  get evidenceType() {
    if (this.props.link){
      switch (this.props.link.type) {
        case "supporting":
          return EvidenceType.SUPPORT
        case "counter":
          return EvidenceType.COUNTER
        default:
          return null
      }
    } else {
      return null
    }
  }

  get relevance() {
    return this.props.link && this.props.link.relevance
  }

  get sortScore() {
    return this.props.link && this.props.link.sortScore
  }

  get relevanceVoteCount() {
    return this.props.link && this.props.link.voteCount
  }

  // TODO: the "root" case doesn't seem to be working -JF
  evidenceTypeClass = () => {
    switch (this.evidenceType){
      case EvidenceType.ROOT:
        return "root";
      case EvidenceType.SUPPORT:
        return "support";
      case EvidenceType.COUNTER:
        return "counter";
      default:
        return "";
    }
  }

  // TODO: perhaps this function should  actively check whether the "evidence" is an Answer to a question, rather than assume that as the default;
  //   previously, the default was simply "Evidence"
  evidenceTypeLabel = () => {
    let label = `Answer`;
    if (this.hasParent()) {
      if (this.evidenceType === EvidenceType.SUPPORT)
        label = "For";
      else if (this.evidenceType === EvidenceType.COUNTER)
        label = "Against";
      return <span className="evidenceTypeLabel">{label}</span>
    }
  }

  teamClass = () => {
    if (this.supportsOriginalClaim){
      return "blackTeam"
    } else {
      return "redTeam"
    }
  }

  handleRelClick = (e) => {
    //console.log("toggle relevance ui");
    e.stopPropagation();
    if (this.state.relevanceRater) {
      this.setState({ relevanceRater: false })
    } else {
      this.setState({ relevanceRater: true })
    }
  }

  showRelevanceRater = (e) => {
    e.stopPropagation();
    this.setState({ relevanceRater: true })
  }

  hideRelevanceRater = (e) => {
    e.stopPropagation();
    this.setState({ relevanceRater: false })
  }

  relevanceRaterUI = () => {
    let classesConnectingLineRelRater = `connectingLine connectingLineRelevanceRater ${this.evidenceTypeClass()} ${this.teamClass()}`  
    let classesRelevanceCtrlArea = `relevanceCtrlArea ${this.evidenceTypeClass()} ${this.teamClass()}`  
    if (this.props.parentPoint) {
      return <span>
        { this.state.relevanceRater ?
            <div className={classesRelevanceCtrlArea}>
              <div className={classesConnectingLineRelRater}/>
              <RelevanceRater point={this.point} parentPoint={this.props.parentPoint} link={this.props.link} onClose={this.hideRelevanceRater}/>
            </div> :
            <span className="noRelevanceCtrl"/>
        }
      </span>
    }
  }
  
  voteIcon = () => {  
    let classesRelevanceVoteIcon = `iconVote ${this.evidenceTypeClass()} ${this.teamClass()}`  
     return <svg className={classesRelevanceVoteIcon}  id="v01_B" data-name="v01 B" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <polyline className="iconVoteBallot" fill="none" strokeMiterlimit="10" strokeWidth="2.5" points="10.09 25.42 3.66 16.08 19.8 4.73 28.07 16.48 15.87 25.42"/>
        <polygon className="iconVoteSlot" fill="none" points="2.51 23.98 29.49 23.98 31.61 27.27 0.39 27.27 2.51 23.98"/>
      </svg>
}

  // TODO: make animation only occur on the claim being manipulated (rather than on all relevance) -JF
  // TODO: this might want to be its own component
  //  code with animation:  <AnimateOnChange baseClassName="relevanceDisplay" animationClassName="Score--bounce" animate={this.relevance != -1}>{this.relevance}%</AnimateOnChange>
  //  let classesConnectingLine = `${this.evidenceTypeClass()} ${this.teamClass()} connectingLine connectingLineRelevanceLink`
  linkArea = () => {
    if (this.props.parentPoint) {
      let classesLinkArea = `linkArea ${this.state.relevanceRater && "relRaterExpanded"} ${this.evidenceTypeClass()} ${this.teamClass()}`
      let classesLinkAreaBase = `linkAreaBase ${this.state.relevanceRater && "relRaterExpanded"} ${this.evidenceTypeClass()} ${this.teamClass()}`
      let classesRelevanceLink = `relevanceLink ${this.evidenceTypeClass()} ${this.teamClass()}`
      let classesConnectingLine = `connectingLine connectingLineRelevanceLink ${this.evidenceTypeClass()} ${this.teamClass()}`     
      let classesConnectingLineAbove = `${classesConnectingLine} aboveRelLink`
      let classesRelevanceDisplay = `relevanceDisplay ${this.evidenceTypeClass()} ${this.teamClass()}`
      return <div className={classesLinkArea}>
        <div className={classesConnectingLineAbove}/>
        <a className={classesRelevanceLink} onClick={this.handleRelClick}>
          <div className={classesConnectingLine}/>
          <span className={classesRelevanceDisplay}>
            <Hover delay="1" onHover={<HoverTip title="Rate&nbsp;this&nbsp;Link" />}>
              {this.evidenceTypeLabel()}
              <span className="bottomActionDot">·</span>
              <span className="number">{this.relevance}</span>
              <span className="perctSignSmallRelLink">%</span>
              <span className="relevanceDisplayCopy">Relevant</span>
              {this.voteIcon()}
            </Hover>
            <Hover delay="1" onHover={<HoverTip title="Comment On Relevance" />}>
              <CommentOnLinkIcon count={this.props.link.numComments} />
            </Hover>
          </span>
        </a>
        {this.relevanceRaterUI()}
        <div className={classesLinkAreaBase} onClick={this.handleRelClick} >
          <LinkedClaimArrow evidenceType={this.evidenceTypeClass()} relRaterExpanded={this.state.relevanceRater} />
        </div>
      </div>
    } else {
      return null
    }
  }

  expand = () => {
    if (!this.props.expansionLoading) {
      this.props.onExpand()
      gtmEvent('Main Page', 'Expand Point', this.point.url);
      gtmCustom('Test51')
      fbq('track', 'ViewContent');
      fbq('trackCustom', 'ExpandEvidence', {
        urlParent: this.point.url,
      });
    }
  }

  collapse = () => {
    if (!this.props.expansionLoading)
      this.props.onCollapse()
  }

  handleSeeEvidence = () => {
    this.expand();
  }

  handleHideEvidence = () => {
    this.collapse();
  }

  // When user clicks on the pointTitle or the stackGroup
  // Disabled when the claim is being edited
  handleToggleEvidence = () => {
    if (!this.editingSomething()) {
      if (this.expanded()) {
        this.collapse()
      } else {
        this.expand()
      }
    }
  }

  expanded = () => {
    return this.props.expanded && !this.props.expansionLoading;
  }

  handleImageClick = (e) => {
    e.stopPropagation();
    if (this.state.displayImageBig) {
      this.setState({ displayImageBig: false })
    } else {
      this.setState({ displayImageBig: true })
    }
  }
  // TODO old django pointBox.html also checks if point.imageURL.strip exists - is that necessary here? -JF
  hasImage = () => {
    //console.log("hasImage() : " + this.point.imageURL  )
    return this.point.imageURL
  }
  displayImage = () => {
    return (this.hasImage() && !this.state.editingClaimText && !this.state.editingClaimSources && !this.state.editingClaimTags)
  }
  image = () => {
    if (this.displayImage()) {
      let classesImageContainer = `imageContainer hideBorderBottom ${this.state.displayImageBig && !this.state.editingClaimImage && "imageContainerBig"}`
      let classesImage = `claimImage ${this.state.editingClaimImage && "imageFaded"}`
      let classesImageCaption = `${this.state.displayImageBig ? "imageCaption" : "hidden"}`
      return  <div className={classesImageContainer}>
          <a onClick={this.handleImageClick}><img className={classesImage} src={this.point.fullPointImage} alt={this.point.imageDescription}/></a>
          <span className={classesImageCaption}>{this.point.imageDescription}</span>
        </div>
    }
  }

  textContentWidth = () => {
    if (this.displayImage() && (!this.state.displayImageBig || this.state.editingClaimImage) ) {
      return "contentWithImage"
    } else {
      return "contentNoImage"
    }
  }

  // TODO: this is declared as a local function in two different components - should it be a global function or a const? -JF
  numSupportingPlusCounter = () => {
    return ( this.point.numSupporting + this.point.numCounter + this.point.numAnswers)
  }
  hasSupportingEvidence = () => (
    this.point.supportingPoints && this.point.supportingPoints.edges.length > 0
  )
  hasCounterEvidence = () => (
    this.point.counterPoints && this.point.counterPoints.edges.length > 0
  )
  hasAnswerEvidence = () => (
    this.point.answerPoints && this.point.answerPoints.edges.length > 0
  )
  isSingleEvidenceTypeChildren = () => {
    if (this.props.isSingleEvidenceChild === false) return false;
    return (this.hasAnswerEvidence() && !this.hasSupportingEvidence() && !this.hasCounterEvidence())
        || (!this.hasAnswerEvidence() && !(this.hasSupportingEvidence() && this.hasCounterEvidence()));
  }
  isChildrenWideCard = () => {
    if (this.props.isSingleEvidenceChild === false) return false;
    return this.isSingleEvidenceTypeChildren()
  }
  isWideCardClaim = () => {
    if (this.props.isSingleEvidenceChild === false) return false;
    if (!this.hasParent()) return true;
    if (this.props.isSingleEvidenceChild) return true;
    return false;
  }


  // Removing for now, -JF
  /*
  isChildExpanded = (child) =>
    this.expansion && this.expansion.isExpanded(child, this.childPrefix())

  childrenExpanded = (edgeName) => {
    const point = this.props.point
    return point && point[edgeName] && !!point[edgeName].edges.find(edge => this.isChildExpanded(edge.node))
  }

  supportingChildrenExpanded = (point) => this.childrenExpanded('supportingPoints')

  counterChildrenExpanded = (point) => this.childrenExpanded('counterPoints')

  relevantChildrenExpanded = (point) => this.childrenExpanded('relevantPoints')
  */


  currentUserIsLoggedIn = () => (
    this.props.currentUser
  )

  currentUserIsCreator = () => {
    //NOTE: in Sept. 2018 we set all WhysaurusUser.url values to lowercase in the DB, but did not modify existing Point.creatorURL or Comment.userUrl values:
    const creatorURL = this.props.point && this.props.point.creatorURL ? this.props.point.creatorURL.toLowerCase() : (this.props.point.authorURL ? this.props.point.authorURL.toLowerCase() : "")
    return this.props.currentUser && creatorURL === this.props.currentUser.url
  }

  numUsersContributedIsNonzero = () => this.props.point && (this.props.point.numUsersContributed > 0)

  currentUserIsAdmin = () => (
    this.props.currentUser && this.props.currentUser.admin
  )

  currentUserIsPowerUser = () => this.props.currentUser && this.props.currentUser.powerUser

  currentUserCanUnlink = () => this.hasParent() && (this.currentUserIsAdmin() ||
    (this.currentUserIsCreator() && !this.numUsersContributedIsNonzero()))

  currentUserCanDelete = () => this.currentUserIsAdmin() || (this.currentUserIsCreator() && !this.numUsersContributedIsNonzero())

  currentUserCanUnlinkOrDelete = () => this.currentUserCanUnlink() || this.currentUserCanDelete()
  
  currentUserCanSetTop = () => this.currentUserIsAdmin() && this.props.point.isTop === false

  currentUserCanUnsetTop = () => this.currentUserIsAdmin() && this.props.point.isTop === true

  currentUserCanSetStale = () => this.currentUserIsAdmin() && !this.props.point.stale

  currentUserCanUnsetStale = () => this.currentUserIsAdmin() && this.props.point.stale

  currentUserCanGenerateEvidence = () => this.currentUserIsAdmin() || this.currentUserIsPowerUser()

  currentUserCanSeeEditClaim = () => this.currentUserIsCreator() || this.currentUserIsAdmin()

  currentUserCanEditTags = () => this.currentUserIsAdmin() || (this.props.currentUser && this.props.currentUser.canAddTags)

  hasParent = () => (this.props.parentPoint)

  hasBadge = () => (this.props.badge)

  evidence = () => {
    if (this.expanded() ) {
      // If this is the first level down, remove an indent bc the Relevance widget effectively creates one when it appears for the first time -JF
      let classesEvidenceBlock = `evidenceBlock ${!this.props.parentPoint && "removeOneIndent"} ${this.isQuestion() && "qAnswers"}`
      //console.log("pointCard : evidence() ")
      if (this.isQuestion()) {
        return <div className={classesEvidenceBlock}>
          {this.point.answerPoints && this.answerPoints()}
        </div>
      } else if (this.numSupportingPlusCounter() === 0) {
        return <div className={classesEvidenceBlock + " evidenceBlockEmpty"}>
          <AddEvidence point={this.point} type={"DUAL"} />
        </div>
      } else {
        return <div className={classesEvidenceBlock}>
         {this.props.parentPoint && !this.props.lastInList && <div className="connectingLine connectingLineExpansionIndicator"/>}
         <MediaQuery minWidth={config.singleColumnThreshold}>
          {this.hasSupportingEvidence() && this.supportingPoints()}
          {this.hasCounterEvidence() && this.counterPoints()}
         </MediaQuery>
         <MediaQuery maxWidth={config.singleColumnThreshold}>
          {this.point.relevantPoints && this.relevantPoints()}
         </MediaQuery>
          { !this.hasParent() && <div className="moreClaimsDivision"/> }
        </div>
      }
    }
  }

  prefix = () => this.props.prefix || ''

  childPrefix = () => this.prefix() + this.props.point.url

  commentPrefix = () => this.prefix() + 'comments-'

  supportingPoints = () => {
    if (this.expanded() && this.point.supportingPoints) {
      return <div className="evidenceBlockSupport evidenceBlockFirstColAlignment">
        {this.point.counterPoints.edges.length > 0 && <div className="connectingLineHoriz connectCounter "/> }
        <div className="evidenceList">
          {this.point.counterPoints.edges.length < 1 ? <AddEvidence point={this.point} type={"DUAL"}/> : <AddEvidence point={this.point} type={"SUPPORT"}/>}
          <PointList edges={this.point.supportingPoints.edges} parentPoint={this.point} relevanceThreshold={config.relevanceThreshold} prefix={this.childPrefix()} parentSupportsOriginalClaim={this.supportsOriginalClaim} isSingleEvidenceChild={this.isChildrenWideCard()} />
        </div>
      </div>
    }
  }

  counterPoints = () => {
    if (this.expanded() && this.point.counterPoints){
      let evidenceBlockCounterClasses = `evidenceBlockCounter ${this.point.supportingPoints.edges.length < 1 ? "evidenceBlockFirstColAlignment" : ""}`
      return <div className={evidenceBlockCounterClasses}>
        <div className="evidenceList">
          {this.point.supportingPoints.edges.length < 1 ? <AddEvidence point={this.point} type={"DUAL"}/> : <AddEvidence point={this.point} type={"COUNTER"}/>}
          <PointList edges={this.point.counterPoints.edges} parentPoint={this.point} relevanceThreshold={config.relevanceThreshold} prefix={this.childPrefix()}  parentSupportsOriginalClaim={this.supportsOriginalClaim} isSingleEvidenceChild={this.isChildrenWideCard()} />
        </div>
      </div>
    }
  }

  answerPoints = () => {
    if (this.expanded() && this.point.answerPoints){
      let evidenceBlockAnswerClasses = `${this.point.answerPoints.edges.length < 1 ? "evidenceBlockFirstColAlignment" : ""}`
      return <div className={evidenceBlockAnswerClasses}>
        <div className="evidenceList">
          <AddEvidence point={this.point} type={"ANSWER"} />
          <PointList edges={this.point.answerPoints.edges} parentPoint={this.point} relevanceThreshold={config.relevanceThreshold} prefix={this.childPrefix()}  parentSupportsOriginalClaim={this.supportsOriginalClaim} isSingleEvidenceChild={this.isChildrenWideCard()} />
        </div>
      </div>
    }
  }

  //JF: Prob killing this, but stashing here till I'm sure:
  relevantPointsHeading = () => {
    let evidenceBlockLabel = <span>Evidence For & <span className="counterHeading">Against</span></span>
    if (this.point.supportingPoints.edges.length > 0 && this.point.counterPoints.edges.length < 1) {
      evidenceBlockLabel = "Evidence For"
    } else if (this.point.supportingPoints.edges.length < 1 && this.point.counterPoints.edges.length > 0) {
      evidenceBlockLabel = <span className="counterHeading">Evidence Against</span>
    }
    return <div className={`heading evidenceBlockHeading ${this.teamClass()}`}>{evidenceBlockLabel}</div>
  }

  //JF: if users ask for it, reintroduce <span className="sortBy">Sorted by Relevance</span>
  //JF: Prob killing this, but stashing here till I'm sure:
  //    {this.point.relevantPoints.edges.length > 0 && this.relevantPointsHeading()}
  relevantPoints = () => {
    if (this.expanded() && this.point.relevantPoints){
      return <div className="evidenceBlockBoth evidenceBlockFirstColAlignment">
        <div className="evidenceList">
          <AddEvidence point={this.point} type={"DUAL"}/>
          <PointList edges={this.point.relevantPoints.edges} parentPoint={this.point} relevanceThreshold={config.relevanceThreshold} prefix={this.childPrefix()} parentSupportsOriginalClaim={this.supportsOriginalClaim} isSingleEvidenceChild={this.isChildrenWideCard()} />
        </div>
      </div>
    }
  }


  // TODO: this is defined in the model point.py, so we could pass it up through GraphQL if that would be faster
  linksRatio = () => {
    let sup = this.point.numSupporting
    let cou = this.point.numCounter
    if (sup === 0 && cou === 0)
      return 50
    else if (cou === 0)
      return 100
    else if (sup === 0)
      return 0
    else {
      let ratio = sup/(sup + cou)
      //console.log("linksRatio : " + ratio)
      return ratio
    }
  }

  // TODO: this could be removed by moving its logic into render() and its DOM into <Sources>
  sources = () => {
    if (this.point.sources){
      return <div className="row-fluid">
          <Sources point={this.point}/>
        </div>
    }
  }

  tags = () => {
    if (this.point.tags){
      return <div className="row-fluid">
          <Tags point={this.point}/>
        </div>
    }
  }

  handleClickDelete = (e) => {
    e.stopPropagation();
    this.setState({showMoreMenu: false});
    let r = window.confirm("Are you sure you want to DELETE this point '" + this.point.title + "' ?")
    if (r === true) {
      this.setState({deleting: true})
      this.props.delete(this.point.url, this.props.parentPoint && this.props.parentPoint.url).then(
        (success) => {
          console.log("Delete succeeded, affected queries should reload automatically.")
          console.log(success)
          this.props.onDelete && this.props.onDelete(success);
        },
        (error) => {
          console.log("Delete failed: " + error)
          this.setState({deleting: false})
        })
    }
  }

  handleClickReportClaim = (e) => {
    e.stopPropagation();
    this.setState({showMoreMenu: false});
    let r = window.confirm(`Are you sure you want to REPORT this point '${this.point.title}'? Moderators will be notified that this point might be spam, trolling or other bad stuff.`)
    if (r === true) {
      this.props.reportpoint(this.point.id).then(
        (success) => {
          console.log("report point success")
          this.props.alert.show(`Point has been reported — '${this.point.title}'`)
        },
        (error) => {
          console.log("report point error: " + error)
        })
    }
  }

  handleClickMakeFeatured = (e) => {
    e.stopPropagation();
    this.setState({showMoreMenu: false});
    this.props.makeFeatured(this.point.id)
  }

  handleClickSetEditorsPick = (e) => {
    e.stopPropagation();
    this.setState({showMoreMenu: false});
    this.setState({showEditorsPick: true})
  }

  handleClickSetStale = (e) => {
    e.stopPropagation();
    this.setState({showMoreMenu: false});
    this.props.setStale(this.point.id, this.point.url, true, null)
  }

  handleClickUnsetStale = (e) => {
    e.stopPropagation();
    this.setState({showMoreMenu: false});
    this.props.setStale(this.point.id, this.point.url, false, null)
  }

  handleClickSetTop = (e) => {
    e.stopPropagation();
    this.setState({showMoreMenu: false});
    this.props.setTop(this.point.id, true)
  }

  handleClickUnsetTop = (e) => {
    e.stopPropagation();
    this.setState({showMoreMenu: false});
    this.props.setTop(this.point.id, false)
  }

  // handleClickGenerateEvidence = (e) => {
  //   e.stopPropagation();
  //   this.setState({showMoreMenu: false});
  //   console.log("Generating evidence: " + this.point.title)
  //   this.props.generateEvidence(this.point.id, this.point.title).then(
  //     (resp) => {
  //       console.log("GenerateEvidence Response: ")
  //       console.log(resp)
  //     },
  //     (error) => {
  //       console.log("GenerateEvidence Error: " + error)
  //     }
  //   )
  // }

  handleClickUnlink = (e) => {
    e.stopPropagation();
    this.setState({showMoreMenu: false});
    let r = window.confirm("Are you sure you want to UNLINK this point '" + this.point.title + "' from its Parent?")
    if (r === true) {
      this.setState({unlinking: true})
      this.props.unlink(this.props.parentPoint.url, this.point.url, this.props.link.type).then(
        (success) => {
          console.log("unlink success")
          this.props.alert.show('Point unlinked From Parent — "' + this.point.title + '"');
        },
        (error) => {
          console.log("unlink error: " + error)
          this.setState({unlinking: false})
        })
    }
  }

  handleClickMove = (e) => {
    this.setState({showMoreMenu: false});
    this.setState({showMoveDialog: true});
    if (e) e.stopPropagation();
  }

  // TODO: Make SupportingCount work and move out of admin
  // TODO: Make Edit only work if user is author or admin
  /*      Code to check if current user is the point Author
          NOTE: in Sept. 2018 we set all WhysaurusUser.url values to lowercase in the DB, but did not modify existing Point.creatorURL or Comment.userUrl values:
          {this.props.data.currentUser &&
          this.props.data.currentUser.url == this.point.authorURL.toLowerCase() &&
          <a onClick={this.handleClickEditClaimText} className="editLink" >Edit</a>}
  */
  moreMenu = () => {
    let moreMenuSourcesLabel = `${this.point.sources ? "Edit Sources" : "Add Sources"}`
    let moreMenuTagsLabel = `${this.point.tags ? "Edit Tags" : "Add Tags"}`
    let moreMenuImageLabel = `${this.hasImage() ? "Edit Image" : "Add Image"}`
    let moreMenuEditLabel = `${this.isQuestion() ? "Edit Question" : "Edit Point"}`
    const {showMoreMenu} = this.state;
    return ( 
      <span className="dropdown pull-right">
          <button onClick={(e) =>  e.stopPropagation() || this.setState({showMoreMenu: !showMoreMenu})}
                className="btn btn-link cardBottomActionBlock iconBlock moreMenuBlock dropdown-toggle"  data-toggle="dropdown">
            <span className="cardBottomAction moreMenuLink">
              <FontAwesomeIcon name="ellipsis-h" />
            </span>
          </button>
        {showMoreMenu && (
            <ShareArea point={this.point}>
              {({copyPointUrl, generateFullLinkUrl, postOnFacebook, shareOnTwitter, generateMailToUrl}) => (
                <OutsideClickDetector component='ul'
                                      action={() => this.setState({showMoreMenu: false})}
                                      className="moreMenu dropdown-menu dropdown-menu-with-caret"
                                      role="menu" aria-labelledby="dropdownMenu">

                  <div className="dropdown-caret"><div className="caret-outer"/><div className="caret-inner"/></div>
                  <li className="inlineIcons">
                    <a className="menuShareLink" target="_blank"
                       onClick={(e) => {e.stopPropagation(); this.setState({showMoreMenu: false}); }}
                       href={generateMailToUrl()} rel="noreferrer">
                      <FontAwesomeIcon name="envelope" type="far" />
                    </a>
                    <a className="menuShareLink" onClick={(e)=>{postOnFacebook(e);this.setState({showMoreMenu: false});}}>
                      <FontAwesomeIcon name="facebook-square" type="fab" />
                    </a>
                    <a className="menuShareLink" onClick={(e)=>{shareOnTwitter(e);this.setState({showMoreMenu: false});}}>
                      <FontAwesomeIcon name="twitter" type="fab" />
                    </a>
                  </li>
                  <li className="divider"/>
                  <li><a onClick={(e)=>{this.setState({showMoreMenu: false});copyPointUrl(e);}} href={generateFullLinkUrl()}>
                    <span className="iconWithStat">
                      <FontAwesomeIcon name="link" />
                    </span>Copy Link</a>
                  </li>
                  <li><a onClick={this.handleClickNoProp} target="_blank" href={"/claim/" + this.point.url} rel="noreferrer"><span className="iconWithStat"><FontAwesomeIcon name="external-link-alt" /></span>Open in new tab</a></li>
                  <li className="divider"/>
                  { this.currentUserCanSeeEditClaim()   && <li className="admin"><a onClick={this.handleClickEditClaimText} className="" ><span className="iconWithStat"><FontAwesomeIcon name="pencil-alt" /></span>{moreMenuEditLabel}</a></li>}
                  <li><a onClick={this.handleClickEditClaimImage} className="" ><span className="iconWithStat"><FontAwesomeIcon name="image" type="far" /></span>{moreMenuImageLabel}</a></li>
                  { this.currentUserCanEditTags()       && <li className="admin"><a onClick={this.handleClickEditClaimTags}><span className="iconWithStat"><FontAwesomeIcon name="hashtag" /></span>{moreMenuTagsLabel}</a></li> }
                  <li><a onClick={this.handleClickEditClaimSources} className="" ><span className="iconWithStat"><FontAwesomeIcon name="book-open" /></span>{moreMenuSourcesLabel}</a></li>
                  { this.currentUserCanUnlinkOrDelete() &&  <li className="divider"/> }
                  { this.currentUserCanUnlink()         &&  <li className="admin"><a onClick={this.handleClickMove}><span className="iconWithStat"><FontAwesomeIcon name="arrows-alt" type="fa" /></span>How to Move</a></li> }
                  { this.currentUserCanUnlink()         && <li className="admin"><a onClick={this.handleClickUnlink}><span className="iconWithStat"><FontAwesomeIcon name="unlink" type="fa" /></span>Unlink</a></li>  }
                  { this.currentUserCanDelete()         && <li className="admin"><a onClick={this.handleClickDelete}><span className="iconWithStat"><FontAwesomeIcon name="trash-alt" type="far" /></span>Delete</a></li>  }
                  <li className="divider"/>
                  { this.currentUserCanGenerateEvidence() && <li className="admin"><a onClick={this.handleClickNoProp} target="_blank" href={"/generate/" + this.point.url} rel="noreferrer"><span className="iconWithStat"><FontAwesomeIcon name="arrow-down" /></span>Generate Evidence</a></li> }
                  <li><a onClick={this.handleClickNoProp} target="_blank" href={"/history/" + this.point.url} rel="noreferrer"><span className="iconWithStat"><FontAwesomeIcon name="history" /></span>History</a></li>
                  <li><a onClick={this.handleClickReportClaim}><span className="iconWithStat"><FontAwesomeIcon name="flag" /></span>Report</a></li>
                  { this.currentUserIsAdmin()           && <li className="admin"><a onClick={this.handleClickMakeFeatured}><span className="iconWithStat"><FontAwesomeIcon name="star" /></span>Make Featured</a></li>  }
                  { this.currentUserIsAdmin()           && <li className="admin"><a onClick={this.handleClickSetEditorsPick}><span className="iconWithStat"><FontAwesomeIcon name="ribbon" /></span>Set Editor's Pick</a></li>  }
                  { this.currentUserCanSetStale()       && <li className="admin"><a onClick={this.handleClickSetStale}><span className="iconWithStat"><FontAwesomeIcon name="bread-slice" /></span>Set Stale</a></li>  }
                  { this.currentUserCanUnsetStale()     && <li className="admin"><a onClick={this.handleClickUnsetStale}><span className="iconWithStat"><FontAwesomeIcon name="bread-slice" /></span>Unset Stale</a></li>  }
                  { this.currentUserCanSetTop()         && <li className="admin"><a onClick={this.handleClickSetTop}><span className="iconWithStat"><FontAwesomeIcon name="arrow-up" /></span>Set Top</a></li> }
                  { this.currentUserCanUnsetTop()       && <li className="admin"><a onClick={this.handleClickUnsetTop}><span className="iconWithStat"><FontAwesomeIcon name="arrow-down" /></span>Unset Top</a></li> }
                  { this.currentUserIsAdmin()           && <li className="admin"><SupportingCount point={this.point} /></li> }
                </OutsideClickDetector>
              )}
            </ShareArea>
        )}
      </span>
    )
  }

  pointTextComponent = () => {
    const point = this.point;
    if (this.state.editingClaimText){
      return <EditPoint point={point} onCancel={this.handleCancelEditClaimText}/>
    } else {
      return <Point point={point} link={this.props.link} onClick={this.handleToggleEvidence} wideCard={this.isWideCardClaim()} isPrimaryClaimView={this.props.isPrimaryClaimView} />
    }
  }

  relevanceBottomLinkLabel = () => {
    return <span>
      <MediaQuery maxWidth={config.extraextraSmallScreenThreshold - 1 }>
        Relv
      </MediaQuery>
      <MediaQuery minWidth={config.extraextraSmallScreenThreshold}>
        Relevance
      </MediaQuery>
      </span>
  }

  preloadPoint = () => {
    console.log("preloading data for " + this.point.url)
    this.props.client && this.props.client.query({
      query: schema.GetPoint,
      variables: {url: this.point.url}
    })
  }

  // TODO: Is there a crafty way to do this with concatenation? -JF
  stackMarginBottomClass = () => {
    if (this.numSupportingPlusCounter() === 0)
      return "stackMarginBottom0"
    else if (this.numSupportingPlusCounter() === 1)
      return "stackMarginBottom1"
    else if (this.numSupportingPlusCounter() === 2)
      return "stackMarginBottom2"
    else return "stackMarginBottom3"
  }

  editEditorsPick = () => <Modal isOpen={this.state.showEditorsPick}>
      <EditEditorsPick point={this.point}
                       close={(e) => this.setState({showEditorsPick: false})}/>
  </Modal>

  moveDialog = () => (
    <Modal isOpen={this.state.showMoveDialog} style={{content: {bottom: 'auto'}}}>
      <div className="modal-header"><h1>How To Move a Point</h1></div>
      <div className="modal-body text-content noMarginTop">
        <p>For now, here's how:</p>
        <ol className="dialogBullets">
          <li>Unlink the point you want to move</li>
          <li>Click "Add Evidence" where you want to move it to</li>
          <li>Start typing the unlinked point—autocomplete will suggest the rest</li>
        </ol>
        <p className="noMarginBottom">Whysaurus is a work in progress, built on blood, sweat and tears. This workflow will get smoother soon — and we always welcome your <a href="contact" target="_blank">feedback!</a></p>
      </div>
      <div className="modal-footer">
        <button className="buttonUX2" onClick={() => this.setState({showMoveDialog: false})}>Got It</button>
      </div>
    </Modal>
  )

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({wasVisible: true})
    } else {
      this.state.wasVisible && this.props.updateLastVisit()
      this.setState({wasVisible: false})
    }
  }

  get parentSupportsOriginalClaim() {
    if (typeof this.props.parentSupportsOriginalClaim === 'undefined') {
      return true
    } else {
      return this.props.parentSupportsOriginalClaim
    }
  }

  get supportsOriginalClaim() {
    if (this.evidenceType === EvidenceType.COUNTER) {
      return !this.parentSupportsOriginalClaim
    } else {
      return this.parentSupportsOriginalClaim
    }
  }

  //JF: removing for now, but might change my mind and bring back:
  //    <span className="cardBottomAction bottomActionDot">·</span>
  //JF: removing for now, but might change my mind and bring back:
  //    let classesListedClaimGroup = `... ${!this.props.parentPoint && this.stackMarginBottomClass() }`
  //    let classesListedClaim = `listedClaim ${this.state.relevanceRater ? "relGroupHilite" : "relNotClicked"} ${this.evidenceTypeClass()=="support" ? "linkedClaim" : "rootClaim"}`
  render() {
    const {expansion} = this.props
    const {quickComments} = this.props.point
    if (this.state.deleting) {
      return <div className="progressFeedback"><Spinner />Deleting...</div>
    } else if (this.state.unlinking) {
      return <div className="progressFeedback unlinkProgressFeedback"><Spinner />Unlinking...</div>
    } else if (this.point){
      const point = this.point;
      let classesListedClaimGroup = `listedClaimGroup ${this.props.latestQuickCreate && 'latestQuickCreate'} ${this.stackMarginBottomClass()} ${this.expanded() ? "expanded" : ""} `
      let classesListedClaim = `listedClaim ${this.evidenceTypeClass()==="support" ? "linkedClaim" : "rootClaim"}`
      let classesStackCardGroup = `stackCardGroup ${!this.editingSomething() && "stackCardGroupActive"} ${this.state.relevanceRater ? "relExtraMarginBottom" : "relNotClicked"}`
      let classesStackCard1 = `stackCard ${this.isWideCardClaim() ? "wideCard" : ""} ${this.numSupportingPlusCounter() < 3 ? "stackCardHidden" : ""} ${this.linksRatio() <  1.00 ? "counter" : ""} ${this.expanded() ? "stackCardDealBottom stackCardDealFade" : ""}`
      let classesStackCard2 = `stackCard ${this.isWideCardClaim() ? "wideCard" : ""} ${this.numSupportingPlusCounter() < 2 ? "stackCardHidden" : ""} ${this.linksRatio() <= 0.50 ? "counter" : ""} ${this.expanded() ? "stackCardDealInvertXform stackCardDealFade" : ""}`
      let classesStackCard3 = `stackCard ${this.isWideCardClaim() ? "wideCard" : ""} ${this.numSupportingPlusCounter() < 1 ? "stackCardHidden" : ""} ${this.linksRatio() <= 0.25 ? "counter" : ""} ${this.expanded() ? "stackCardDealInvertXform stackCardDealFade" : ""}`
      let classesPointCard = `point-card ${this.isWideCardClaim() ? "wideCard" : ""} ${!this.editingSomething() && "pointCardActive"} stackCard ${this.expanded() ? "stackCardDealInvertXform" : ""} ${this.teamClass()} ${this.evidenceTypeClass()} ${this.state.editingClaimImage && "hideBorderTop"} ${this.props.expansion.isExpanded(point, this.commentPrefix()) && "hideBorderBottom"}  row-fluid toggleChildVisOnHover`
      let classesRelevanceBottomLink = `${this.props.parentPoint ? "cardBottomAction   relevanceVoteBottomAction" : "hidden" }`

      return <div className={classesListedClaimGroup}>
        {this.state.showEditorsPick && this.editEditorsPick()}
        {this.state.showMoveDialog && this.moveDialog()}
        <div className="listedClaimAndItsEvidence" ref={(input) => { this.cardToScrollTo = input; }}>
          <div className="listedClaimAndShare" onMouseEnter={() => this.props.updateLastVisit()}>
            {/*TODO: Gene: Removed - VisibilitySensor is abandoned and the updateLastVisit isn't strictly necessary here. Replace someday? */}
            {/*<VisibilitySensor intervalCheck={false} scrollCheck={true}*/}
            {/*                  onChange={this.onVisibilityChange}/>*/}
          {this.props.showUpstreamLinks && <UpstreamLinks url={this.point.url}/>}                       
          <div className={classesListedClaim} tabIndex="-1" >                
            <div className="relLinkAndStackCards">
                  {this.linkArea()}
                  { this.state.editingClaimImage && <EditImage point={point} parentPoint={this.props.parentPoint} hasImage={this.hasImage()} wideCard={this.isWideCardClaim()} onClose={this.handleCloseEditClaimImage}/> }
                  <div className={classesStackCardGroup} tabIndex="0" onClick={this.handleToggleEvidence} ref={(input) => { this.cardToFocusOn = input;}}>
                    <div className={classesStackCard1} tabIndex="-1">
                      <div className={classesStackCard2} tabIndex="-1">
                        <div className={classesStackCard3} tabIndex="-1">
                          <div className={classesPointCard} tabIndex="-1">
                          <div className="cardBody">
                            { this.image() }
                            <div className="inlineflexBox">
                              <div className={ this.textContentWidth() }>
                                 <div className="row-fluid">
                                  <div className="pointText">
                                    { this.pointTextComponent() }
                                  </div>
                                 </div>
                                 <div className="claimCardSecondaryInfo">
                                    <Byline point={point} onClickComments={this.handleClickEditComments} />
                                    { !this.state.editingClaimSources ? this.sources() : <EditSources point={point} onCancel={this.handleCancelEditClaimSources}/> }
                                    { this.state.editingClaimTags && <EditTags point={point} onCancel={this.handleCancelEditClaimTags}/> }
                                    <div className="aggregateCommentArea pointCardPaddingH">
                                    {quickComments && <QuickCommentBadgeList aggregateComments={quickComments} pointUrl={this.props.point.url} onClickComments={this.handleClickEditComments} />}
                                    </div>
                                 </div>
                                </div>
                              </div>
                            </div>
                            <div className="cardBottomActionRow" >
                              <EvidenceLink point={point} expanded={this.props.expanded} expansionLoading={this.props.expansionLoading}
                                              onSee={this.handleSeeEvidence} onHide={this.handleHideEvidence}
                                              mouseOverPreload={this.preloadPoint} />
                              { !this.isQuestion() && <AgreeDisagree point={point} parentPoint={this.props.parentPoint}/> }
                              <CommentsOnClaimToggle point={point} onClick={this.handleClickEditComments}/>
                              <EmojiLink point={point} />
                              { this.moreMenu() }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              { this.expanded() && !this.hasParent() && !this.state.relevanceRater &&
                  <MediaQuery minWidth={config.extraextraSmallScreenThreshold}>
                    <ShareIconArea point={point} />
                  </MediaQuery>}
            </div>           
            { this.expansion && this.expansion.isExpanded(point, this.commentPrefix()) && <Comments point={point} wideCard={this.isWideCardClaim()} indent={!!this.props.parentPoint} onCancel={this.handleCloseComments}/> }
            <div className="evidenceRow row-fluid">
              {this.evidence()}
            </div>
          </div>
        </div>
    }
    else if (this.props.data && this.props.data.loading) {
      return <div>Loading...</div>
    }
    else {
      return <div>Something strange happened...</div>
    }
  }
}

export const PointCard = compose(
  withApollo,
  withExpandedIndexForPoint,
  withYsAlerts,
  withAuth,
  graphql(schema.GetPoint, {
    skip: ({expanded}) => !expanded,
    props: ({ownProps, data: { loading, ...rest }}) => ({
      expansionLoading: loading,
      ...rest
    })
  }),
  graphql(schema.DeletePointMutation, {
    props: ({ mutate }) => ({
      delete: (url, parentURL) => {
        const queriesToRefetch = [{query: schema.EditorsPicks}, {query: schema.NewPoints, variables: {limit: config.newPointsPageSize}}]
        if (parentURL) {
          queriesToRefetch.push({query: schema.GetPoint, variables: {url: parentURL}})
        }
        return mutate({variables: {url},
                       refetchQueries: queriesToRefetch})
      }
    })
  }),
  graphql(schema.ReportClaim, {
    props: ({ mutate }) => ({
      reportClaim: (id) => mutate({variables: {id}})
    })
  }),
  graphql(schema.MakeFeatured, {
    props: ({ mutate }) => ({
      makeFeatured: (id) => mutate({variables: {id},
                                   refetchQueries: [{query: schema.HomePage}]})
    })
  }),
  graphql(schema.SetEditorsPick, {
    props: ({ mutate }) => ({
      setEditorsPick: (id) => mutate({variables: {id},
                                     refetchQueries: [{query: schema.EditorsPicks}]})
    })
  }),
  graphql(schema.SetStale, {
    props: ({ mutate }) => ({
      setStale: (id, url, stale, staleDate) => mutate({variables: {id, stale, staleDate},
                                     refetchQueries: [{query: schema.GetPoint, variables: {url: url}}]}) // TODO: Gene: What refetch?
    })
  }),
  graphql(schema.SetTop, {
    props: ({ mutate }) => ({
      setTop: (id, isTop) => mutate({variables: {id, isTop}})
    })
  }),
  graphql(schema.GenerateEvidence, {
    props: ({ mutate }) => ({
      generateEvidence: (pointID, text, modelid) => mutate({variables: {pointID, text, modelid}})
    })
  }),
  graphql(schema.UpdateLastVisit, {
    props: ({ ownProps: { currentUser }, mutate }) => ({
      updateLastVisit: () => currentUser && mutate()
    })
  }),
  graphql(schema.UnlinkPointMutation, {
    props: ({ mutate }) => ({
      unlink: (parentURL, url, linkType) => mutate({variables: {parentURL, url, linkType},
                                                    refetchQueries: [{query: schema.GetPoint, variables: {url: parentURL}}]})
    })
  })
)(PointCardComponent)
