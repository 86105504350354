import React from 'react';

const MentionComponent = ({
  children,
}) => {
  return (
    <span className="editorMention">
      {children}
    </span>
  );
};

export default MentionComponent;