import TagManager from 'react-gtm-module'

// Pass through GTM eventTracking event to replace GA calls
export const gtmEvent = (category, action, label, value) => {
  if (typeof window.disableGtm != 'undefined' && window.disableGtm) {
    console.info('Bypassing GTM')
    return
  }
  //console.info('GTM Event: ' + category + " | " + action + " | " + label + " | " + value);
  window.dataLayer.push({
    'event': 'eventTracking',
    'category': category,
    'action': action,
    'label': label,
    'value': value
  });
}

function registerGtm(userId) {
  let dataLayer = null;
  if (userId) {
    dataLayer = {
      userId: userId
    };
  }
  if (process.env.REACT_APP_GTM_ID) {
    if (process.env.REACT_APP_GTM_AUTH) {
      //console.info("GTM Register: " + process.env.REACT_APP_GTM_ID + " | " + process.env.REACT_APP_GTM_PREVIEW);
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID,
        auth: process.env.REACT_APP_GTM_AUTH,
        preview: process.env.REACT_APP_GTM_PREVIEW,
        dataLayer: dataLayer,
      })
    } else {
      //console.info("GTM Register: " + process.env.REACT_APP_GTM_ID);
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: dataLayer,
      })
    }
  } else {
    // TODO: Gene: Backend warning
    console.warn("GTM_ID environment variable not specified, data will not be sent to GTM")
  }
}

export const gtmSetUserid = (userId) => {
  if (!userId) {
    // TODO: Gene: Error Handling
    console.error('TagManager User Init Called Without Valid Id')
    return;
  }
  registerGtm(userId);
  // console.info("GTM Register: " + userId);
}

// More generic GTM custom event calls
export const gtmCustom = (label) => {
  window.dataLayer.push({'event': label})
}

export default () => {
  registerGtm(null)
}
