import { useMemo, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin'

import Mention from './Mention';
import { ActiveUsersQuery } from '../../../schema';

const useMentions = () => {
  const { data = {} } = useQuery(ActiveUsersQuery);
  const users = useMemo(
    () => (data.activeUsers || []).map(item => ({
      id: item.id,
      name: item.display,
    })),
    [data]
  );

  const [suggestions, setSuggestions] = useState(users);

  const mentionPlugin = useRef(createMentionPlugin({
    mentionComponent: Mention,
    mentionPrefix: '@',
  }));

  const onSearchChange = ({ value }) => {
    setSuggestions(defaultSuggestionsFilter(value, users));
  };

  return {
    mentionPlugin: mentionPlugin.current,
    suggestions,
    onSearchChange,
  };
}

export default useMentions;