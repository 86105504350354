import {graphql} from '@apollo/client/react/hoc';
import * as schema from "../schema";
import {PointList} from "./PointList";
import React from "react";

const NeedsClarificationList = graphql(schema.NeedsClarificationClaims, {
  props: ({data: { loading, needsClarificationClaims}}) => ({
    loading: loading,
    points: needsClarificationClaims,
  })
})(PointList)

export class NeedsClarificationPage extends React.Component {
  render(){
    return <div>
      <div id="mainContainer">
        <h2 id="tagHeader">Needs Clarification</h2>
        <div className="row pointPageContainer infiniteWidth">
          <div id="infiniteOrFiniteWidth" className="">
            <NeedsClarificationList />
          </div>
        </div>
      </div>
    </div>
  }
}