import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import initGTM from './gtm'
//import initGA from './ga'
import initFB from './fb'

initGTM()
//initGA()
initFB()

const el = document.getElementById('root')
ReactModal.setAppElement(el)
ReactDOM.render(<App />, el);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
