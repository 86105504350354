import React from 'react';
import { Link } from 'react-router-dom'

const Mention = ({
  children,
  contentState,
  entityKey,
}) => {
  const entity = contentState.getEntity(entityKey)
  const { mention } = entity.get('data');

  return (
    <Link
      to={`/user/${mention.id}`}
      className="editorMention"
    >
      {children}
    </Link>
  );
};

export default Mention;