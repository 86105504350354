/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";

import { currentBrowser, browsers } from "../utils/browser";

/**
 * tested on chrome, firefox and safari.
 * chrome requires repositioning of tooltip and highlight layers.
 * add [browsers[browserName]] if layers are unpositioned on the browser
 */

const repositionTooltipAndHighlightClassMap = (className) =>
  ({
    [browsers.chrome]: className,
  }[currentBrowser] || "");

const getModifiedSteps = (steps) =>
  steps.map(({ reposition, ...stepProps }) => ({
    ...stepProps,
    ...(reposition
      ? {
          tooltipClass: repositionTooltipAndHighlightClassMap(
            "reposition-tooltipReferenceLayer"
          ),
          highlightClass: repositionTooltipAndHighlightClassMap(
            "reposition-helperLayer"
          ),
        }
      : {}),
  }));

const OnboardingTips = ({ steps, ...rest }) => (
  <Steps steps={getModifiedSteps(steps)} {...rest} />
);

OnboardingTips.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      element: PropTypes.string.isRequired,
      intro: PropTypes.oneOfType([(PropTypes.string, PropTypes.object)])
        .isRequired,
    })
  ),
};

// "The css is to disable the scrolling from the page when onboardingTips is active." —Suraj
const tour = () => {
  const body = document.querySelector("body");
  const illustrations = document.querySelector("#explanationRowHomepage");

  if (illustrations) {
    const start = () => {
      body.style.height = "95vh";
      body.style.overflow = "hidden";
      illustrations.style.display = "none";
    };

    const end = () => {
      body.style.height = "100%";
      body.style.overflow = "visible";
      illustrations.style.display = "block";
    };

    return { start, end };
  }
};

const withOnboardingTips = (Component, options = {}) => (props) => {
  const { disable, ...rest } = options;
  const [run, setRun] = useState(false);

  const startTour = () => setRun(true);

  const handleStartTour = () => {
    tour()?.start();
  };

  const handleExitTour = () => {
    setRun(false);
    tour()?.end();
  };

  return (
    <>
      {!disable && (
        <OnboardingTips
          options={{
            doneLabel: "Done",
            overlayOpacity: 0.5,
            hideNext: true,
            hidePrev: true,
          }}
          onChange={handleStartTour}
          onExit={handleExitTour}
          enabled={run}
          initialStep={0}
          {...rest}
        />
      )}
      <Component {...props} {...{ startTour }} />
    </>
  );
};

export default OnboardingTips;
export { withOnboardingTips };
