import React from 'react';
import {Helmet} from "react-helmet";
import { flowRight as compose } from 'lodash';
import { withRouter, Redirect, matchPath } from 'react-router-dom'
import {PointList, PointListComponent, PointListWithPoint} from './components/PointList';
import History from './components/History';
import {withExpandedIndex} from './components/ExpandedIndex'
import QuickCreateClaim from './components/QuickCreateClaim'
import GenerateClaims from './components/GenerateClaims';
import RecommendedPoints from "./components/RecommendedPoints";
import ModeratorPage from "./components/ModeratorPage";
import AdminPage from "./components/AdminPage";
import AdminUsersPage from "./components/AdminUsersPage";
import {withYsAlerts} from "./AlertProvider";
import {graphql} from "@apollo/client/react/hoc";
import * as schema from "./schema";
import {withAuth} from "./components/Authorization";
import Spinner from "./components/Spinner";

export const homeURL = "/"

class PointPageComponent extends React.Component {
  state = {
    goHome: false,
  }

  constructor(props) {
    super(props);

    this.unlisten = props.expansion.setupRouteMonitoringForPoint(props.location, props.match, props.history)
  }
  componentDidMount() {

  }
  componentWillUnmount() {
      this.unlisten()
  }

  render(){
    if (this.state.goHome){
      return <Redirect to={homeURL}/>
    } else {
      const url = this.props.match.params.url
      return <div id="mainContainer">
        <Helmet>
          <meta property="og:type" content="article" />
          {/*TODO: Gene: This needs to pull the title - should we move GetPoint here or move the helmet down?*/}
          {/*<title>Whysaurus</title>*/}
          {/*<meta property="og:title" content="" />*/}
          {/*<meta property="og:description" content="" />*/}
          <link rel="canonical" href={"https://www.whysaurus.com/claim/" + url + "/"} />
          <meta property="og:url" content={"https://www.whysaurus.com/claim/" + url + "/"} />
          <meta property="og:image" content="https://www.whysaurus.com/static/img/whysaurus_logo.png" />
          {/*<meta property="fb:app_id" content="144595249045851" />*/}
        </Helmet>
        <div className="infiniteWidth">
          <article>
            <PointListWithPoint
                url={url}
                onDelete={() => {this.setState({goHome: true})}}
                showUpstreamLinks={true}
                isPrimaryClaimView={true} />
          </article>
          <RecommendedPoints pointUrl={url} />
        </div>
      </div>
    }
  }
}

export const PointPage = withExpandedIndex(PointPageComponent)

export class HistoryPage extends React.Component {
  render(){
      return <div id="content" className="fullPageText">
          <History url={this.props.match.params.url}/>
        </div>
  }
}

class SimilarPointsComponent extends React.Component {
  render(){
    const url = this.props.match.params.url
    return <div id="mainContainer">
      <div className="infiniteWidth">
        <PointListWithPoint
          url={url}
          onDelete={() => {this.setState({goHome: true})}}
          showUpstreamLinks={true}
          isPrimaryClaimView={true} />
        <h3>Similar Points</h3>
        {this.props.similarPoints ? <PointListComponent points={this.props.similarPoints} /> : <Spinner />}
      </div>
    </div>
  }
}

export const SimilarPointsPage = compose(
  withAuth,
  withRouter,
  withExpandedIndex,
  withYsAlerts,
  graphql(schema.SimilarPoints, {
    options: ({match}) => ({
      variables: {url: match.params.url}
    }),
    props: ({ownProps, data: {similarPoints}}) => ({
      similarPoints
    })
  })
)(SimilarPointsComponent)

export class ModeratorContainer extends React.Component {
  render(){
    return <div className="row pointPageContainer">
      <div id="content" className="">
        <ModeratorPage></ModeratorPage>
      </div>
    </div>
  }
}

export class AdminContainer extends React.Component {
  render(){
    return <div className="row pointPageContainer">
      <div id="content" className="">
        <AdminPage></AdminPage>
      </div>
    </div>
  }
}

export class AdminUsersContainer extends React.Component {
  render(){
    return <div className="row pointPageContainer">
      <div id="content" className="">
        <AdminUsersPage allUsers={false}></AdminUsersPage>
      </div>
    </div>
  }
}

export class AdminUsersAllContainer extends React.Component {
  render(){
    return <div className="row pointPageContainer">
      <div id="content" className="">
        <AdminUsersPage allUsers={true}></AdminUsersPage>
      </div>
    </div>
  }
}

class SearchBoxComponent extends React.Component {
  // since the search box is currently not under react control, just create a
  // function that the classical JS can call into and make it globally available.
  //
  // once we convert the entire page to react this component should actually render the search box.
  render(){
    window.onSearch = (searchTerms) => {
      var pageUrl = '/search?q=' + searchTerms;
      this.props.history.push(pageUrl);

    }
    return <div></div>
  }
}

export const SearchBox = withRouter(SearchBoxComponent)

class MakeArgumentComponent extends React.Component {
  // since the make argument button is currently not under react control, just create a
  // function that the classical JS can call into and make it globally available.
  //
  // once we convert the entire page to react this component should actually render the make argument button
  render(){
    window.makeArgument = () => {
      this.props.history.push(homeURL + "?focusQuickCreate=true");
      QuickCreateClaim.focus()
    }
    return <div></div>
  }
}

export const MakeArgument = withRouter(MakeArgumentComponent)


class GenerateEvidencePageComponent extends React.Component {
    render(){
      const url = this.props.match.params.url
      return <div id="mainContainer">
        <div className="infiniteWidth">
          <GenerateClaims url={url} />
        </div>
      </div>
    }
  }
  
  export const GenerateEvidencePage = compose(
    withAuth,
    withRouter,
    withExpandedIndex,
    withYsAlerts
  )(GenerateEvidencePageComponent)
