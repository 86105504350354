import 'unfetch/polyfill'

import React from 'react';
import './App.css';
import { ApolloProvider } from '@apollo/client';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import AlertProvider from './AlertProvider'
import Authorization from './components/Authorization'
import { withAuth } from './components/Authorization'
import Header from './components/Header'
import { classic } from './graphqlClients'

import NewHomePage from './components/NewHomePage';
import SearchPage from './components/SearchPage';
import NotificationsPage from './components/NotificationsPage';
import UserPage from './components/UserPage';
import ChangePasswordPage from './components/ChangePasswordPage';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import {TagPageMainPage} from './components/TagPage';
import SettingsPage from './components/SettingsPage';
import CreateClaimPage from './components/CreateClaim';
import EditorsPicksPage from './components/EditorsPicksPage'
import EditorPage from './components/EditorPage';
import TopTags from "./components/TopTags";
import {NeedsClarificationPage} from "./components/ModQuickCommentClaimsPage";
import {ExpandedIndexProvider} from './components/ExpandedIndex'
import {
  homeURL, PointPage, HistoryPage, ModeratorContainer,
  AdminContainer, AdminUsersContainer, AdminUsersAllContainer, MakeArgument,
  SearchBox, SimilarPointsPage, GenerateEvidencePage
} from './appComponents'


// These are the less files that get compiled and added to the site
import './less/bootstrap.less'
import './less/responsive.less'
import './less/uiv2.less'
import './less/editor.less'

import asyncComponent from "./components/AsyncComponent";
import {withReactAlerts} from "./components/withReactAlerts";
import ChooseUsernameForm from "./components/ChooseUsername";

const AboutPage = asyncComponent(() => import('./pages/AboutPage'))
const ValuesPage = asyncComponent(() => import('./pages/ValuesPage'))
const FAQPage = asyncComponent(() => import('./pages/FAQPage'))
const ManifestoPage = asyncComponent(() => import('./pages/ManifestoPage'))
const EducationPage = asyncComponent(() => import('./pages/EducationPage'))
const APUSHistoryPage = asyncComponent(() => import('./pages/APUSHistoryPage'))
const WalkthroughPage = asyncComponent(() => import('./pages/WalkthroughPage'))
const PrivacyPage = asyncComponent(() => import('./pages/PrivacyPage'))
const JoinPage = asyncComponent(() => import('./pages/JoinPage'))
const ContactPage = asyncComponent(() => import('./pages/ContactPage'))
const PasswordChangedPage = asyncComponent(() => import('./pages/PasswordChanged'))

const withExpandedIndex = (Component) => (props) => (
  <ExpandedIndexProvider>
    <Component {...props}/>
  </ExpandedIndexProvider>
)

// TODO: Gene: Do we have a way to do this that doesn't depend on the CurrentUserQuery?
class AuthRouteComponent extends React.Component {
  render() {
    if (this.props.userLoading) {
      // TODO: Gene: Cleanup all this - right now only for admin pages
      return <div>User Loading...</div>
    }
    else if (this.props.currentUser && this.props.currentUser.admin) {
      return <Route {...this.props} />
    }
    else {
      return <Redirect to={{pathname: '/login', state: {from: this.props.location}}}/>
    }
  }
}

const AuthRoute = withAuth(AuthRouteComponent)

// TODO: Gene: Remove when done & not actually logging in - why?
// Temporary Login Placeholder
class LoginComponent extends React.Component {
  componentDidMount() {
    // Ignoring loading for now
    // this.props.showLoginDialog()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.userLoading) {
      return
    } else {
      if (this.props.currentUser) {
        if (this.props.isLoginDialogVisible) {
          this.props.hideAuthDialog()
        }
      } else {
        if (!this.props.isLoginDialogVisible) {
          this.props.showLoginDialog()
        }
      }
    }
  }

  render() {
    if (this.props.userLoading || !this.props.currentUser) {
      return <div>Please <a onClick={() => this.props.showLoginDialog()}>Login</a></div>
    }
    else {
      return <Redirect to={{pathname: '/'}}/>
    }
  }
}

const Login = withAuth(LoginComponent)

function Status({ code, children }) {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.status = code;
        return children;
      }}
    />
  );
}
function PageNotFoundComponent() {
  return (
    <Status code={404}>
      <div id="mainContainer">
		  <div id="content" className="fullPageText">
			<h2>Page not found :(</h2>
			<p className="textSection">
				<div><a href="/">Return Home</a></div>
				<br/>
				<div>Error 404</div>
			</p>
		  </div>
	  </div>
    </Status>
  );
}

const AppComponent = () =>
    <div>
      <Header/>
      <MakeArgument/>
      <SearchBox/>
      <TopTags />
      <Switch>
        <Route exact path={homeURL} component={withExpandedIndex(NewHomePage)} />
        <Route exact path="/claim/:url" component={withExpandedIndex(PointPage)} />
        <Route exact path="/history/:url" component={withExpandedIndex(HistoryPage)} />
        <Route exact path="/similar/:url" component={SimilarPointsPage} />
        <Route exact path="/generate/:url" component={withExpandedIndex(GenerateEvidencePage)} />
        <Route exact path="/y/:tagname" component={withExpandedIndex(TagPageMainPage)} />
        <Route exact path="/editorspicks" component={withExpandedIndex(EditorsPicksPage)} />
        <Route exact path="/needsclarification" component={withExpandedIndex(NeedsClarificationPage)} />
        <Route exact path="/search" component={withExpandedIndex(SearchPage)} />
        <Route exact path="/user/:url" component={withExpandedIndex(UserPage)} />

        <Route exact path="/settings" component={SettingsPage} />
        <Route exact path="/create" component={CreateClaimPage} />
        <Route exact path="/createargument" component={CreateClaimPage} />
        <Route exact path="/createquestion" render={(props) => <CreateClaimPage {...props} isQuestion={true} />} />
        <AuthRoute exact path="/moderator" component={ModeratorContainer} />
        {/*<Route exact path="/admin" component={AdminContainer} />*/}
        <Route exact path="/login" component={Login} />
        <Route exact path="/choose_username" component={ChooseUsernameForm} />
        <AuthRoute exact path="/newadmin" component={AdminContainer} />
        <AuthRoute exact path="/users" component={AdminUsersContainer} />
        <AuthRoute exact path="/usersAll" component={AdminUsersAllContainer} />
        <Route exact path="/changePassword" component={ChangePasswordPage} />
        <Route exact path="/passwordChanged" component={PasswordChangedPage} />
        <Route exact path="/forgotPassword" component={ForgotPasswordPage} />
        <Route exact path="/notifications" component={NotificationsPage} />
        <Route exact path="/about" component={AboutPage} />
        <Route exact path="/values" component={ValuesPage} />
        <Route exact path="/faq" component={FAQPage} />
        <Route exact path="/manifesto" component={ManifestoPage} />
        <Route exact path="/education" component={EducationPage} />
        <Route exact path="/apushistory" component={APUSHistoryPage} />
        <Route exact path="/walkthrough" component={WalkthroughPage} />
        <Route exact path="/privacyPolicy" component={PrivacyPage} />
        <Route exact path="/join" component={JoinPage} />
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/new-create" component={EditorPage} />
        <Route component={PageNotFoundComponent} status={404} />
      </Switch>
      <div id="footer">
        <a href="/contact" target="_blank" className="feedbackFooterLink blackLink">Feedback</a>
      </div>
    </div>

// TODO: Gene: const AppWithAlerts = withReactAlerts(AppComponent);
const AppWithAlerts = AppComponent;

class App extends React.Component {
  render() {
    return (
      <AlertProvider>
        <BrowserRouter>
          <ApolloProvider client={classic} >
            <Authorization useNewLoginDialog={true}>
             <AppWithAlerts />
            </Authorization>
          </ApolloProvider>
        </BrowserRouter>
      </AlertProvider>
    )
  }
}

export default App;
