import React from 'react'
import * as validations from '../validations';
import Form from "./formNew";
import TitleText from './TitleText'
import ImagePicker from "./ImagePicker";
import {Controller} from "react-hook-form";
import Spinner from './Spinner'
import {flowRight as compose} from "lodash";
import {withAuth} from "./Authorization";
import { withYsAlerts } from '../AlertProvider';
import {graphql} from '@apollo/client/react/hoc';
import * as schema from "../schema";
import {Tab, Tabs} from "react-bootstrap";
import config from "../config";
import { editorUtils } from './Editor';
import {HashLink} from "react-router-hash-link";

const placeholderTextClaim = 'Dogs are better than cats.';
const placeholderTextQuestion = 'Why are dogs better than cats?';
const sourceTextClaim = 'Add a Source — link to where this point was found';
const sourceTextQuestion = 'Add a Source — link to a document that raises this question';
const imageInstruction = 'Add an Image';


export class CreateClaimComponent extends React.Component {

  // static focus(){
  //   const field = document.getElementById("newPointTextField");
  //   field && field.focus();
  // }

  state = {
    submitting: false,
    // Intended to be seed only
    isQuestion: this.props.isQuestion ?? false,
    placeholderText: placeholderTextClaim,
    sourceText: sourceTextClaim,
    imageUploaded: false,
    optionsVisible: false,
    hasUpdatedOptions: false,
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.setState({optionsVisible: this.props.currentUser.createOptionsVisible});
    } else if (this.props.userLoading) {
      // TODO: Gene: How should we be triggering this really?
      console.log('User Loading On Mount')
    } else {
      // Should we redirect?
      this.props.showLoginDialog()
    }
  }

  submitButtonLabel = () => {
    return <span>Publish</span>
  }

  submitButton = ({...rest}) => {
    if (this.state.submitting) {
      return <span className="quickCreateClaimFeedback">
        <Spinner /><span className="spinnerLabel">Adding your point...</span>
      </span>;
    } else {
      return <button className="buttonUX2 createClaimFormButton" type="submit" {...rest}>{this.submitButtonLabel()}</button>;
    }
  }

  onFormSubmit = (variables) => {
    if (variables['sourceURL']) {
      variables['sourceURLs'] = [variables['sourceURL']];
      variables['sourceNames'] = [variables['sourceName'] || ''];
    }
    editorUtils.mapTextFromValues(variables, 'title', 'title', null)
    if (this.props.currentUser) {
      if (this.state.isQuestion) {
        return this.props.createNewQuestion(variables)
          .then((newPoint) => newPoint && this.props.history.push(`/claim/${newPoint.url}`))
      } else {
        return this.props.createNewPoint(variables)
          .then((newPoint) => newPoint && this.props.history.push(`/claim/${newPoint.url}`))
      }
    } else {
      this.props.showLoginDialog()
      return Promise.reject("User not logged in");
    }
  }

  onQuickCreateTabsSelect = (key) => {
    const isQuestion = key === "createQuestion";
    this.setState({
      isQuickCreateQuestion: isQuestion,
      placeholderText: isQuestion ? placeholderTextQuestion : placeholderTextClaim,
      sourceText: isQuestion ? sourceTextQuestion : sourceTextClaim,
    })
  }

  addSource = (pointID, name, url) => {
    console.log(`Point: ${pointID} name: ${name} url: ${url}`)
  }

  validate = values => ({
    title: validations.validateTitle(values.title),
    sourceURL: values.sourceURL && validations.validateSourceURL(values.sourceURL),
    sourceName: values.sourceURL && validations.validateSourceName(values.sourceName),
  });

  toggleOptions = () => {
    console.log('Toggle')
    const update = !this.state.optionsVisible
    this.setState({optionsVisible: update})
    if (update && !this.state.hasUpdatedOptions) {
      this.setState({hasUpdatedOptions: true})
      this.props.setUserFlag(this.props.currentUser.url, 'setCreateOptionsVisible', 1)
      console.log('Options Updated')
    }
  }

  render(){
    // let { placeholderText } = this.props;
    // let placeholder = placeholderText || 'Start with a claim, eg "Dogs are better than cats."'
    let defaultTabKey = this.props.isQuestion ? 'createQuestion' : 'createClaim';
    if (!this.props.userLoading && !this.props.currentUser) {
      // TODO: this.props.showLoginDialog()
      return <div>Please <a href="/Login">Login</a></div>
    }
    return <div className="mainContainer">
        <div className="fullPageForm">
          <div className="createClaimCreationArea">
          <Form
              onSubmit={this.onFormSubmit}
              validate={this.validate}
              defaultValues={{
                imageURL: '',
                imageDescription: '',
                title: ''
              }}
          >
            {({values: {title, imageUrl, imageDescription}, submitForm, control}) => { return <>
              <Tabs id="tabs-quick-create" defaultActiveKey={defaultTabKey} onSelect={this.onQuickCreateTabsSelect}>
                <Tab eventKey="createClaim" title="Make a good argument">
                  <h3 className="mainPageClaimCreationLabel">Start by making a single point</h3>
                  <div className="formInstruction">Refer to our community's <HashLink to="/faq#GoalsForGoodClaims">goals for good points</HashLink>.</div>
                </Tab>
                <Tab eventKey="createQuestion" title="Ask a question">
                  <h3 className="mainPageClaimCreationLabel">Start with a question</h3>
                </Tab>
              </Tabs>
              <div>  {/*className="claimCreationFormFieldBlock" id="mainPageClaimCreationForm">
              {/* <label>Claim</label>*/}
              <TitleText
                title={title}
                placeholder={this.state.placeholderText}
                id="newPointTextField"
                className="inputFieldUX2 titleTextField"
                suggestExistingClaims={false}
                onSubmit={submitForm}
                validate={{
                  validate: (titleObj) => validations.validateTitle(editorUtils.getTextFromRawContent(titleObj)),
                  required: true,
                }}
                />
              </div>
              <div>
                <a className="createOptionsToggle" onClick={this.toggleOptions}>Additional Options</a>
                {this.state.optionsVisible && <div className="optionsContainer">
                  <div>
                    <label className="createClaimFormFirstOptionLabel">{this.state.sourceText}</label>
                    <div>
                      <Form.Text
                        name="sourceURL"
                        className="inputFieldUX2"
                        placeholder="URL"
                        validate={{ validate: validations.validateSourceUrlAllowNull, }}
                      />
                      <Form.Text
                        name="sourceName"
                        className="inputFieldUX2"
                        placeholder="Title"
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="addEvidenceAreaNote">{imageInstruction}</div>
                      <Controller
                        name="imageURL"
                        control={control}
                        render={({ onChange }) => (
                          <ImagePicker
                            onChange={onChange}
                            onUploaded={(file) => {
                              this.setState({imageUploaded: true});
                              console.log(`Image Uploaded: ${imageUrl}`)
                              console.log(file);
                            }}
                            onTransformed={(result) => {
                              console.log("Saved transformed versions of image:")
                              console.log(result)
                            }}
                          />
                        )}
                      />
                      {!imageUrl && this.state.imageUploaded && <span id="imageUploadSuccessMessage">Image Uploaded!</span>}
                      {imageUrl && (
                        <img
                          className="imageBigDisplay"
                          src={config.cdn.baseURL + imageUrl}
                          alt={imageDescription}
                        />
                      )}
                      {this.state.imageUploaded && <Form.Text name="imageDescription" label="Add a caption, credit, description, etc (optional)" />}
                    </div>
                  </div>
                </div>}
              </div>
              <div className="claimCreationFormButtonBlock">
                {this.submitButton({disabled: (!title || (title === "") || (title.length > validations.titleMaxCharacterCount) )})}
                <div className="formInstruction">Next, you'll add evidence...</div>
                {/* TODO: title.length < validations.titleMinCharacterCount ||/}
                {/* TODO: || (validationFailures > 0)*/}
              </div>
            </>}}
          </Form>
        </div>
        </div>
      </div>
  }
}

CreateClaimComponent.defaultProps = {
  placeholderText: 'Start with a claim, eg "Dogs are better than cats."',
  isQuestion: false,
}

export default compose(
  withAuth,
  withYsAlerts,
  graphql(schema.NewPoint, {
    props: ({ mutate }) => ({
      createNewPoint: (variables) => mutate({variables}).
        then(({data: {newPoint}}) => newPoint && newPoint.point)
    })
  }),
  graphql(schema.NewQuestion, {
    props: ({ mutate }) => ({
      createNewQuestion: (variables) => mutate({variables}).
        then(({data: {newQuestion}}) => newQuestion && newQuestion.point)
    })
  }),
  graphql(schema.SetUserFlag, {
    props: ({ mutate }) => ({
      setUserFlag: (userUrl, flag, value) => mutate({variables: {userUrl, flag, value}})
    })
  }),
)(CreateClaimComponent)