import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Link } from 'react-router-dom'

import * as schema from '../schema';
import { PointList } from './PointList';
import RecommendedPoints from "./RecommendedPoints";
import {Helmet} from "react-helmet";

// TODO: GB: Code for returning to paged results
// const searchResultsDefaultPageSize = 10;
// const TagPointList = graphql(schema.TagPoints, {
//   options: ({pointsPerPage, tag}) => ({variables: {tag: tag, limit: pointsPerPage || searchResultsDefaultPageSize}}),
//   props: ({ownProps: {pointsPerPage, tag}, data: { loading, fullClaimSearch = {}, fetchMore}}) => ({
//     tag: tag,
//     loading: loading,
//     points: fullClaimSearch.points,
//     hasMore: fullClaimSearch.hasMore,
//     infiniteScroll: true,
//     loadMorePoints: () => {
//       return fetchMore({
//         query: schema.TagPoints,
//         variables: {tag: tag, cursor: fullClaimSearch.cursor, limit: pointsPerPage || searchResultsDefaultPageSize},
//         updateQuery: (previousResult, { fetchMoreResult }) => {
//           fetchMoreResult.fullClaimSearch.points = [...previousResult.fullClaimSearch.points, ...fetchMoreResult.fullClaimSearch.points]
//           return fetchMoreResult
//         }
//       })
//     }
//   })
//
// })(PointList)

const TagPointList = graphql(schema.TagPoints, {
  props: ({ownProps: {tagUrl}, data: { loading, tagResult }}) => ({
    tagUrl: tagUrl,
    loading: loading,
    points: tagResult && tagResult.points
  })
})(PointList)

class TagPage extends React.Component {
  render(){
    return <div id="infiniteOrFiniteWidth" className="">
        <TagPointList tagUrl={this.props.tagUrl}/>
      </div>
  }
}

export class TagPageMainPageComponent extends React.Component {
  render(){
    let tagUrl = this.props.match.params.tagname
    let tag = this.props.tag
    return <div>
      {tag && <Helmet>
        <title>{tag.text} -- Whysaurus</title>
        <meta property="og:title" content={tag.text} />
        <meta property="og:description" content={tag.text} />
        <link rel="canonical" href={"https://www.whysaurus.com/claim/" + tagUrl + "/"} />
        <meta property="og:url" content={"https://www.whysaurus.com/claim/" + tagUrl + "/"} />
      </Helmet>}
      <div id="mainContainer">
        <div className="infiniteWidth">
          <header>
            {tag && <h1 id="tagHeader"><Link to={"/y/" + tagUrl}>#{tag.text}</Link></h1>}
          </header>
          <TagPage tagUrl={tagUrl}/>
          <RecommendedPoints pointUrl='' />
        </div>
      </div>
    </div>
  }
}

export const TagPageMainPage = graphql(schema.GetTag, {
  options: (ownProps) => ({
    variables: {
      tagUrl: ownProps.match.params.tagname
    }
  }),
  props: ({ownProps: {tagUrl}, data: { loading, getTag }}) => ({
    tagUrl: tagUrl,
    loading: loading,
    tag: getTag
  })
})(TagPageMainPageComponent)

export default TagPage
