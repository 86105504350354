import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash';

import * as schema from '../schema'
import Spinner from './Spinner'
import {withExpandedIndex} from './ExpandedIndex'
import {withRouter} from "react-router";
import {withAuth} from "./Authorization";

class AdminUserComponent extends React.Component {
  setActive = (e) => {
    let user = this.props.user
    this.props.setTagActive(user.url, true)
  }

  setNotes = (e) => {
    let user = this.props.user

    var r = prompt("Notes for user " + user.url + "?", user.notes);
    if (r == null || r == "") {
      confirm("Notes Set Bypassed!")
      return;
    }

    this.props.setUserNotes(user.url, r)
  }

  setUserGA = (e) => {
    let user = this.props.user

    var r = prompt("GA Id For User (" + user.url + ")?\r\n(Only use when GA Id is empty!)", user.gaId);
    if (r == null || r == "") {
      confirm("GA Id Set Bypassed!")
      return;
    }

    this.props.setUserGaid(user.url, r)
  }

  setUserInternal = (e) => {
    let user = this.props.user

    var r = confirm("Flag user (" + user.url + ") as internal?");
    if (r != true) {
      confirm("Set internal aborted")
      return
    }

    this.props.setUserInternal(user.url, true)
  }

  setPowerUser = (e) => {
    let user = this.props.user

    var r = confirm("Flag user (" + user.url + ") as power user?");
    if (r != true) {
      confirm("Set power user aborted")
      return
    }

    this.props.setUserPrivilege(user.url, "powerUser")
  }

  setUserRole = (e) => {
    let user = this.props.user

    // TODO: How to assign?
    var role = this.roleSelect.value

    var r = confirm("Flag user (" + user.url + ") as " + role + "?");
    if (r != true) {
      confirm("Set internal aborted")
      return
    }

    this.props.setUserRole(user.url, role)
  }

  setUserClassroom = (e) => {
    let user = this.props.user

    var r = prompt("Classroom To Add For User (" + user.url + ")?");
    if (r == null || r == "") {
      confirm("GA Id Set Bypassed!")
      return;
    }

    this.props.setUserPrivateArea(user.url, r, true)
  }

  resetUserPassword = (e) => {
    let user = this.props.user

    var r = confirm("Change the password for user " + user.url + "?");
    if (r != true) {
      confirm("Password reset aborted")
      return
    }

    this.props.resetUserPassword(user.url)
  }

  render(){
    let user = this.props.user
    let userAreas = this.props.user.privateAreas
    let areaString
    if (userAreas) {
      if (userAreas.length == 0) {
        areaString = 'None'
      } else {
        areaString = userAreas.join(', ')
      }
    }
    return <tr>
      <td><a href={"/user/" + user.url}>{user.url}</a></td>
      <td>{user.displayName}</td>
      <td>{user.notes}</td>
      <td><a name="setActive" onClick={this.setNotes}>Set Notes</a></td>
      <td>{user.gaId}</td>
      <td>{String(user.isInternal)}</td>
      <td>{String(user.powerUser)}</td>
      <td>
        <select className="role-chooser" defaultValue={user.role} ref={select=> this.roleSelect = select}>
          <option value=""></option>
          <option value="Limited">Limited</option>
          <option value="User">User</option>
          <option value="Teacher">Teacher</option>
          <option value="Admin">Admin</option>
        </select>
        <a name="setUserRole" onClick={this.setUserRole}>Set Role</a>
      </td>
      <td><a href="/user/{user.url}">{user.username}</a></td>
      <td className='adminTableSimpleNumber'>{user.createdCount}</td>
      <td className='adminTableSimpleNumber'>{user.editedCount}</td>
      <td className='adminTableSimpleNumber'>{user.viewedCount}</td>
      <td className='adminTableSimpleNumber'>{user.lastVisitAvgIntervalDays}</td>
      <td className='adminTableDateCol'>{user.lastLoginPST}</td>
      <td className='adminTableDateCol'>{user.lastViewPST}</td>
      <td className='adminTableDateCol'>{user.lastSessionPST}</td>
      <td className='adminTableDateCol'>{user.lastEmailSent}</td>
      <td className='adminTableDateCol'>{user.createdPST}</td>
      <td className='adminTableDateCol'>{user.updatedPST}</td>
      <td className='adminTableEllipsisObvi'>
        <a name="setUserGaid" onClick={this.setUserGA}>Set GA Id</a>
      </td>
      <td className='adminTableEllipsisObvi'>
        <a name="setInternalUser" onClick={this.setUserInternal}>Set Internal</a>
      </td>
      <td className='adminTableEllipsisObvi'>
        <a name="setPowerUser" onClick={this.setPowerUser}>Set Power User</a>
      </td>
      <td className='adminTableEllipsisObvi'>
        <a name="resetPassword" onClick={this.resetUserPassword}>Reset password </a>
      </td>
      <td className='adminTableClassrooms'>
        {userAreas && userAreas.map((area, i) => <span key={i}>{area} </span>)}
      </td>
      <td className='adminTableEllipsisObvi'>
        <a name="setUserGaid" onClick={this.setUserClassroom}>Add Classroom</a>
      </td>
    </tr>
  }
}

const AdminUser = compose(
  graphql(schema.SetUserNotes, {
    props: ({ mutate }) => ({
      setUserNotes: (userUrl, userNotes) => mutate({variables: {userUrl, userNotes}})
    })
  }),
  graphql(schema.ResetUserPassword, {
    props: ({ mutate }) => ({
      resetUserPassword: (userUrl) => mutate({variables: {userUrl}})
    })
  }),
  graphql(schema.SetUserInternal, {
    props: ({ mutate }) => ({
      setUserInternal: (userUrl, isInternal) => mutate({variables: {userUrl, isInternal}})
    })
  }),
  graphql(schema.SetUserGaid, {
    props: ({ mutate }) => ({
      setUserGaid: (userUrl, gaid) => mutate({variables: {userUrl, gaid}})
    })
  }),
  graphql(schema.SetUserPrivateArea, {
    props: ({ mutate }) => ({
      setUserPrivateArea: (userUrl, id, active) => mutate({variables: {userUrl, id, active}})
    })
  }),
  graphql(schema.SetUserRole, {
    props: ({ mutate }) => ({
      setUserRole: (userUrl, role) => mutate({variables: {userUrl, role}})
    })
  }),
  graphql(schema.SetUserPrivilege, {
    props: ({ mutate }) => ({
      setUserPrivilege: (userUrl, userPrivilege) => mutate({variables: {userUrl, userPrivilege}})
    })
  }),
)(AdminUserComponent)

class AdminUserListComponent extends React.Component {
  renderUser = (user) => {
    return <AdminUser key={user.url} user={user} areas={this.props.areas} />
  }

  renderUsers = (users) => users.map((user, i) => this.renderUser(user))

  render(){
    if (this.props.users) {
      return <div>
        <div>
          <table className="adminTable">
            <thead key="thead">
            <tr>
              <th>URL</th>
              <th>Name</th>
              <th>Notes</th>
              <th>Set Notes</th>
              <th>GA ID</th>
              <th className='adminTableSimpleNumber'>Internal<span className='adminTableDetail'>Ys Team Account?</span></th>
              <th className='adminTableSimpleNumber'>Power User</th>
              <th>User Role</th>
              <th>User Name</th>
              <th className='adminTableSimpleNumber'>Created</th>
              <th className='adminTableSimpleNumber'>Edited</th>
              <th className='adminTableSimpleNumber'>Viewed</th>
              <th className='adminTableSimpleNumber'>Average Days b/t Visits <span className='adminTableDetail'>Since 1/2018</span></th>
              <th className='adminTableDateCol'>Last Login (PST)</th>
              <th className='adminTableDateCol'>Last Visit (PST)</th>
              <th className='adminTableDateCol'>Last Session (PST)</th>
              <th className='adminTableDateCol'>Last Notification Email</th>
              <th className='adminTableDateCol'>Created</th>
              <th className='adminTableDateCol'>Updated</th>
              <th className='adminTableEllipsisObvi'>Set User GA Id<span className='adminTableDetail'>Only use when GA Id is empty</span></th>
              <th className='adminTableEllipsisObvi'>Set Internal</th>
              <th className='adminTableEllipsisObvi'>Set Power User</th>
              <th className='adminTableEllipsisObvi'>Password reset</th>
              <th className='adminTableClassrooms'>User Classroom(s)</th>
              <th className='adminTableEllipsisObvi'>Add Classroom</th>
            </tr>
            </thead>
            <tbody key="tbody">
              {this.renderUsers(this.props.users)}
            </tbody>
          </table>
        </div>
      </div>
    } else if (this.props.loading || (this.props.data && this.props.data.loading)) {
      return <div className="spinnerPointList"><Spinner /></div>
    } else {
      return <div></div>
    }
  }
}

const AdminUserList = withRouter(AdminUserListComponent);

const AllAdminUserList = graphql(schema.AdminUsersPage, {
  options: ({allUsers}) => ({
    variables: {allUsers: allUsers || false}
  }),
  props: ({ownProps, data: { loading, adminUsersPage }}) => ({
    loading: loading,
    users: adminUsersPage && adminUsersPage.users,
    areas: adminUsersPage && adminUsersPage.areas
  })
})(AdminUserList);

class AdminUsersPage extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    tabIndex: 0
  }

  componentDidMount(){

  }

  usersTest = (e) => {
    console.log('Admin: Users Test..')
  }

  render(){
    const {userLoading, currentUser, showLoginDialog} = this.props
    if (userLoading) {
      return <div></div>
    } else if (currentUser && currentUser.admin) {
      return <div className="infiniteWidth">
        <div>
          <div className="adminPageTitle">Users sorted by {this.props.allUsers ? "last update" : "last log-in"}</div>
          <div className="adminTopButtons">
            <button id='adminTest' onClick={this.usersTest}>Users Test</button>
          </div>
        </div>
        <div className="usersContentArea">
          <div id="mainPageMainArea">
            <AllAdminUserList allUsers={this.props.allUsers || false}/>
          </div>
        </div>
      </div>
    } else {
      return <div>Not Authorized</div>
    }
  }
}

export default compose(
  withExpandedIndex,
  withAuth,
  graphql(schema.AdminUsersPage),
)(AdminUsersPage);
