import {
  convertFromRaw,
} from 'draft-js';

export const isValidContent = (rawContent) => {
  try {
    convertFromRaw(rawContent);
    return true;
  } catch (err) {
    return false;
  }
};

export const findMentionEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'mention'
      );
    },
    callback
  );
};