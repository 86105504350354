import FB from 'react-facebook-pixel';

export const fbq = (action, event, data) => {
  if (action === "track"){
    FB.track(event, data)
  } else if (action === "trackCustom") {
    FB.trackCustom(event, data)
  } else {
    console.warn(`facebook action ${action} not recognized, not tracking`)
  }
}

export default () => {
  if (process.env.REACT_APP_FB_PIXEL_ID) {
    FB.init(process.env.REACT_APP_FB_PIXEL_ID);
    FB.pageView();
  } else {
    console.warn("FB_PIXEL_ID environment variable not specified, data will not be sent")
  }
}
