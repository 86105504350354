import React, { useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider, useFormContext, useController } from 'react-hook-form';
import { Editor } from './Editor';

const fieldID = (field) => `ys-form-${field}`;

const renderError = (error) => (
  <div className="alert alert-error">{error.message}</div>
);

const Text = ({
  name,
  label,
  id = "",
  type = "text",
  icon = null,
  validate = {},
  ...props
}) => {
  const { register, errors } = useFormContext() || {};
  const error = errors ? errors[name] : null;
  const rref = register ? register(validate) : null;

  return (
    <>
      {label && <label htmlFor={fieldID(name)}>{label}</label>}
      <div style={{ position: "relative" }}>
        <input
          type={type}
          id={id || fieldID(name)}
          name={name}
          ref={rref}
          {...props}
        />
        {icon && <span className="eyeMask">{icon}</span>}
      </div>
      {error && renderError(error)}
    </>
  );
};

Text.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  validate: PropTypes.object,
  id: PropTypes.string,
  icon: PropTypes.object,
  type: PropTypes.oneOf(["text", "password", "email"]),
};

const TextArea = ({ name, label, id = "", validate = {}, ...props }) => {
  const { register, errors } = useFormContext() || {};
  const error = errors ? errors[name] : null;
  const rref = register ? register(validate) : null;

  return (
    <>
      {label && <label htmlFor={fieldID(name)}>{label}</label>}
      <textarea
        type="textarea"
        id={id || fieldID(name)}
        name={name}
        ref={rref}
        {...props}
      />
      {error && renderError(error)}
    </>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  validate: PropTypes.object,
  id: PropTypes.string,
};

const Checkbox = ({ name, label, id = "", validate = {}, ...props }) => {
  const { register, errors } = useFormContext() || {};
  const error = errors ? errors[name] : null;
  const rref = register ? register(validate) : null;

  return (
    <>
      {label && <label htmlFor={fieldID(name)}>{label}</label>}
      <input
        type="checkbox"
        id={id || fieldID(name)}
        name={name}
        ref={rref}
        {...props}
      />
      {error && renderError(error)}
    </>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  validate: PropTypes.object,
  id: PropTypes.string,
};

const Radio = ({ name, label, id = "", validate = {}, ...props }) => {
  const { register, errors } = useFormContext() || {};
  const error = errors ? errors[name] : null;
  const rref = register ? register(validate) : null;

  return (
    <>
      {label && <label htmlFor={fieldID(name)}>{label}</label>}
      <input
        type="radio"
        id={id || fieldID(name)}
        name={name}
        ref={rref}
        {...props}
      />
      {error && renderError(error)}
    </>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  validate: PropTypes.object,
  id: PropTypes.string,
};

/* Form wrapper for Draft Editor */
const FormEditor = ({
  name,
  placeholder,
  validate,
}) => {
  const { control, errors } = useFormContext();
  const error = errors ? errors[name] : null;
  const {
    field: { onChange }
  } = useController({
    name,
    control,
    rules: validate,
  });

  return (
    <>
      <Editor
        onChange={onChange}
        placeholder={placeholder}
      />
      {error && renderError(error)}
    </>
  );
};

const Form = React.forwardRef(({
  children,
  onSubmit,
  defaultValues,
  className,
  id,
  validationMode = 'onSubmit',
}, ref) => {
  const methods = useForm({
    defaultValues,
    mode: validationMode,
  });
  const _onSubmit = methods.handleSubmit(onSubmit);

    useImperativeHandle(
      ref,
      () => ({
        reset: methods.reset,
      }),
      [methods]
    );

    return (
      <FormProvider {...methods}>
        <form onSubmit={_onSubmit} className={className} id={id}>
          {children({
            submitForm: _onSubmit,
            errors: methods.errors,
            values: methods.watch(),
            control: methods.control,
          })}
        </form>
      </FormProvider>
    );
  }
);

Form.propTypes = {
  children: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string,
  validationMode: PropTypes.oneOf([
    "onSubmit",
    "onChange",
    "onBlur",
    "onTouched",
  ]),
};

Form.Text = Text;
Form.TextArea = TextArea;
Form.Checkbox = Checkbox;
Form.Radio = Radio;
Form.Editor = FormEditor;

export const formDataPoster = (url) => (data) => {
  var body = new URLSearchParams();

  for (const [key, value] of Object.entries(data)) {
    body.append(key, value);
  }

  return fetch(url, { method: "POST", body });
};

export default Form;
