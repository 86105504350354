import gql from 'graphql-tag';

export const userFragment = gql`
fragment userFields on User {
  url
  username
  displayName
  privateAreas
  avatarURL
  admin
  moderator
  powerUser
  notes
  hasConfirmedTermsAndConditions
  hasConfirmedHeaderWalkthrough
  role
  recentlyViewed { id, url, title }
  email
  bio
  websiteURL
  notificationFrequencyAgreeDisagree
  notificationFrequencyAchievements
  notificationFrequencyCounterPoint
  notificationFrequencyComments
  notificationFrequencyEdits
  notificationFrequencyMentions
  gaId
  isInternal
  canAddTags
  createdCount
  editedCount
  viewedCount
  lastVisitAvgIntervalDays
  lastLoginPST
  lastViewPST
  lastSessionPST
  lastEmailSent
  createdPST
  updatedPST
  privateAreas
  createOptionsVisible
}
`

export const LogIn = gql`
${userFragment}
mutation LogIn($auth: String, $password: String!) {
  logIn(auth: $auth, password: $password) {
    success
    errorMessage
    user { ...userFields }
  }
}
`
export const ForgotPassword = gql`
mutation ForgotPassword($auth: String) {
  forgotPassword(auth: $auth) {
    success
  }
}
`
export const ChangePassword = gql`
${userFragment}
mutation ChangePassword($password: String!) {
  changePassword(password: $password) {
    user { ...userFields }
  }
}
`

export const CheckUsername = gql`
mutation CheckUsername($username: String!) {
  checkUsername(username: $username) {
    success
    message
  }
}
`

export const SignUp = gql`
${userFragment}
mutation SignUp($user: UserInput!) {
  signUp(user: $user) {
    user { ...userFields }
  }
}
`

export const notificationFragment = gql`
fragment notificationFields on Notification {
  id
  preLinkText
  timeText
  reasonCode
  cleared
  point {
    url
    title
  }
  sourceUser {
    avatarURL
  }
  targetUser {
    avatarURL
  }
}
`

export const NotificationSubscription = gql`
subscription NotificationSubscription($ref: String!) {
  notificationUpdate @rtdbSub(ref: $ref, event: "value") @type(name: "NotificationUpdate") {
    timestamp
  }
}
`

// Mod: Removed: currentArea, currentAreaDisplayName
export const CurrentUserQuery = gql`
${userFragment}
${notificationFragment}
query CurrentUser {
  currentUser {
    ...userFields
    notifications {
      ...notificationFields
    }
    newNotificationCount
    token
  }
}`

export const UserQuery = gql`
${userFragment}
query User($url: String!) {
  user(url: $url) {
    ...userFields
  }
}`

export const ActiveUsersQuery = gql`
query ActiveUsers {
  activeUsers {
    id
    display
  }
}`

export const ProfileUser = gql`
query User($url: String!) {
  user(url: $url) {
    url
    bio
    websiteURL
    createdCount
    editedCount
    displayName
    avatarURL
    notes
    moderator
  }
}`

export const Notifications = gql`
${notificationFragment}
query Notifications {
  notifications {
    ...notificationFields
  }
}`

export const ClearNotifications = gql`
${notificationFragment}
mutation ClearNotifications($notificationIDs: [ID]) {
  clearNotifications(notificationIDs: $notificationIDs) {
     ...notificationFields
  }
}
`

export const SetUserArea = gql`
mutation SetUserArea($areaName: String) {
  setUserArea(areaName: $areaName) {
    areaName
  }
}
`

const emojiFieldsFragment = gql`
fragment emojiFields on Emoji {
  id
  emojiCode
  count
  isUserEmoji
}
`

const aggregateCommentFieldsFragment = gql`
fragment aggregateCommentFields on AggregateComment {
  id
  quickCommentType
  count
  countUnresolved
  isUserComment
}
`

const proposedEditFieldsFragment = gql`
fragment proposedEditFields on ProposedEdit {
  id
  text
  votesFor
  votesAgainst
  userVote
}
`

export const pointFieldsFragment = gql`
${emojiFieldsFragment}
${aggregateCommentFieldsFragment}
${proposedEditFieldsFragment}
fragment pointFields on Point {
  id,
  url,
  pointType,
  title,
  authorName,
  authorURL,
  creatorName,
  creatorURL,
  dateEdited,
  imageURL,
  imageDescription,
  fullPointImage,
  upVotes,
  downVotes,
  pointValue,
  numSupporting,
  numCounter,
  numAnswers,
  numUsersContributed,
  isTop,
  stale,
  supportedCount,
  engagementScore,
  controversyScore,
  sources { id, url, name },
  tags { id, url, text },
  emojis { ...emojiFields },
  quickComments { ...aggregateCommentFields },
  proposedEdits { ...proposedEditFields },
  numActiveEdits,
  rootURLsafe,
  currentUserVote,
  isRecentlyUpdated,
  root {
    numComments
    editorsPick
    editorsPickSort
  }
}
`

export const evidenceEdgesFragment = gql`
fragment evidenceEdges on SubPointConnection {
  edges { 
    node { ...pointFields }, 
    link { id, type, numComments, relevance, relevanceVote, voteCount, sortScore, stale, parentURLsafe, childURLsafe }
  }
}`


export const expandedPointFieldsFragment = gql`
${pointFieldsFragment}
${evidenceEdgesFragment}
fragment evidenceFields on Point {
 supportingPoints { ...evidenceEdges },
 counterPoints { ...evidenceEdges },
 answerPoints { ...evidenceEdges },
 relevantPoints { ...evidenceEdges }
}`

export const EditPointQuery = gql`
mutation EditPoint($url: String!, $title: String, $imageDescription: String, $imageURL: String) {
  editPoint(pointData: {url: $url, title: $title, imageDescription: $imageDescription, imageURL: $imageURL}) {
    point {
      id,
      title,
      url,
      imageURL,
      imageDescription,
      fullPointImage
    }
  }
}
`

export const AddEvidenceQuery = gql`
${pointFieldsFragment}
${evidenceEdgesFragment}
mutation AddEvidence($title: String!, $linkType: String, $parentURL: String, $imageURL: String, $imageAuthor: String, $imageDescription: String, $sourceURLs: [String], $sourceNames: [String]) {
  addEvidence(pointData: {title: $title, content: $title, summaryText: $title, imageURL: $imageURL, imageAuthor: $imageAuthor, imageDescription: $imageDescription, sourceURLs: $sourceURLs, sourceNames: $sourceNames, linkType: $linkType, parentURL: $parentURL}) {
    newEdges { ...evidenceEdges }
    parent { id, numSupporting, numCounter }
  }
}
`

export const LinkPoint = gql`
${pointFieldsFragment}
${evidenceEdgesFragment}
mutation LinkPoint($parentURL: String!, $url: String!, $linkType: String!) {
  linkPoint(parentURL: $parentURL, linkType: $linkType, url: $url) {
    newEdges { ...evidenceEdges }
    parent { id, numSupporting, numCounter }
  }
}
`

export const VoteQuery = gql`
mutation Vote($url: String!, $vote: Int!, $parentURL: String) {
  vote(url: $url, vote: $vote, parentURL: $parentURL) {
    point {
      id
      pointValue
      upVotes
      downVotes
      currentUserVote
    }
    parentPoint {
      id
      pointValue
    }
  }
}
`

export const DeletePointMutation = gql`
mutation Delete($url: String!) {
  delete(url: $url) {
    url
  }
}
`

export const AddSource = gql`
mutation AddSource($pointID: String!, $url: String!, $name: String) {
  addSource(pointID: $pointID, url: $url, name: $name) {
    point {
      id
      sources { id, name, url }
    }
  }
}

`
export const DeleteSource = gql`
mutation DeleteSource($pointID: String!, $id: String!) {
  deleteSource(pointID: $pointID, id: $id) {
    point {
      id
      sources { id, name, url }
    }
  }
}
`

export const AddTag = gql`
mutation AddTag($pointID: String!, $tagUrl: String!) {
  addTag(pointID: $pointID, tagUrl: $tagUrl) {
    point {
      id
      tags { id, text, url }
    }
  }
}

`
export const DeleteTag = gql`
mutation DeleteTag($pointID: String!, $id: String!) {
  deleteTag(pointID: $pointID, id: $id) {
    point {
      id
      tags { id, text, url }
    }
  }
}
`

export const UnlinkPointMutation = gql`
mutation Unlink($parentURL: String!, $url: String!, $linkType: String!) {
  unlink(parentURL: $parentURL, url: $url, linkType: $linkType) {
    parentURL,
    url
  }
}
`

export const SetTop = gql`
mutation SetTop($id: String!, $isTop: Boolean!) {
  setTop(id: $id, isTop: $isTop) {
    point {
      id
    }
  }
}
`

export const SetEditorsPick = gql`
mutation SetEditorsPick($id: String!, $editorsPick: Boolean!, $sort: Int!, $notify: Boolean!) {
  setEditorsPick(id: $id, editorsPick: $editorsPick, sort: $sort, notify: $notify) {
    point {
      id
      root {
        editorsPick
        editorsPickSort
      }
    }
  }
}
`

export const SetStale = gql`
mutation SetStale($id: String!, $stale: Boolean!, $staleDate: DateTime) {
  setStale(id: $id, stale: $stale, staleDate: $staleDate) {
    point {
      id
      root {
        editorsPick
        editorsPickSort
      }
    }
  }
}
`

export const ReportClaim = gql`
mutation ReportClaim($id: String!) {
  reportClaim(id: $id) {
    point {
      id
    }
  }
}
`

export const ReportComment = gql`
mutation ReportComment($id: String!, $pointId: String!) {
  reportComment(id: $id, pointId: $pointId) {
    point {
      id
    }
  }
}
`

export const RemoveQuickComment = gql`
mutation RemoveQuickComment($id: String!) {
  removeQuickComment(id: $id) {
    success
  }
}
`

export const AddQuickComment = gql`
mutation AddQuickComment($id: String!) {
  addQuickComment(id: $id) {
    success
  }
}
`

export const RemoveEmoji = gql`
mutation RemoveEmoji($id: String!) {
  removeEmoji(id: $id) {
    success
  }
}
`

export const AddQuickEmoji = gql`
mutation AddQuickEmoji($id: String!) {
  addQuickEmoji(id: $id) {
    success
  }
}
`

export const MakeFeatured = gql`
mutation MakeFeatured($id: String!) {
  makeFeatured(id: $id) {
    point {
      id
    }
  }
}
`

export const RelevanceVoteQuery = gql`
mutation RelevanceVote($linkType: String!, $parentRootURLsafe: String!, $rootURLsafe: String!, $url: String!, $vote: Int!) {
  relevanceVote(linkType: $linkType, rootURLsafe: $rootURLsafe, parentRootURLsafe: $parentRootURLsafe, url: $url, vote: $vote) {
    point {
      id
    }

    parentPoint {
      id
      pointValue
    }

    link {
      id,
      type,
      relevance,
      relevanceVote,
      voteCount,
      sortScore,
      parentURLsafe,
      childURLsafe
    }
  }
}
`

export const Emojis = gql`
${emojiFieldsFragment}
query Emojis($pointUrl: String!) {
  emojis(pointUrl: $pointUrl) {
    emojis {
      ...emojiFields
    }
  }
}
`

export const NewEmoji = gql`
${pointFieldsFragment}
mutation NewEmoji($pointUrl: String!, $emojiCode: String!) {
  newEmoji(pointUrl: $pointUrl, emojiCode: $emojiCode) {
    point {
      ...pointFields
    }
  }
}
`

export const RemoveEmojiForUser = gql`
mutation RemoveEmojiForUser($pointUrl: String!, $emojiCode: String!) {
  removeEmojiForUser(pointUrl: $pointUrl, emojiCode: $emojiCode) {
    success
  }
}
`

export const GetPoint = gql`
${expandedPointFieldsFragment}
query Point($url: String) {
  point(url: $url) {
    ...pointFields,
    ...evidenceFields
 }
}`

export const GetLuckyPoint = gql`
${pointFieldsFragment}
query GetLuckyPoint {
  getLuckyPoint {
    ...pointFields
  }
}`

export const Backlinks = gql`
query Point($url: String) {
  point(url: $url) {
    id
    supportingBacklinks {id, url, title}
    counterBacklinks {id, url, title}
 }
}`

export const GetCollapsedPoint = gql`
${pointFieldsFragment}
query Point($url: String) {
  point(url: $url) {
    ...pointFields
 }
}`

export const Search = gql`
query Search($query: String!) {
  search(query: $query) {
    id, url, title
  }
}`

export const FullClaimSearch = gql`
${pointFieldsFragment}
query FullClaimSearch($q: String!, $cursor: String, $limit: Int) {
  fullClaimSearch(q: $q, cursor: $cursor, limit: $limit) {
    cursor
    points {
      ...pointFields
    }
    hasMore
  }
}`

export const TagSearch = gql`
query TagSearch($q: String!) {
  tagSearch(q: $q) {
    url, text
  }
}`

export const HomePage = gql`
${pointFieldsFragment}
query HomePage {
  homePage {
    featuredPoint {
      ...pointFields
    }
  }
}
`

export const NewHomePageFeaturedPoint = gql`
${pointFieldsFragment}
query NewHomePageFeaturedPoint {
  homePage {
    featuredPoint {
      ...pointFields
    }
  }
}
`

export const NewHomePageLatestPoints = gql`
${pointFieldsFragment}
query NewHomePageLatestPoints {
  homePage {
    latestPoints {
      ...pointFields
    }
  }
}
`

export const NewHomePageEditorsPicks = gql`
${pointFieldsFragment}
query NewHomePageEditorsPicks {
  homePage {
    editorsPicks {
      points { ...pointFields }
      numLeadPoints
    }
  }
}
`

export const NewHomePageSpecificTagAndPoints = gql`
${pointFieldsFragment}
query NewHomePageSpecificTagAndPoints {
  homePage {
    specificTagAndPoints {
      points { ...pointFields }
      tag { url, text }
    }
  }
}
`

export const NewHomePageFeaturedTagAndPoints = gql`
${pointFieldsFragment}
query NewHomePageFeaturedTagAndPoints {
  homePage {
    featuredTagAndPoints {
      points { ...pointFields }
      tag { url, text }
    }
  }
}
`

export const NewHomePageFeaturedTagAndPointsSecondary = gql`
${pointFieldsFragment}
query NewHomePageFeaturedTagAndPointsSecondary {
  homePage {
    featuredTagAndPointsSecondary {
      points { ...pointFields }
      tag { url, text }
    }
  }
}
`

export const NewHomePageFeaturedAuthorAndPoints = gql`
${pointFieldsFragment}
query NewHomePageFeaturedAuthorAndPoints {
  homePage {
    featuredAuthorAndPoints {
      points { ...pointFields }
      creator { url, name }
    }
  }
}
`

export const NewHomePageControversialPoints = gql`
${pointFieldsFragment}
query NewHomePageControversialPoints {
  homePage {
    controversialPoints {
      ...pointFields
    }
  }
}
`

export const NewHomePageSuggestedPoints = gql`
${pointFieldsFragment}
query NewHomePageSuggestedPoints {
  homePage {
    suggestedPoints {
      ...pointFields
    }
  }
}
`

export const NewHomePageQuestions = gql`
${pointFieldsFragment}
query NewHomePageQuestions {
  homePage {
    questionPoints {
      ...pointFields
    }
  }
}
`

export const EditorsPicks = gql`
${pointFieldsFragment}
query HomePage {
  homePage {
    editorsPicks {
      points { ...pointFields }
      numLeadPoints
    }
  }
}
`

export const EditorsPicksPaged = gql`
${pointFieldsFragment}
query EditorsPicksPaged($cursor: String, $limit: Int) {
  editorsPicksPaged(cursor: $cursor, limit: $limit) {
    cursor
    points {
      ...pointFields
    }
    hasMore
  }
}
`

export const NewPointsOne = gql`
${pointFieldsFragment}
query HomePage {
  homePage {
    newPoints {
      ...pointFields
    }
  }
}
`

export const NewPoints = gql`
${pointFieldsFragment}
query NewPoints($cursor: String, $limit: Int) {
  newPoints(cursor: $cursor, limit: $limit) {
    cursor
    points {
      ...pointFields
    }
    hasMore
  }
}
`

export const NewPoint = gql`
${pointFieldsFragment}
mutation NewPoint($title: String!, $imageURL: String, $imageAuthor: String, $imageDescription: String, $sourceURLs: [String], $sourceNames: [String], $parentURL: String, $linkType: String) {
  newPoint(pointData: {title: $title, content: $title, summaryText: $title, imageURL: $imageURL, imageAuthor: $imageAuthor, imageDescription: $imageDescription, sourceURLs: $sourceURLs, sourceNames: $sourceNames, parentURL: $parentURL, linkType: $linkType}) {
    point { ...pointFields }
  }
}
`

export const NewQuestion = gql`
${pointFieldsFragment}
mutation NewQuestion($title: String!, $imageURL: String, $imageAuthor: String, $imageDescription: String, $sourceURLs: [String], $sourceNames: [String]) {
  newQuestion(pointData: {title: $title, content: $title, summaryText: $title, imageURL: $imageURL, imageAuthor: $imageAuthor, imageDescription: $imageDescription, sourceURLs: $sourceURLs, sourceNames: $sourceNames}) {
    point { ...pointFields }
  }
}
`

export const tagFieldsFragment = gql`
fragment tagFields on Tag {
  id,
  url,
  text,
  active,
  isCategory,
  isTop,
  description
}
`

export const GetTag = gql`
${tagFieldsFragment}
query FullClaimSearch($tagUrl: String!) {
  getTag(tagUrl: $tagUrl) {
    ...tagFields
  }
}
`

// TODO: GB: To return with paging for tags
// export const TagPoints = gql`
// ${pointFieldsFragment}
// query FullClaimSearch($tag: String!, $cursor: String, $limit: Int) {
//   tagPoints(tag: $tag, cursor: $cursor, limit: $limit) {
//     cursor
//     points {
//       ...pointFields
//     }
//     hasMore
//   }
// }`

export const TagPoints = gql`
${pointFieldsFragment}
${tagFieldsFragment}
query FullClaimSearch($tagUrl: String!) {
  tagResult(tagUrl: $tagUrl) {
    points {
      ...pointFields
    }
    tag {
      ...tagFields
    }
  }
}
`

export const AuthorPoints = gql`
${pointFieldsFragment}
query FullClaimSearch($authorUrl: String!) {
  authorResult(authorUrl: $authorUrl) {
    points { ...pointFields }
    creator { url, name }
  }
}
`

export const NeedsClarificationClaims = gql`
${pointFieldsFragment}
query NeedsClarificationClaims {
  needsClarificationClaims {
    ...pointFields
  }
}
`

export const ModeratorPage = gql`
${tagFieldsFragment}
query ModeratorPage {
  moderatorPage {
    tags {
      ...tagFields
    }
  }
}
`

export const AdminPage = gql`
query AdminPage {
  adminPage {
    success
  }
}
`

export const AdminUsersPage = gql`
${userFragment}
query AdminUsersPage($allUsers: Boolean) {
  adminUsersPage(allUsers: $allUsers)   {
    users {
      ...userFields
    }
    areas {
      name
    }
  }
}
`

export const AllTags = gql`
${tagFieldsFragment}
query ModeratorPage {
  moderatorPage {
    tags {
      ...tagFields
    }
  }
}
`

export const TopTags = gql`
${tagFieldsFragment}
query ModeratorPage {
  moderatorPage {
    tagsTop {
      ...tagFields
    }
  }
}
`

export const AddNewTag = gql`
mutation AddNewTag($id: String!) {
  addNewTag(id: $id) {
    success
  }
}
`

export const SetTagActive = gql`
mutation SetTagActive($id: String!, $active: Boolean!) {
  setTagActive(id: $id, active: $active) {
    success
  }
}
`

export const SetTagIsTop = gql`
mutation SetTagIsTop($id: String!, $isTop: Boolean!) {
  setTagIsTop(id: $id, isTop: $isTop) {
    success
  }
}
`

export const AdminTest = gql`
mutation AdminTest{
  adminTest {
    success
  }
}
`

export const AcceptTerms = gql`
mutation AcceptTerms($userUrl: String!) {
  acceptTerms(userUrl: $userUrl) {
    success
  }
}
`

export const UpdateLastVisit = gql`
mutation UpdateLastVisit {
  updateLastVisit {
    success
  }
}
`

export const SetUserFlag = gql`
mutation SetUserFlag($userUrl: String!, $flag: String!, $value: Int!) {
  setUserFlag(userUrl: $userUrl, flag: $flag, value: $value) {
    success
  }
}
`

export const SetUserNotes = gql`
mutation SetUserNotes($userUrl: String!, $userNotes: String!) {
  setUserNotes(userUrl: $userUrl, userNotes: $userNotes) {
    success
  }
}
`

export const ResetUserPassword = gql`
mutation ResetUserPassword($userUrl: String!) {
  resetUserPassword(userUrl: $userUrl) {
    success
  }
}
`

export const SetUserInternal = gql`
mutation SetUserInternal($userUrl: String!, $isInternal: Boolean!) {
  setUserInternal(userUrl: $userUrl, isInternal: $isInternal) {
    success
  }
}
`

export const SetUserPrivilege = gql`
mutation SetUserPrivilege($userUrl: String!, $userPrivilege: String!) {
  setUserPrivilege(userUrl: $userUrl, userPrivilege: $userPrivilege) {
    success
  }
}
`

export const SetUserGaid = gql`
mutation SetUserGaid($userUrl: String!, $gaid: String!) {
  setUserGaid(userUrl: $userUrl, gaid: $gaid) {
    success
  }
}
`

export const SetUserPrivateArea = gql`
mutation SetUserPrivateArea($userUrl: String!, $id: String!, $active: Boolean!) {
  setUserPrivateArea(userUrl: $userUrl, id: $id, active: $active) {
    success
  }
}
`

export const SetUserRole = gql`
mutation SetUserRole($userUrl: String!, $role: String!) {
  setUserRole(userUrl: $userUrl, role: $role) {
    success
  }
}
`

const commentFieldsFragment = gql`
fragment commentFields on Comment {
  id
  text
  richText
  parentID
  level
  date
  resolved
  userName
  userUrl
  archived
  quickCommentType
  editId
}
`

export const Comments = gql`
${commentFieldsFragment}
${aggregateCommentFieldsFragment}
query Comments($pointID: String!, $showArchived: Boolean) {
  comments(pointID: $pointID, showArchived: $showArchived) {
    comments {
      ...commentFields
    }
    aggregateComments {
      ...aggregateCommentFields
    }
    archivedCount
  }
}
`

export const RecommendedPoints = gql`
${pointFieldsFragment}
query RecommendedPoints($pointUrl: String!, $userUrl: String!) {
  recommendedPoints(pointUrl: $pointUrl, userUrl: $userUrl) {
    points {
      ...pointFields
    }
  }
}
`

export const CreatedPoints = gql`
${pointFieldsFragment}
query CreatedPoints($url: String!) {
  createdPoints(url: $url) {
    ...pointFields
  }
}
`

export const EditedPoints = gql`
${pointFieldsFragment}
query EditedPoints($url: String!) {
  editedPoints(url: $url) {
    ...pointFields
  }
}
`

export const SimilarPoints = gql`
${pointFieldsFragment}
query SimilarPoints($url: String!) {
  similarPoints(url: $url) {
    ...pointFields
  }
}
`

export const History = gql`
query History($url: String!) {
  history(url: $url) {
    point{
      version
      url
      title
      authorName
      authorURL
      dateEdited
      imageURL
      imageDescription
    }
    supportingPoints {
      url
      title
      version
    }
    counterPoints {
      url
      title
      version
    }
    answerPoints {
      url
      title
      version
    }
    sources {
      id
      url
      name
    }
  }
}
`

export const NewComment = gql`
${pointFieldsFragment}
mutation NewComment($pointID: String!, $text: String, $richText: String, $parentCommentID: String, $quickCommentType: Int) {
  newComment(commentData: {pointID: $pointID, text: $text, richText: $richText, parentCommentID: $parentCommentID, quickCommentType: $quickCommentType}) {
    point {
      ...pointFields
    }
  }
}
`

export const ArchiveComment = gql`
mutation ArchiveComment($pointID: String!, $commentID: String!) {
  archiveComment(pointID: $pointID, commentID: $commentID) {
    numArchived
  }
}
`

export const ResolveComment = gql`
mutation ResolveComment($pointID: String!, $commentID: String!) {
  resolveComment(pointID: $pointID, commentID: $commentID) {
    success
  }
}
`

export const RelevanceComments = gql`
${commentFieldsFragment}
query RelevanceComments($linkUrl: String!, $showArchived: Boolean) {
  relevanceComments(linkUrl: $linkUrl, showArchived: $showArchived) {
    comments {
      ...commentFields
    }
    archivedCount
  }
}
`

export const NewRelevanceComment = gql`
${commentFieldsFragment}
mutation NewRelevanceComment($linkUrl: String!, $text: String!, $richText: String, $parentCommentID: String, $quickCommentType: Int) {
  newRelevanceComment(commentData: {linkUrl: $linkUrl, text: $text, richText: $richText, parentCommentID: $parentCommentID, quickCommentType: $quickCommentType}) {
    comment {
      ...commentFields
    }
  }
}
`

export const ArchiveRelevanceComment = gql`
mutation ArchiveRelevanceComment($linkUrl: String!, $commentId: String!) {
  archiveRelevanceComment(linkUrl: $linkUrl, commentId: $commentId) {
    numArchived
  }
}
`

export const ProposedEditQuery = gql`
query ProposedEdit($editId: String!) {
  proposedEdit(editId: $editId) {
    id
    pointUrl
    creatorUrl
    editText
    votesFor
    votesAgainst
    accept
    complete
  }
}`

export const ProposeEdit = gql`
mutation ProposeEdit($pointUrl: String!, $text: String!, $richText: String!, $editText: String!) {
  proposeEdit(editData: {pointUrl: $pointUrl, text: $text, richText: $richText, editText: $editText}) {
    success
  }
}
`

export const ProposedEditVote = gql`
mutation ProposedEditVote($editId: String!, $userUrl: String!, $vote: Int!) {
  proposedEditVote(editId: $editId, userUrl: $userUrl, vote: $vote) {
    success
  }
}
`

export const ProposedEditAccept = gql`
mutation ProposedEditAccept($editId: String!, $userUrl: String!) {
  proposedEditAccept(editId: $editId, userUrl: $userUrl) {
    success
  }
}
`

export const ProposedEditReject = gql`
mutation ProposedEditReject($editId: String!, $userUrl: String!) {
  proposedEditReject(editId: $editId, userUrl: $userUrl) {
    success
  }
}
`

export const generatedEvidenceFields = gql`
fragment generatedEvidenceFields on GenerateEvidence {
  text, source, sourceName, sourceTitle, sourceSummary, parentUrl, evidenceType, sourceValidated, sourceError
}
`

export const GenerateEvidence = gql`
mutation GenerateEvidence($pointID: String!, $text: String!, $modelid: String!) {
  generateEvidence(pointID: $pointID, text: $text, modelid: $modelid) {
    proPoints { id, text, source, sourceName, sourceTitle, sourceSummary, parentUrl, evidenceType, sourceValidated, sourceError }
    conPoints { id, text, source, sourceName, sourceTitle, sourceSummary, parentUrl, evidenceType, sourceValidated, sourceError }
  }
}
`

export const SetClaimCandidateIrrelevant = gql`
mutation SetClaimCandidateIrrelevant($userUrl: String, $id: String!,  $irrelevant: Boolean!) {
  setClaimCandidateIrrelevant(userUrl: $userUrl, id: $id, irrelevant: $irrelevant) {
    success
  }
}
`

export const SetClaimCandidateFeedback = gql`
mutation SetClaimCandidateFeedback($userUrl: String, $id: String!,  $feedback: String!) {
  setClaimCandidateFeedback(userUrl: $userUrl, id: $id, feedback: $feedback) {
    success
  }
}
`

export const SaveSettings = gql`
${userFragment}
mutation SaveSettings($user: UserInput!) {
  saveSettings(user: $user) {
    ...userFields
  }
}
`
