import React from 'react';
import { graphql } from '@apollo/client/react/hoc';

import * as schema from '../schema';
import config from '../config';
import { PointList } from './PointList';
import Helmet from "react-helmet";

const EditorsPicksPagedList = graphql(schema.EditorsPicksPaged, {
  options: ({pointsPerPage}) => ({variables: {limit: pointsPerPage || 3}}),
  props: ({ownProps: {pointsPerPage}, data: { loading, editorsPicksPaged = {}, fetchMore}}) => ({
    loading: loading,
    points: editorsPicksPaged.points,
    hasMore: editorsPicksPaged.hasMore,
    infiniteScroll: true,
    loadMorePoints: () => {
      return fetchMore({
        query: schema.EditorsPicksPaged,
        variables: {cursor: editorsPicksPaged.cursor, limit: pointsPerPage || 3},
        updateQuery: (previousResult, { fetchMoreResult }) => {
          fetchMoreResult.editorsPicksPaged.points = [...previousResult.editorsPicksPaged.points, ...fetchMoreResult.editorsPicksPaged.points]
          return fetchMoreResult
        }
      })
    }
  })
})(PointList)

class EditorsPicksPage extends React.Component {
  render(){
    return <div>
      <Helmet>
        <title>Editors Picks -- Whysaurus</title>
        <meta property="og:title" content="Editors Picks -- Whysaurus" />
        <meta property="og:description" content="Editors Picks" />
        <link rel="canonical" href={"https://www.whysaurus.com/editorspicks/"} />
        <meta property="og:url" content={"https://www.whysaurus.com/editorspicks/"} />
      </Helmet>
      <div id="mainContainer">
        <div className="infiniteWidth">
          <h1 id="tagHeader">Editor's Picks</h1>
          <div id="infiniteOrFiniteWidth" className="">
            <EditorsPicksPagedList pointsPerPage={config.newPointsPageSize}/>
          </div>
        </div>
      </div>
    </div>
  }
}

export default EditorsPicksPage