import React from 'react'
import PropTypes from 'prop-types'
import Form from './formNew';

import * as validations from '../validations';
// import TitleText from './TitleText'
import TitleText from './TitleTextNew';
import { CloseLinkX } from './common'
import {HashLink} from "react-router-hash-link";


class AddEvidenceForm extends React.Component {

  static propTypes = {
    point: PropTypes.object.isRequired,
    evidenceType: PropTypes.string.isRequired,
    addExistingClaim: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  }

  // TODO: add multiple options for each type and randomize to give it some fun magic!
  generatePlaceholderText(evidenceType) {
    let placeholderSupport = `Dogs can bring you tasty drinks in little barrels around their necks.`
    let placeholderCounter= `Cat gifs are humanity's greatest artistic achievement.`
    if (this.props.evidenceType === "counter") {
        return placeholderCounter
    } else {
        return placeholderSupport
    }
  }

  formHeaderText(evidenceType) {
    if (evidenceType === "answer") {
      return "Add Answer"
    } else if (evidenceType === "supporting") {
      return "Add Evidence For"
    } else if (evidenceType === "counter") {
      return "Add Evidence Against"
    } else {
      return "Add Evidence"
    }
  }

  // [There is bug around validationFailures - its returning 0 when it shouldn't be
  //   for now, adding the condition (title.length > validations.titleMaxCharacterCount) to make the button disable when it should
  //   but really we should fix the bug
  //   see PR here: https://github.com/aaronlifshin/whysaurus/pull/82#pullrequestreview-111369929]
  // UPDATE 10/5/18: This is not a bug. When a validation simply returns a string (as all the functions in validations.js are
  //   currently doing), it is treated as an error, not a validation failure.
  // Using onMouseDown for the buttons at the bottom, so they can be clicked before blur removes classes .titleTextField:active and
  //   .titleTextField:focus and physically moves the buttons up out of reach of the onClick event (which always occurs afterwards).
  render(){
    let submitClasses = `buttonUX2 createClaimFormButton ${this.props.evidenceType==="counter" && "buttonUX2Red" }`
    let titleTextClasses = `inputFieldUX2 titleTextField ${this.props.evidenceType==="counter" && "counter" }`
    let formHeading = this.formHeaderText(this.props.evidenceType)
    let {point, evidenceType, addExistingClaim} = this.props

    return (
      <Form
        onSubmit={this.props.onSubmit}
        className="addEvidenceForm"
        validationMode="onSubmit"
      >
        {({ submitForm, errors, values }) => {
          const hasErrors = Object.keys(errors).length > 0;

          return (
            <>
              <span className="addEvidenceAreaHeading">
                <span className="addEvidenceAreaHeadingText">{formHeading}</span>
                <span className="editAreaClose"><a onClick={this.props.onCancel}><CloseLinkX/></a></span>
              </span>
              <label className="addEvidenceAreaNote">Make a point. Refer to our <HashLink to="/faq#GoalsForGoodClaims">goals for good points</HashLink>.</label>
              <div className="claimCreationFormFieldBlock">
                <TitleText
                  name="title"
                  suggestExistingClaims={true}
                  point={point}
                  evidenceType={evidenceType}
                  addExistingClaim={addExistingClaim}
                  onSubmit={submitForm}
                  className={titleTextClasses}
                  autoComplete='off'
                  autoFocus={true}
                  placeholder={this.generatePlaceholderText(evidenceType)}
                  validate={{
                    validate: validations.validateTitle,
                    required: true,
                  }}
                />
                <div name="addSourceArea" className="addSourceArea">
                  <span className="addEvidenceAreaHeading">
                    <span className="heading">Optional</span>
                    <label className="addEvidenceAreaNote">Add a Source — link to where this point was found</label>
                  </span>
                  <Form.Text
                    name="sourceUrl"
                    className="inputFieldUX2"
                    placeholder="URL"
                    validate={{
                      validate: (sourceUrl) => validations.validateSourceForEvidence(sourceUrl, values.sourceName),
                    }}
                  />
                  {/* {errors.sourceUrl && <div className="error">{errors.sourceUrl.message}</div>} */}
                  <Form.Text name="sourceName" className="inputFieldUX2" placeholder="Title"/>
                </div>
              </div>
              <div className="claimCreationFormButtonBlock">
                <button type="submit" onMouseDown={submitForm} className={submitClasses} disabled={hasErrors}>Add</button>
                <button type="cancel" onMouseDown={this.props.onCancel} className="cancelButton">Cancel</button>
              </div>
            </>
          );
        }}
      </Form>
    )
/*
    return <Form onSubmit={this.props.onSubmit}
                 validate={values => ({title: validations.validateTitle(values.title),
                                       sourceUrl: validations.validateSourceForEvidence(values.sourceUrl, values.sourceName)})}>
      { ({submitForm, values: {title, sourceUrl, sourceName}, validationFailures, errors}) => (
        <form onSubmit={submitForm} className="addEvidenceForm">
          <span className="addEvidenceAreaHeading">
            <span className="addEvidenceAreaHeadingText">{formHeading}</span>
            <span className="editAreaClose"><a onClick={this.props.onCancel}><CloseLinkX/></a></span>
          </span>
          <div className="addEvidenceAreaNote">Make a claim</div>
          <div className="claimCreationFormFieldBlock">
            <TitleText id="title" className={titleTextClasses}
                     autoComplete='off'
                     autoFocus={true}
                     placeholder={this.generatePlaceholderText(evidenceType)}
                     suggestExistingClaims={true}
                     point={point}
                     evidenceType={evidenceType}
                     addExistingClaim={addExistingClaim}
                     onSubmit={submitForm}/>
            <div name="addSourceArea" className="addSourceArea">
              <span className="addEvidenceAreaHeading">
                <span className="heading">Optional</span>
                <div className="addEvidenceAreaNote">Add a Source to your new claim</div>
              </span>
              <Text field="sourceUrl" className="inputFieldUX2" placeholder="URL"/>
              {errors && errors.sourceUrl && <div className="error">{errors.sourceUrl}</div>}
              <Text field="sourceName" className="inputFieldUX2" placeholder="Title"/>
            </div>
          </div>
          <div className="claimCreationFormButtonBlock">
            <button type="submit" onMouseDown={submitForm} className={submitClasses} disabled={!title || title === "" || errors || validationFailures > 0}>Add</button>
            <button type="cancel" onMouseDown={this.props.onCancel} className="cancelButton cancelButtonAddEvidence">Cancel</button>
          </div>
        </form>
      )}
    </Form>
    */
  }
}

export default AddEvidenceForm;
