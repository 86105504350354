import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import * as schema from '../schema'
// import * as ga from '../ga'
import LoginDialog from './LoginDialog'
import SignupDialog from './SignupDialog'
import {Modal} from 'react-bootstrap';
import {gtmEvent, gtmSetUserid} from "../gtm";


const {Consumer, Provider} = React.createContext({
  showLoginDialog: () => {},
  showSignupDialog: () => {},
  hideAuthDialog: () => {},
  isLoginDialogVisible: false,
  currentUser: null
});

export class AuthorizationComponent extends React.Component {
  state = {
      isLoginDialogVisible: false,
  }

  showLoginDialog = () => {
      this.setState({
          authDialog: 'login',
          isLoginDialogVisible: true,
      })
  }
  showSignupDialog = () => {
      this.setState({
          authDialog: 'signup',
          isLoginDialogVisible: true,
      })

      gtmEvent('Login', 'With Whysaurus', 'Dialog')
  }
  hideAuthDialog = () => this.setState({authDialog: null, isLoginDialogVisible: false})

  render() {
    const {currentUser, refetchUser, userLoading} = this.props;
    return (
      <Provider value={{ currentUser,
                         refetchUser,
                         userLoading,
                         isLoginDialogVisible: this.state.isLoginDialogVisible,
                         showLoginDialog: this.showLoginDialog,
                         showSignupDialog: this.showSignupDialog,
                         hideAuthDialog: this.hideAuthDialog}}>
        {this.props.children}
        <Modal id="loginDialog"
               show={this.state.authDialog === "login"}
               onHide={this.hideAuthDialog}>
          <LoginDialog showSignup={this.showSignupDialog}
                       close={this.hideAuthDialog}/>
        </Modal>
        <Modal id="signupDialog"
               show={this.state.authDialog === "signup"}
               onHide={this.hideAuthDialog}>
          <SignupDialog showLogin={this.showLoginDialog}
                        close={this.hideAuthDialog}/>
        </Modal>
      </Provider>
    )
  }
}


export default graphql(schema.CurrentUserQuery, {
  options: () => ({
    onCompleted: (data) => {
      // TODO: Gene: Warning for missing data
      if (data && data.currentUser) {
        let gaId = data.currentUser.gaId;
        if (gaId) {
          // console.log("GTM Init: " + gaId);
          gtmSetUserid(gaId)
        } else {
          console.log("No gaId for user gtm init")
        }

        console.log('currentUser: ' + data.currentUser.url)
      } else {
          console.log('currentUser: Nothing returned')
      }
    },
  }),
  props: ({data: {loading, currentUser, refetch}}) => ({
    currentUser,
    userLoading: loading,
    refetchUser: refetch,
  }),
})(AuthorizationComponent)


export const withAuth = (Comp) => (props) => (
  <Consumer>
    {(authProps) => <Comp {...authProps} {...props}/>}
  </Consumer>
)
