import React, { Fragment } from 'react'
import Form from './formNew'

import { graphql } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash';
import { withAuth } from './Authorization'
import * as schema from '../schema'
import { withYsAlerts } from './../AlertProvider'
import { PrimaryButton } from './buttons'
import Spinner from "./Spinner";

const NotificationFrequencyRadio = ({field, label}) => (
  <span className="radioGroup">
    {/*<RadioGroup label={label} field={`notificationFrequency${field}`}>*/}
    {/*  <Radio label="Real Time" value="Realtime"/>*/}
    {/*  <Radio label="Daily" value="Daily"/>*/}
    {/*  <Radio label="Weekly" value="Weekly"/>*/}
    {/*  <Radio label="Never" value="Never"/>*/}
    {/*  <Radio label="Default" value="Default"/>*/}
    {/*</RadioGroup>*/}
    <label>{label}</label>
    <div className="radioOptions">
      <div className="radioOptionBlock"><Form.Radio name={`notificationFrequency${field}`} value="Realtime" label="Real Time" /></div>
      <div className="radioOptionBlock"><Form.Radio name={`notificationFrequency${field}`} value="Daily" label="Daily" /></div>
      <div className="radioOptionBlock"><Form.Radio name={`notificationFrequency${field}`} value="Weekly" label="Weekly" /></div>
      <div className="radioOptionBlock"><Form.Radio name={`notificationFrequency${field}`} value="Never" label="Never" /></div>
      <div className="radioOptionBlock noRightMargin"><Form.Radio name={`notificationFrequency${field}`} value="Default" label="Default" /></div>
    </div>
  </span>
)

const SettingsPage = props => {
    const { saveSettings, userLoading, currentUser, alert } = props;
    if (userLoading) {
        return <Spinner />
    }
    else if (currentUser) {
      const {
        email, websiteURL, bio,
        notificationFrequencyAgreeDisagree,
        notificationFrequencyAchievements,
        notificationFrequencyCounterPoint,
        notificationFrequencyComments,
        notificationFrequencyMentions,
        notificationFrequencyEdits
      } = currentUser
      const handleValues = (data) => {
          console.log(data)
          return saveSettings(data);
      };
      return (
        <Fragment>
            <div id="mainContainer">
              <div id="settingsPage" className="fullPageForm">
                <h1 className="fullPageFormHeading">Edit Settings</h1>
                <a href="/changePassword" id="changePasswordLink">Change Password</a>
                <Form className="settingsForm"
                      onSubmit={(a) => handleValues(a).
                              then(() => alert.info("Saved!")).
                              catch(({graphQLErrors}) => {
                                  console.log(graphQLErrors);
                                  alert.error(graphQLErrors);
                              })}
                      defaultValues={{
                          email,
                          websiteURL,
                          bio,
                          notificationFrequencyAgreeDisagree,
                          notificationFrequencyAchievements,
                          notificationFrequencyCounterPoint,
                          notificationFrequencyComments,
                          notificationFrequencyEdits,
                          notificationFrequencyMentions
                      }}
                >
                    {() => {
                        return <>
                          <Form.Text name="email" label="Email" />
                          <Form.Text name="websiteURL" label="Website" placeholder="http://" />
                          <Form.Text name="bio" label="Bio" placeholder="Say something about yourself"/>
                          <h2>Notification Emails</h2>
                          <span className="notificationFrequencyRadios">
                            <NotificationFrequencyRadio label="Agrees" field="AgreeDisagree" />
                            <NotificationFrequencyRadio label="Achievements" field="Achievements" />
                            <NotificationFrequencyRadio label="New Evidence" field="CounterPoint" />
                            <NotificationFrequencyRadio label="Comments" field="Comments" />
                            <NotificationFrequencyRadio label="Mentions" field="Mentions" />
                            <NotificationFrequencyRadio label="Edits" field="Edits" />
                          </span>
                          <div>
                            <PrimaryButton type="submit">Save Settings</PrimaryButton>
                          </div>
                        </>
                    }}
                </Form>
            </div>
          </div>
        </Fragment>
      )
    } else {
        return <div>Please <a href="/Login">Login</a></div>
    }
}

export default compose(
  withAuth,
  withYsAlerts,
  graphql(schema.SaveSettings, {
    props: ({mutate}) => ({
      saveSettings: (user) => mutate({
        variables: {user}
      })
    })
  })
)(SettingsPage)
