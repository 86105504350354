import { convertFromRaw } from 'draft-js';
import {raw} from "@storybook/react";

export const getTextFromRawContent = (rawContent) => {
  if (!rawContent || Object.entries(rawContent).length === 0) {
    return ""
  }
  const contentState = convertFromRaw(rawContent);
  return contentState.getPlainText();
};

export const mapTextFromValues =
  (values, richTextField, targetTextField, targetRichTextField) => {
    const rawContent = values[richTextField]
    // let payload = {
    //   ...values,
    // }
    if (targetTextField) {
      values[targetTextField] = getTextFromRawContent(rawContent)
    }
    if (targetRichTextField) {
      values[targetRichTextField] = JSON.stringify(rawContent)
    }
    return values
  }