import React, { Component } from "react";
import { graphql } from '@apollo/client/react/hoc';
import * as schema from "../schema";
import Form from "./formNew";
import { Modal } from "react-bootstrap";
import Spinner from "./Spinner";
import { gtmEvent } from "../gtm";
import FontAwesomeIcon from "./FontAwesomeIcon";

class SignupDialog extends Component {
  state = {};

  signup = (values) => {
    const { website, signupCode, ...payload } = values;
    this.setState({ submitting: true });
    this.props.signup(payload).then(() => this.setState({ submitting: false }));
  };

  toggleShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  render() {
    const { close, showLogin } = this.props;
    const passwordType = this.state.showPassword ? "text" : "password";

    return (
      <Form
        onSubmit={this.signup}
        id="frm_signupDialog"
        className="form-horizontal"
      >
        {() => {
          return (
            <>
              <Modal.Header>
                <div className="modalHeadlineCopy">New Account</div>
                <span className="editAreaClose modalCloseX">
                  <a onClick={close}>×</a>
                </span>
              </Modal.Header>
              <div className="row-fluid" id="alertArea"></div>
              <Modal.Body>
                <div className="control-group">
                  <label className="" htmlFor="username">
                    Username
                  </label>
                  <div className="controls">
                    <Form.Text
                      id="signup_userName"
                      name="username"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="control-group">
                  <label className="" htmlFor="email">
                    Email Address
                  </label>
                  <div className="controls">
                    <Form.Text
                      id="signup_userEmail"
                      name="email"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="control-group">
                  <label className="" htmlFor="password">
                    Password
                  </label>
                  <div className="controls">
                    <Form.Text
                      id="signup_password1"
                      name="password"
                      type={passwordType}
                      placeholder="8+ characters with a letter and a number"
                      icon={
                        <FontAwesomeIcon
                          name={this.state.showPassword ? "eye" : "eye-slash"}
                          onClick={this.toggleShowPassword}
                          style={{ cursor: "pointer" }}
                        />
                      }
                    />
                  </div>
                </div>

                <p className="heading extraMarginTop">Optional</p>
                <div className="control-group">
                  <label className="" htmlFor="website">
                    Website
                  </label>
                  <div className="controls">
                    <Form.Text
                      id="signup_userWebsite"
                      name="website"
                      placeholder="http://"
                    />
                  </div>
                </div>
                <div className="control-group">
                  <label className="" htmlFor="bio">
                    Bio
                  </label>
                  <div className="controls">
                    <Form.TextArea
                      id="signup_userBio"
                      className="inputFieldUX2"
                      name="bio"
                      rows="3"
                      placeholder="Say something about yourself"
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {this.state.submitting ? (
                  <div id="signUpSpinner"><Spinner /></div>
                ) : (
                <button
                  type="submit"
                  id="submit_signupDialog"
                  className="buttonUX2"
                  onClick={() => gtmEvent("Login", "Create Whysaurus User")}
                >
                  Create User
                </button>

                )}
                 <div className="backToLoginArea">
                  <a onClick={showLogin} id="backToLogin">
                    Got an account? Click here to sign in
                  </a>
                </div>
              </Modal.Footer>
            </>
          );
        }}
      </Form>
    );
  }
}

export default graphql(schema.SignUp, {
  props: (p) => ({
    signupData: p,
    signup: ({
      email,
      username,
      password,
      website,
      bio,
      signupCode,
    }) =>
      p
        .mutate({
          variables: {
            user: {
              email,
              username,
              password,
              website,
              bio,
              signupCode,
            },
          },
        })
        .then(() => {
          window.location.reload();
        }),
  }),
})(SignupDialog);
