import React, {useState} from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash';
import {Link, withRouter} from "react-router-dom";
import * as schema from '../schema'
import { useForm } from "react-hook-form";
import {withYsAlerts} from "../AlertProvider";
import Spinner from "./Spinner";

const ChangePasswordPage = props => {
  const { changePassword, handleErrors, showInfo } = props;
  const { register, handleSubmit, watch, errors } = useForm();
  const [submitted, setSubmitted] = useState(false)

  const onChangePassword = (values) => {
    setSubmitted(true)
    return changePassword(values)
      .then(() => {
        showInfo("Your password has been changed.")
        setSubmitted(false)
        window.location = "/passwordChanged"
      })
      .catch(({graphQLErrors}) => {
        setSubmitted(false)
        handleErrors(graphQLErrors)
      })
  }

  const validatePassword = (password) => {
    if (!password) {
      return "Password is required."
    } else if (password.length < 8) {
      return "Password must be at least 8 characters long."
    } else if (!password.match(/[a-zA-Z]/)) {
      return "Password must contain at least one letter."
    } else if (!password.match(/[0-9]/)) {
      return "Password must contain at least one number."
    }
  }

  return (
      <div id="mainContainer">
        <div className="fullPageForm">
          <form id="changePasswordForm" className="settingsForm" onSubmit={handleSubmit(onChangePassword)}>
            <div >
              <div className="formInstruction">Reset your password. Or return to <a href="/settings">Settings</a>.</div>
              <div className="formInstruction">Please make your password at least 8 characters long, with both a letter and a number</div>
            </div>
            <div className="">
              <div>
                <label>New Password:</label>
                <input type="password" id="password1" name="password" ref={register({validate: validatePassword})} />
                {errors.password && <p>{errors.password.message}</p>}
                <div>
                  <label>Confirm Password:</label>
                  <input type="password" id="password2" name="password2" ref={register({required: true,
                    validate: (value) => value === watch('password') ?? "Oops! Your passwords do not match."
                  })} />
                </div>
                {errors.password2 && <p>{errors.password2.message}</p>}
              </div>
              <div >
                <input id="submit_changePasswordButton" className="buttonUX2 buttonLower" type="submit" value="Change password" />
                {submitted && <Spinner />}
              </div>
            </div>
          </form>
        </div>
      </div>
  )
}

export default compose(
  withYsAlerts,
  withRouter,
  graphql(schema.ChangePassword, {
    props: ({mutate}) => ({
      changePassword: ({password, token}) => mutate({
        variables: {password, token}
      })
    })
  })
)(ChangePasswordPage)