import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import * as schema from '../schema';
import { withAuth } from './Authorization'
import {withYsAlerts} from "../AlertProvider";

class ClaimSuggestor extends React.Component {
  static propTypes = {
    point: PropTypes.object,
    evidenceType: PropTypes.string,
    render: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.invalidEvidenceURLs = new Set([props.point.url, ...this.evidence().map(claim => claim.url)])
  }

  evidence = () => {
    let connections = this.props.point[this.props.evidenceType === "supporting" ? "supportingPoints" : "counterPoints"]
    return connections ? connections.edges.map(edge => edge.node) : []
  }

  filterEvidenceCandidates = (claims) =>
    claims && claims.filter(claim => claim && !this.invalidEvidenceURLs.has(claim.url))

  suggestions = () => {
    const {currentUser, query, searchResults, searching} = this.props
    if (query && (query !== '')){
      if (!searching) {
        return this.filterEvidenceCandidates(searchResults)
      }
    } else if (currentUser) {
      return this.filterEvidenceCandidates(currentUser.recentlyViewed)
    }
  }

  render(){
    const {searching} = this.props.data || {}
    return (
      this.props.render({suggestions: this.suggestions(), searching: searching})
    )
  }
}

const propsMap = (props) => {
  const {error, loading, search} = props.data
  if (error) {
    props.handleError(error)
  }
  return {
    searching: loading,
    searchResults: search,
  }
}

export default compose(
  withAuth,
  withYsAlerts,
  graphql(schema.Search, {
    skip: (ownProps) => !ownProps.query || (ownProps.query === ''),
    props: propsMap
  })
)(ClaimSuggestor)
