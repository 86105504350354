import React from 'react'
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import Form from './formNew';

import { CloseLinkX } from './common'
import * as validations from '../validations';
import * as schema from '../schema';
import FontAwesomeIcon from './FontAwesomeIcon';

class EditSourceComponent extends React.Component {

  state = {saving: false}

  deleteSource = (id) => {
    this.setState({saving: true})
    this.props.delete(this.props.point.id, id)
  }

  render() {
    if (this.state.saving) {
      return "saving..."
    } else {
      const {id, url, name} = this.props.source
      return <div>
          <a className="source" tabIndex="-1" target="_blank" rel="noopener noreferrer" href={url}>
            <span className="iconSourcesSmall">
              <FontAwesomeIcon name="book-open" />
            </span>
            <span className="sourceLabel">{name || url}</span>
          </a>
         <span className="removeSourceLink easierToClickOn">
          <a onClick={() => this.deleteSource(id)}>
            <FontAwesomeIcon name="trash-alt" type="far" />
          </a>
        </span>
        </div>
    }
  }
}

const EditSource = compose(
  graphql(schema.DeleteSource, {
    props: ({mutate}) => ({
      delete: (pointID, id) => mutate({
        variables: {pointID, id}
      })
    })
  }),
)(EditSourceComponent)


class EditSourcesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  state = {saving: false}

  onSavedSource = () => {
    this.setState({saving: false});
    this.formRef.current && this.formRef.current.reset();
  }

  onSubmit = ({ name, url }) => {
    this.setState({saving: true})
    this.props.add(this.props.point && this.props.point.id, name, url).then(this.onSavedSource)
  }

  render(){
    const sources = this.props.point && this.props.point.sources
    let editSourcesLabel = `${sources ? "Edit Sources" : "Add Sources"}`
    return <div className="row-fluid claimEditArea editSources ">
      <span className="claimEditAreaHeading">
        <span className="heading">{editSourcesLabel}</span>
        <span className="editAreaClose"><a onClick={this.props.onCancel}><CloseLinkX/></a></span>
      </span>
      <div className="claimEditAreaNote">Link to the document where this point was found. </div>
      <div className="editSourcesList">
        {sources && sources.map((source, i) => <EditSource key={i} point={this.props.point} source={source} />)}
      </div>
      <Form onSubmit={this.onSubmit} validationMode="onChange" ref={this.formRef}>
        {() => {
          return (
            <>
              <Form.Text
                name="url"
                className="inputFieldUX2"
                placeholder="URL"
                validate={{
                  validate: validations.validateSourceURL,
                }}
              />
              <Form.Text
                name="name"
                className="inputFieldUX2"
                placeholder="Title"
                validate={{
                  validate: validations.validateSourceName,
                }}
              />
              {
                this.state.saving
                  ? "saving..."
                  : (
                    <button className="buttonUX2 pull-right" type="submit">
                      Add Source
                    </button>
                  )
              }
            </>
          )
        }}
      </Form>
      <div className="hotTip">Tip! If the source provides evidence, add it as supporting points to make this argument stronger.</div>
    </div>
  }
}

export default compose(
  graphql(schema.AddSource, {
    props: ({mutate}) => ({
      add: (pointID, name, url) => mutate({
        variables: {pointID, name, url}
      })
    })
  })
)(EditSourcesComponent)
