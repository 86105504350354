import React from 'react'
import * as validations from '../validations';
import Form from './formNew';
import * as schema from '../schema';
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import TitleText from './TitleTextNew'
import Spinner from './Spinner'
import { CloseLinkX } from './common'
import { gtmEvent } from '../gtm'
import {fbq} from "../fb";
import {withYsAlerts} from "../AlertProvider";

const EditTitleForm = ({
  point,
  onSubmit,
  onClick,
  heightClass,
}) => {
  const titleTextClasses = `inputFieldUX2 titleTextField ${heightClass}`;

  return (
    <Form
      defaultValues={{ title: point.title }}
      onSubmit={onSubmit}
      validationMode="onChange"
      className="editPointTextForm"
    >
      {({ submitForm, errors }) => {
        const hasErrors = Object.keys(errors).length > 0;

        return (
          <>
            <div className="claimCreationFormFieldBlock">
              <TitleText
                onClick={onClick}
                name="title"
                className={titleTextClasses}
                onSubmit={submitForm}
                validate={{
                  validate: validations.validateTitle,
                }}
                id="editPointTextField"
              />
            </div>
              <button
                onClick={onClick}
                disabled={hasErrors}
                className="buttonUX2 editClaimFormButton pull-right"
                type="submit"
              >
                Save
              </button>

          </>
        );
      }}
    </Form>
  );
};


class EditPointComponent extends React.Component {
  state = {saving: false}

  get point() {
    return this.props.point;
  }

  // TODO: add number of agree/disagree voters, but return 1 if the only author is also the only voter
  get respondants() {
    return this.props.point.numUsersContributed + 1
  }

  get note() {
    if (this.respondants === 1)
      return <span></span>
    else
      return <span></span>
  }

  handleClickNoProp = (e) => {
    e.stopPropagation();
  }

  handleClickSave = (values) => {
    values.url = this.point.url
    this.setState({saving: true})
    gtmEvent('Point', 'Edit Point', 'Point Dialog Edit');
    gtmEvent('Point Dialog', 'Publish to Library', 'Edit');
    fbq('trackCustom', 'EditPoint', {
      url: this.point.url,
    });
    this.props.save(values).then(null, (err) => {
      this.setState({saving: false})
      this.props.handleError(err)
    })
  }

  // TODO: this algorithm is a sketch, might not be ideal
  getEditTextFormHeightClass(title){
    if (title.length > 160 )
      return "sixLines";
    if (title.length > 130 )
      return "fiveLines";
    if (title.length > 100 )
      return "fourLines";
    if (title.length > 65 )
      return "threeLines";
    else
      return "twoLines";
  }

  render(){
    let isQuestion = this.point && this.point.pointType === 2;
    let editClaimTextClasses = `claimEditArea editClaimText`;
    let label = `${isQuestion ? "Edit Question" : "Edit Point"}`
    if (this.state.saving) {
      return <div className={editClaimTextClasses}>
        <span className="claimEditAreaSavingFeedback"><Spinner /><span className="spinnerLabel">Saving...</span></span>
      </div>;
    } else {
      return <div className={editClaimTextClasses}>
        <span className="claimEditAreaHeading">
          <span className="heading">{label}</span>
          <span className="editAreaClose"><a onClick={this.props.onCancel} href="/#"><CloseLinkX/></a></span>
        </span>
        { this.respondants > 1 && <div className="claimEditAreaNote">
          <p>Clarify and fix typos. <span className="number">{this.respondants}</span> community members have responded to this, so preserve its intent. If you want to say something different, make a new point!</p>
        </div>
        }
        <EditTitleForm onClick={this.handleClickNoProp} onSubmit={this.handleClickSave} point={this.point} countedValue={this.point.title} heightClass={this.getEditTextFormHeightClass(this.point.title)}/>


      </div>;
    }
  }
}

export default compose(
  withYsAlerts,
  graphql(schema.EditPointQuery, {
    props: ({mutate}) => ({
      save: (values) => mutate({
        variables: values
      })
    })
  }),
)(EditPointComponent)
