/* eslint-disable no-undef */
import React, { Component, useEffect } from 'react';
import {useAlert} from 'react-alert'

export const withReactAlerts = (WrappedComponent, timeout) => (
  class extends Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }

    componentDidCatch = (error, errorInfo) => catchFunc(error, errorInfo, this);

    render() {
      if (this.state.errorInfo) {
        return <HandleError ctx={this} timeout={timeout} />
      }
      // Normally, just render children
      return <WrappedComponent {...this.props} />;
    }
  }
);

const catchFunc = (error, errorInfo, ctx) => {
  // catch errors in any components below and re-render with error message
  ctx.setState({error, errorInfo})
  // log error messages, etc.
}

const HandleError = ({ctx, timeout = 5000}) => {
  const alert = useAlert();
  const error = ctx.state.error;

  const newAlert = () => alert.error(error.message, {
    timeout,
    onOpen: () => {
      // callback that will be executed after this alert open
    }, 
    onClose: () => {
      // callback that will be executed after this alert is removed
    } 
  })

  useEffect(() => {
    if(error){
      newAlert()
    }
    // {ctx.state.errorInfo.componentStack}
  }, [error]);

  if (typeof errorHandler == 'undefined') {
    console.error('Global errorHandler unavailable.  Please report this error to Whysaurus.')
  } else {
    errorHandler.report(error)
  }

  return null;
};
