import React, { useState, useEffect, useRef } from 'react'
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
// import { Form, Text } from './form';
import { useForm } from 'react-hook-form';

import { CloseLinkX } from './common'
import * as validations from '../validations';
import * as schema from '../schema';

class EditTagComponent extends React.Component {

  state = {saving: false}

  deleteTag = (id) => {
    this.setState({saving: true})
    console.log('Deleting Tag: ' + id + ' Point: ' + this.props.point.id)
    this.props.delete(this.props.point.id, id)
  }

  render() {
    if (this.state.saving) {
      return "saving..."
    } else {
      const {id, url, text} = this.props.tag
      return <div>
          <a className="tag" tabIndex="-1" target="_blank" href={url} rel="noreferrer">
            <span className="tagLabel">#{text || url}</span>
          </a>
          <a className="removeTagLink easierToClickOn" onClick={() => this.deleteTag(id)}><CloseLinkX/></a>
        </div>
    }
  }
}

const EditTag = compose(
  graphql(schema.DeleteTag, {
    props: ({mutate}) => ({
      delete: (pointID, id) => mutate({
        variables: {pointID, id}
      })
    })
  }),
)(EditTagComponent)




const EditTagsComponent = ({
  point,
  onCancel,
  add,
}) => {
  const [saving, setSaving] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const tagInputRef = useRef();

  const { register, handleSubmit, errors } = useForm();

  const { tags } = point;
  let editTagsLabel = `${tags ? "Edit Tags" : "Add Tags"}`

  const onSubmit = ({ tagId }) => {
    setSaving(true);
    setErrorMsg(null);
    add(point.id, tagId)
      .then(() => setSaving(false))
      .catch(e => {
        setSaving(false);
        setErrorMsg(e.message);
      });
  };

  useEffect(() => {
    tagInputRef.current && tagInputRef.current.focus();
  }, []);

  return <div className="row-fluid claimEditArea editTags ">
    <span className="claimEditAreaHeading">
      <span className="heading">{editTagsLabel}</span>
      <span className="editAreaClose"><a onClick={onCancel}><CloseLinkX/></a></span>
    </span>
    <div className="claimEditAreaNote">
      New tags can only be created by moderators, for now. Tags are case-sensitive. <a tabIndex="-1" href="/moderator">Click here to view or edit our list of tags.</a>
    </div>
    <div className="editTagsList">
      {tags && tags.map((tag, i) => <EditTag key={i} point={point} tag={tag}/>)}
    </div>
    {/*<Form onSubmit={this.onSubmit}>*/}
    {/*  {({errors, submitForm}) => (*/}
    {/*    <Fragment>*/}
    {/*      <Text field="tagId" className="inputFieldUX2 inputFieldUX2multi" placeholder="tag2"*/}
    {/*            ref={this.tagInput}*/}
    {/*            validate={id => validations.validateTagId(id)}*/}
    {/*      />*/}
    {/*      {errors && errors.tagId && <div className="error">{errors.tagId}</div>}*/}
    {/*      {this.state.errorMsg && <div className="error">{this.state.errorMsg}</div>}*/}
    {/*      {this.state.saving ? "saving..." : <button disabled={false} className="buttonUX2 pull-right" type="submit">Add Tag</button>}*/}
    {/*    </Fragment>*/}
    {/*  )}*/}
    {/*</Form>*/}
    <form onSubmit={handleSubmit(onSubmit)}>
      <input
        type="text"
        name="tagId"
        className="inputFieldUX2"
        placeholder="tag"
        ref={(el) => {
          tagInputRef.current = el;
          register(el, {
            validate: validations.validateTagId,
          });
        }}
      />
      {errors.tagId && <div className="error">{errors.tagId.message}</div>}
      {saving
        ? "saving..."
        : <button className="buttonUX2 pull-right" type="submit">Add Tag</button>
      }
    </form>
    {/* <Form onSubmit={this.onSubmit}
          validate={values => ({tagInput: validations.validateTagId(values.tagInput)})}>
    {({errors, submitForm}) => <form onSubmit={submitForm}
          id = "tagEditForm"
          ref={form => this.refForm = form}>
        <Text field="tagId" className="inputFieldUX2 inputFieldUX2multi" placeholder="tag" />
        {errors && errors.tagId && <div className="error">{errors.tagId}</div>}
        {this.state.saving ? "saving..." : <button disabled={false} className="buttonUX2 pull-right" type="submit">Add Tag</button>}
      </form>}
    </Form> */}
  </div>
}

export default compose(
  graphql(schema.AddTag, {
    props: ({mutate}) => ({
      add: (pointID, tagUrl) => mutate({
        variables: {pointID, tagUrl}
      })
    })
  })
)(EditTagsComponent)
