import React from 'react'
import {Provider, useAlert} from 'react-alert'
import { CloseLinkX } from './components/common'


// TODO: can the flex:2 be removed?
const AlertTemplate = ({ message, style, close }) => (
  <div className="react-alert" style={{ ...style }}>
    <span className="react-alert-msg" style={{flex: 2}}>{message}</span>
    <button onClick={close} className="alertCloseButton">
      <CloseLinkX/>
    </button>
  </div>
)

const alertOptions = {
  position: 'bottom center',
  timeout: 20000, // 5000
  transition: 'fade',
  offset: '0px'
}

export default ({children}) => (
  <Provider template={AlertTemplate} {...alertOptions}>
    {children}
  </Provider>
)

// export const withYsAlerts = (Component) => {
//   const alert = useAlert()
//   return (props) => {
//     return <Component alert={alert} {...props} />;
//   };
// };

const toErrorMessage = (error) => {
  if (!error) {
    return "NULL Error"
  }
  console.error(error)
  if (error.message) {
    return error.message
  }
  console.warn('toErrorMessage: unknown: ' + error)
  return error.toString()
}

export function withYsAlerts(Component) {
  return function WrappedComponent(props) {
    const alert = useAlert();
    const showInfo = (msg) => alert.info(msg)
    const showWarning = (msg) => alert.error(msg)  // TODO: Style warning differently?
    const showError = (msg) => alert.error(msg)
    const handleError = (error) => alert.error(toErrorMessage(error))
    const handleErrors = (errors) => { for (const err of errors) { handleError(err) } }
    return <Component {...props} alert={alert}
      showInfo={showInfo}
      showWarning={showWarning}
      showError={showError}
      handleError={handleError}
      handleErrors={handleErrors}
    />;
  }
}
