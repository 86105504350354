import React, { useState, useEffect, useFormContext } from 'react'
import { PointListWithPoint } from './PointList';
import Spinner from './Spinner'
import {flowRight as compose} from "lodash";
import {withAuth} from "./Authorization";
import { withYsAlerts } from '../AlertProvider';
import {graphql} from '@apollo/client/react/hoc';
import * as schema from "../schema";
import PropTypes from 'prop-types'
import { withExpandedIndex } from './ExpandedIndex';
import {gtmEvent} from '../gtm'
import {fbq} from "../fb";
import { CloseLinkX } from './common'


// TODO: Gene: Desperately needs to be cleaned up - replaced the older form component here, should be streamlined and validated/error handling
const EditTextForm = ({
    text,
    onSubmit,
    // onClick,
    heightClass,
}) => {
    const titleTextClasses = `inputFieldUX2 titleTextField ${heightClass}`;
    const [title, setTitle] = useState(text);

    useEffect(() => {
        
    });

    const handleSubmit = (event) => {
        event.preventDefault();

        console.log("EditTextForm Updated: " + title)
        onSubmit({ title })
    }

    return (
        <form onSubmit={handleSubmit} className="editPointTextForm">
            <div className="claimTitleField">
                <span>
                    <textarea
                        name='title'
                        value={title}
                        onChange={(e) => { setTitle(e.target.value) }}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                e.preventDefault()
                                if (title && (title !== "")) {
                                    this.handleSubmit();
                                }
                            }
                        }}
                        className={titleTextClasses}
                    />
                </span>
            </div>
            <button type='submit' className="buttonUX2 editClaimFormButton pull-right">Save</button>
        </form>
    );
};

EditTextForm.propTypes = {
    text: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

  
class EditTextComponent extends React.Component {
    state = {saving: false}
  
    getText = () => {
        return this.props.text;
    }

    handleClickSave = (e) => {
        console.log(e)
        const { title, ...payload } = e;
        console.log("handleClickSave: " + title);
        this.setState({ saving: true });
        // gtmEvent('Point', 'Edit Candidate', 'Candidate Claim Text Edit');
        // fbq('trackCustom', 'EditCandidateText', {
        //     url: this.point.url,
        // });
        this.props.onSubmit({title});
        // .then(null, (err) => {
        //     this.setState({ saving: false })
        //     this.props.handleError(err)
        // })
    }

    // TODO: this algorithm is a sketch, might not be ideal
    getEditTextFormHeightClass(title) {
        if (title.length > 160)
            return "sixLines";
        if (title.length > 130)
            return "fiveLines";
        if (title.length > 100)
            return "fourLines";
        if (title.length > 65)
            return "threeLines";
        else
            return "twoLines";
    }

    render() {
        let editClaimTextClasses = `claimEditArea editClaimText`;
        let label = `Edit Text`
        if (this.state.saving) {
            return <div className={editClaimTextClasses}>
                <span className="claimEditAreaSavingFeedback"><Spinner /><span className="spinnerLabel">Saving...</span></span>
            </div>;
        } else {
            return <div className={editClaimTextClasses}>
                <span className="claimEditAreaHeading">
                    <span className="heading">{label}</span>
                    <span className="editAreaClose"><a onClick={this.props.onCancel}><CloseLinkX /></a></span>
                </span>
                <EditTextForm text={this.getText()} onSubmit={this.handleClickSave} heightClass={this.getEditTextFormHeightClass(this.getText())} />
            </div>;
        }
    }
}

EditTextComponent.propTypes = {
    text: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

const FeedbackComponent = ({
    text,
    onSubmit,
    onCancel,
}) => {
    const titleTextClasses = `inputFieldUX2 titleTextField`;
    const [title, setTitle] = useState(text);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        
    });

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        console.log("Feedback Text Updated: " + title)
        onSubmit({ title })
    }
    
    let editClaimTextClasses = `claimEditArea editClaimText`;
    let label = `Feedback`

    return (
        <form onSubmit={handleSubmit} className="editPointTextForm">
            <div className="claimTitleField">
                <div className={editClaimTextClasses}>
                    <span className="claimEditAreaHeading">
                        <span className="heading">{label}</span>
                        <span className="editAreaClose"><a onClick={onCancel}><CloseLinkX /></a></span>
                    </span>
                    <textarea
                        name='feedback'
                        value={title}
                        onChange={(e) => { setTitle(e.target.value) }}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                e.preventDefault()
                                if (title && (title !== "")) {
                                    handleSubmit();
                                }
                            }
                        }}
                        className={titleTextClasses}
                    />
                    {saving ? 
                        <div className={editClaimTextClasses}>
    
                            <span className="claimEditAreaSavingFeedback"><Spinner /><span className="spinnerLabel">Saving...</span></span>
                        </div>
                    : <button type='submit' className="buttonUX2 editClaimFormButton pull-right">Save</button>}
                </div>
            </div>
        </form>
    );
};


export class CandidateClaimComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: props.candidate.text,
            adding: false,
            added: false,
            editing: false,
            editingFeedback: false,
            flaggedIrrelevant: false,
            flaggedRedundant: false,
            claimURL: null,
            claimLink: null,
            claimNewUrl: null,
        }
    }

    handleClickEdit = (e) => {
        e.stopPropagation();
        this.setState({ editing: false });
        console.log("Editing Complete: " + e)
    }

    handleClickSaveTextEdit = (values) => {
        this.setState({ editing: false })
        console.log(values)
        console.log("handleClickSaveTextEdit: " + values.title)
        // gtmEvent('Point', 'Edit Candidate', 'Candidate Claim Text Edit');
        // fbq('trackCustom', 'EditCandidateText', {
        //     url: this.point.url,
        // });
        this.setState({ text: values.title })
    }

    handleClickEditCancel = (e) => {
        e.stopPropagation();
        this.setState({ editing: false });
        console.log("Editing Canceled")
    }

    titleUI = () => {
        // const HX = `h2`
        // return <HX className="pointTitle">{this.props.candidate.text}</HX>
        if (this.state.editing) {
            return <EditTextComponent text={this.state.text} onSubmit={this.handleClickSaveTextEdit} onCancel={this.handleClickEditCancel}  />
        } else {
            const HX = `h2`
            return <HX className="pointTitle">{this.state.text}</HX>
        }
    }

    renderSource = () => {
        if (this.props.candidate.source) {
            // const HX = `h4`
            const valIconClass = this.props.candidate.sourceError ? "fa fa-exclamation-triangle" : "fa fa-check-circle"
            const errorText = this.props.candidate.sourceError
            const valIcon =  <span className="valIcon"><i className={valIconClass} aria-hidden="true" title={errorText}></i></span>
            return <div className="candidateSource">
                <div>
                    <b>Source {valIcon}:</b> {this.props.candidate.sourceName} 
                    [<a href={this.props.candidate.source} target="_blank" rel="noreferrer">{this.props.candidate.source}</a>]
                </div>
                {this.props.candidate.sourceTitle ? <div>
                    <div><b>Source Title: </b><span>{this.props.candidate.sourceTitle}</span></div>
                    <div><b>Source Summary: </b><span>{this.props.candidate.sourceSummary}</span></div>
                </div> : <></>}
                [<a onClick={this.handleClickRemoveSource} target="_blank" rel="noreferrer">Remove Source</a>]
            </div>
        } else {
            return <div/>
        }
    }

    handleClickEdit = (e) => {
        e.stopPropagation();
        const point = this.props.parentPoint;
        if (!point) {
            console.log("No point to generate from?!")
            console.log(this.props)
            return
        }
        const text = this.props.candidate.text;
        console.assert(text, "Text is undefined or null");
        const source = this.props.candidate.source;
        this.setState({ editing: true });
        console.log("Editing evidence: " + text)
    }
    
    renderEditLink = () => {
        if (this.state.editing) {
            return <b>[ Edit ]</b>;
        } else {
            return <a onClick={this.handleClickEdit}><b>[ Edit ]</b></a>;
        }
    }

    handleClickSaveTextEdit = (values) => {
        this.setState({ editing: false })
        console.log(values)
        console.log("handleClickSaveTextEdit: " + values.title)
        // gtmEvent('Point', 'Edit Candidate', 'Candidate Claim Text Edit');
        // fbq('trackCustom', 'EditCandidateText', {
        //     url: this.point.url,
        // });
        this.setState({ text: values.title })
    }

    handleClickRemoveSource = (values) => {
        // this.setState({ editing: false })
        // console.log(values)
        console.log("handleClickRemoveSource")
        this.props.candidate.source = null
        this.props.candidate.sourceName = null
        console.log("handleClickRemoveSource Done")
        // this.setState({ text: values.title })
    }

    handleClickShowFeedback = (e) => {
        e.stopPropagation();
        if (this.state.editingFeedback) {
            this.setState({ editingFeedback: false });
            console.log("Hide Feedback")
        } else {
            this.setState({ editingFeedback: true });
            console.log("Show Feedback")
        }
    }

    handleFeedbackCancel = (e) => {
        e.stopPropagation();
        this.setState({ editingFeedback: false });
        console.log("Feedback Canceled")
    }

    renderShowFeedback = () => {
        if (this.state.editingFeedback) {
            return <b>[ Feedback ]</b>;
        } else {
            return <a onClick={this.handleClickShowFeedback}><b>[ Feedback ]</b></a>;
        }
    }

    handleClickSaveFeedback = (values) => {
        // this.setState({ editing: false })
        const textFeedback = values.title
        console.log(values)
        console.log(textFeedback)
        console.log("handleClickSaveFeedback (" + this.props.candidate.id + "): " + textFeedback)
        // gtmEvent('Point', 'Edit Candidate', 'Candidate Claim Text Edit');
        // fbq('trackCustom', 'EditCandidateText', {
        //     url: this.point.url,
        // });
        // this.setState({ text: values.title })
        this.props.setFeedback(null, this.props.candidate.id, textFeedback)
        .then(
            (resp) => {
                console.log("SetFeedback Response: ")
                console.log(resp)
                this.setState({ editingFeedback: false })
            },
            (error) => {
                console.error("SetFeedback Error: " + error)
            }
        )
    }

    renderFeedback = () => {
        if (this.state.editingFeedback) {
            return <FeedbackComponent text={""} onSubmit={this.handleClickSaveFeedback} onCancel={this.handleFeedbackCancel} />
        } else {
            return null
        }
    }

    handleClickIrrelevant = (e) => {
        e.stopPropagation();
        
        console.log("setIrrelevant: " + this.props.candidate.id)
        this.props.setIrrelevant(null, this.props.candidate.id, false)
        .then(
            (resp) => {
                console.log("setIrrelevant Response: ")
                console.log(resp)
                this.setState({ editingFeedback: false })
            },
            (error) => {
                console.error("setIrrelevant Error: " + error)
            }
        )
    }

    handleClickAddEvidence = (e) => {
        e.stopPropagation();
        const point = this.props.parentPoint;
        if (!point) {
            console.log("No point to generate from?!")
            console.log(this.props)
            return
        }
        const text = this.props.candidate.text;
        console.assert(text, "Text is undefined or null");
        const source = this.props.candidate.source;
        this.setState({ adding: true, added: false });
        console.log("Adding evidence: " + text + " to " + point.url + " as " + this.props.candidate.evidenceType)
        let linkType = null;
        switch (this.props.candidate.evidenceType) {
            case "PRO":
            case "pro":
                linkType = "supporting";
                break;
            case "CON":
            case "con":
                linkType = "counter";
                break;
            default:
                console.log(this.props.candidate)
                throw new Error("Unknown evidence type: " + this.props.candidate.evidenceType);
        }
        let variables = {
            "title": text,
            "parentURL": point.url,
            "linkType": linkType,
        }
        if (source) {
            // TODO: Source title/summary pulled?
            variables["sourceURLs"] = [source]
            variables["sourceNames"] = [source]
        }
        this.props.onAdd(variables).then(
            (newPoint) => {
                console.log("AddEvidence Response: ")
                console.log(newPoint)
                this.setState({ 
                    added: true,
                    adding: false,
                    claimURL: `/claim/${newPoint.url}`,
                    claimLink: `/claim/${newPoint.url}`,
                    claimNewUrl: `${newPoint.url}`,
                 })
            },
            (error) => {
                console.log("AddEvidence Error: " + error)
            }
        )
    }

    renderAddCandidate = () => {
        if (this.state.adding) {
            return <span className="quickCreateClaimFeedback">
                <Spinner /><span className="spinnerLabel">Adding...</span>
            </span>;
        } else if (!this.state.added) {
            return <a onClick={this.handleClickAddEvidence}><b>[ Add to argument ]</b></a>
        } else {
            // TODO: Gene: Link to new claim 
            const claimUrl = this.state.claimLink;
            if (claimUrl) {
                return <a href={claimUrl} target="_blank" rel="noreferrer"><b>[ Added ]</b></a>;
            } else {
                return <b>[ Added (Unknown) ]</b>;
            }
        }
    }

    renderNewClaim = () => {
        if (this.state.added) {
            const claimUrl = this.state.claimLink;
            return <div>
                <PointListWithPoint url={claimUrl} showUpstreamLinks={false} isPrimaryClaimView={false} />
                <div>
                    <a href={"/generate/" + this.state.claimNewUrl}  target="_blank" rel="noreferrer"><b>[ Generate Child Claims ]</b></a>
                </div>
            </div> 
        } else {
            return <></>
        }
    }

    render() {
        let classesContainer = `claimTextDisplay pointCardPaddingH pointCardPaddingHExtra`
        let classesTitleSource = ``
        return <div className={classesContainer}>
            <div className={classesTitleSource}>
                <div>
                    {this.titleUI()}
                </div>
                <div>
                    <span className="editTextSpan">
                        {this.renderEditLink()}
                    </span>
                </div>
                <div>
                    {this.renderSource()}
                </div>
            </div>
            <div>
                <span className="addClaimSpan">
                    {this.renderAddCandidate()}
                </span>
                <span className="showFeedbackSpan">
                    {this.renderShowFeedback()}
                </span>
                <a onClick={this.handleClickIrrelevant} >Irrelevant</a>
                <a >Redundant</a>
                <a >Boring</a>
                <a >Not a claim </a>
            </div>
            <div>
                <span className="feedbackSpan">
                    {this.renderFeedback()}
                </span>
            </div>
            {this.renderNewClaim()}            
        </div>
    }
}

export const CandidateClaim = compose(
    graphql(schema.SetClaimCandidateIrrelevant, {
      props: ({ mutate }) => ({
        setIrrelevant: (userUrl, id, irrelevant) => mutate({variables: {userUrl, id, irrelevant}})
      })
    }),
    graphql(schema.SetClaimCandidateFeedback, {
        props: ({ mutate }) => ({
            setFeedback: (userUrl, id, feedback) => mutate({variables: {userUrl, id, feedback}})
        })
    }),
  )(CandidateClaimComponent)


export class GenerateClaimsListComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {

      }
    }
  
    prefix = () => this.props.prefix || ''
  
    renderCandidate = (candidate, i, {lastInList} = {}) => {
        const url = this.props.parentPointUrl;
        return <CandidateClaim key={`${url}_${candidate.evidenceType}_${i}`} url={url} candidate={candidate} 
            parentPoint={this.props.parentPoint} prefix={this.prefix()} lastInList={lastInList}
            onAdd={this.props.onAdd} />
    }
  
    renderPoints = (candidates) => candidates.map((candidate, i) => this.renderCandidate(candidate, i, {lastInList: (i + 1) === candidates.length}))
  
    render(){
        if (this.props.loading || (this.props.data && this.props.data.loading)) {
            return <div className="spinnerPointList"><Spinner /></div>
        } else if (this.props.generatedEvidence) {
            const ev = this.props.generatedEvidence
            if (ev) {
                console.log(ev)
                return <div>
                    <h2>Pro</h2>
                    <div className="proCandidateList">
                        {ev.proPoints && this.renderPoints(ev.proPoints)}
                    </div>
                    <h2>Con</h2>
                    <div className="conCandidateList">
                        {ev.conPoints && this.renderPoints(ev.conPoints)}
                    </div>
                </div>
            } else {
                console.log(this.props.data)
                return <div>No evidence generated</div>
            }
        } else {
            return <div/>
        }
    }
  }
  
  GenerateClaimsListComponent.propTypes = {
    parentPoint: PropTypes.object,
    prefix: PropTypes.string,
    relevanceThreshold: PropTypes.number,
    onAdd: PropTypes.func,
    generatedEvidence: PropTypes.object,
    loading: PropTypes.bool,
    hasMore: PropTypes.bool,
    infiniteScroll: PropTypes.bool,
    loadMorePoints: PropTypes.func
  }
  
export const GenerateClaimsList = compose(
    // withRouter,
    // graphql(schema.GetPoint, {
    //     skip: ({featuredPoint}) => !featuredPoint,
    //     options: ({featuredPoint}) => ({variables: {url: featuredPoint.url}})
    // })
)(GenerateClaimsListComponent);


export class GenerateClaimsComponent extends React.Component {
    state = {
        modelid: 'gpt4',
        generating: false,
        generatedEvidence: null,
        placeholderText: '',
        sourceText: '',
    }

    componentDidMount() {
        if (this.props.currentUser) {
            //   this.setState({optionsVisible: this.props.currentUser.createOptionsVisible});
        } else if (this.props.userLoading) {
            // TODO: Gene: How should we be triggering this really?
            console.log('User Loading On Mount')
        } else {
            // Should we redirect?
            this.props.showLoginDialog()
        }
    }

    handleClickGenerateEvidence = (e) => {
        e.stopPropagation();
        const point = this.props.data && this.props.data.point;
        if (!point) {
            console.log("No point to generate from?!")
            console.log(this.props.data)
            return
        }
        this.setState({ generating: true,  generatedEvidence: null });
        console.log("Generating evidence (" + point.id + "/" + this.state.modelid + "): " + point.title)
        this.props.generateEvidence(point.id, point.title, this.state.modelid).then(
            (resp) => {
                console.log("GenerateEvidence Response: ")
                console.log(resp)
                this.setState({ generatedEvidence: resp.data.generateEvidence, generating: false })
            },
            (error) => {
                console.warn("GenerateEvidence Error: " + error)
                this.props.handleError(error)
                this.setState({
                    generating: false,
                    errorMessage: "GenerateEvidence Error: " + error
                })
            }
        )
    }

    generateButton = ({ ...rest }) => {
        if (this.state.generating) {
            return <span className="quickCreateClaimFeedback">
                <Spinner /><span className="spinnerLabel">Generating claims...</span>
            </span>;
        } else {
            return <button className="buttonUX2 createClaimFormButton" type="submit" onClick={this.handleClickGenerateEvidence} {...rest}><span>Generate</span></button>;
        }
    }

    handleClickDone = (e) => {
        e.stopPropagation();
        let url = this.props.url || this.props.point.url;
        this.props.history.push(`/claim/${url}`);
    }

    handleModelidChange = (e) => {
        const val = e.target.value 
        console.log("Modelid Change: " + val)
        this.setState({modelid: val})
    }

    render() {
        const point = this.props.data && this.props.data.point;
        const pointUrl = this.props.url
        if (!this.props.userLoading && !this.props.currentUser) {
            // TODO: this.props.showLoginDialog()
            return <div>Please <a href="/Login">Login</a></div>
        }
        return <div className="mainContainer">
            <div className="infiniteWidth">
                <PointListWithPoint url={pointUrl} point={this.props.point} showUpstreamLinks={true} isPrimaryClaimView={true} />
            </div>
            <div className="generationOptions">
                <select className="model-chooser" defaultValue={"gpt4"} onChange={this.handleModelidChange}>
                    <option value="gpt4">gpt4</option>
                    <option value="gemini">gemini</option>
                    <option value="claude3">claude3</option>
                    <option value="perplexity">perplexity</option>
                    <option value="mixtral-8x7b-instruct">mixtral-8x7b-instruct</option>
                    <option value="llama-2-70b-chat">llama-2-70b-chat</option>
                </select>
            </div>
            <div className="generationButtonContainer">
                {this.generateButton()}
            </div>
            <div className="generationContainer">
                <GenerateClaimsList generatedEvidence={this.state.generatedEvidence} parentPointUrl={pointUrl} parentPoint={point} onAdd={this.props.createNewPoint} />
            </div>
            <br />
            <div className="doneContainer">
                <button className="buttonUX2 createClaimFormButton" type="submit" onClick={this.handleClickDone}><span>Done</span></button>
            </div>
        </div>
    }
}

export default compose(
  withAuth,
  withYsAlerts,
  withExpandedIndex,
  graphql(schema.GetPoint),
  graphql(schema.GenerateEvidence, {
    props: ({ mutate }) => ({
      generateEvidence: (pointID, text, modelid) => mutate({variables: {pointID, text, modelid}})
    })
  }),
  graphql(schema.NewPoint, {
    props: ({ mutate }) => ({
      createNewPoint: (variables) => mutate({variables}).
        then(({data: {newPoint}}) => newPoint && newPoint.point)
    })
  }),
)(GenerateClaimsComponent)