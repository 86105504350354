import React from 'react'
import { graphql } from '@apollo/client/react/hoc';

import * as schema from '../schema';
import { PointList } from './PointList';
import { fbq } from '../fb'

const searchResultsDefaultPageSize = 10;
const SearchResultPointList = graphql(schema.FullClaimSearch, {
  options: ({pointsPerPage, q}) => ({variables: {q: q, limit: pointsPerPage || searchResultsDefaultPageSize}}),
  props: ({ownProps: {pointsPerPage, q}, data: { loading, fullClaimSearch = {}, fetchMore}}) => ({
    q: q,
    loading: loading,
    points: fullClaimSearch.points,
    hasMore: fullClaimSearch.hasMore,
    infiniteScroll: true,
    loadMorePoints: () => {
      return fetchMore({
        query: schema.FullClaimSearch,
        variables: {q: q, cursor: fullClaimSearch.cursor, limit: pointsPerPage || searchResultsDefaultPageSize},
        updateQuery: (previousResult, { fetchMoreResult }) => {
          fetchMoreResult.fullClaimSearch.points = [...previousResult.fullClaimSearch.points, ...fetchMoreResult.fullClaimSearch.points]
          return fetchMoreResult
        }
      })
    }
  })

})(PointList)

class TagSearchResultsComponent extends React.Component {
  render() {
    let tags = Array.from(this.props.tags);
    return <div className="tagSearchResults">
      {tags && tags.length > 0 && <div>
        <div className="cardSectionHeading">
          <div className="searchResultsSectionHeading">Tags:</div>
        </div>
        <div id="tagSearchResultsArea">
          {tags && tags.map((tag, i) => <div key={i}>{this.renderTag(tag)}</div>)}
        </div>
        <div className="cardSectionHeading">
          <div className="searchResultsSectionHeading">Arguments:</div>
        </div>
      </div>}
    </div>
  }

  renderTag(tag) {
    return <div className="">
      <a className="searchResultsTag" href={"/y/" + tag.url}>#{tag.text}</a>
    </div>
  }
}

const TagSearchResults = graphql(schema.TagSearch, {
  options: ({q}) => ({variables: {q: q}}),
  props: ({ownProps: {q}, data: { loading, tagSearch = {}}}) => ({
    q: q,
    loading: loading,
    tags: tagSearch
  })
})(TagSearchResultsComponent)

class SearchResults extends React.Component {
  componentDidMount() {
    fbq('track', 'Search', {
      search_string: this.props.q,
    });
  }
  render() {
    return <div className="">
       <h1  id="searchResultsHeader" >Search Results</h1>
      <TagSearchResults q={this.props.q} />
      <div id="searchResultsArea">
        <SearchResultPointList q={this.props.q} />
      </div>
    </div>
  }
}

export default SearchResults
