import React from 'react';
import { Editor, Renderer } from './Editor';

const EditorPage = () => {
  const [rawContent, setRawContent] = React.useState(null);

  return (
    <div>
      <Editor
        onChange={val => setRawContent(val)}
      />
      <Renderer
        content={rawContent}
      />
    </div>
  );
};

export default EditorPage;
