/* eslint-disable no-unused-vars, no-undef*/

import React, {useState} from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash';
import * as schema from '../schema'
import { useForm } from 'react-hook-form'
import {Modal} from 'react-bootstrap';
import {gtmEvent} from "../gtm";
import {withYsAlerts} from "../AlertProvider";
import Spinner from "./Spinner";
import {Link} from "react-router-dom";

const LoginDialog = props => {
  const sendForgotPassword = (auth) => {
    const {forgotPassword, showInfo, handleError} = props
    if (auth && auth.length > 0) {
      forgotPassword(auth).then(
        () => showInfo('We have sent an email with a password reset link to your email address.'))
    } else {
      handleError("Please enter your email address or username.")
    }
  };

  const {close, showSignup, login, handleErrors} = props
  const {register, handleSubmit, getValues} = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const doSubmit = handleSubmit((attrs) => {
    console.log('Login Submitted..')
    setSubmitting(true)
    setErrorMessage("")
    gtmEvent('Login', 'Sign in Whysaurus User')
    login(attrs)
        .catch(({graphQLErrors}) => {
          handleErrors(graphQLErrors)
          if (graphQLErrors && graphQLErrors.length > 0) {
            console.error('Login Failed: ' + graphQLErrors[0].message)
            setErrorMessage(graphQLErrors[0].message)
          } else {
            console.error('Login Failed: Invalid Exception Error')
          }
          setSubmitting(false)
        })
        .then((res) => {
          if (!res || (!res.success || res.errorMessage)) {
            const errMsg = (res && res.errorMessage) || "Login Failed (Unspecified)"
            console.log('Login Failed: ' + errMsg)
            setErrorMessage(errMsg)
          } else {
            console.log('Login Succeeded')
            setErrorMessage("")
            window.location.reload()
          }
          setSubmitting(false)
        })
  })

  const onClickForgotPassword = () => {
    window.location.href = '/forgotPassword'
  };

  return (
    <div>
      <Modal.Header className="modalHeaderSignInWith">
        <div className="modalHeadlineCopy">Sign In</div>
        <span className="editAreaClose modalCloseX"><a onClick={close} href="/#">×</a></span>
        <div className="dialogSubHeading">
        {/*<p className="dialogSubHeading">Make your voice heard</p>*/}
        <p className="">We've recently completed an infrastructure upgrade. If you haven't logged in since June, please <a onClick={onClickForgotPassword}>reset your password</a>.</p>
        </div>
      </Modal.Header>
      <Modal.Body className="modalBodySignInWith">
        <div className="returningUserSignIn">
          <form id="frm_emailLoginDialog" className="form-horizontal" onSubmit={doSubmit} >
              <div>
                <div className="row-fluid">
                  <p className="heading">Returning Users</p>
                </div>
                <div className="control-group">
                  <label className="" htmlFor="username">
                    Username or email
                  </label>
                  <div className="controls">
                    <input id="login_userEmail" name="auth" type="text" placeholder="" ref={register} />
                  </div>
                </div>
                <div className="control-group">
                  <label className="" htmlFor="username">
                    Password
                  </label>
                  <div className="controls">
                    <input id="login_userPassword" name="password" type="password" placeholder="" ref={register} />
                  </div>
                </div>
                <div className="signInFooter">
                  {errorMessage &&
                    <div className="signInErrorMessageDiv alert alert-error">
                      <span>{errorMessage}</span>
                    </div>}
                  <div className="signInFooterItems">
                    <button type="submit" id="submit_emailLoginDialog" className="buttonUX2" disabled={submitting}>
                      Sign In
                    </button>
                     <span id="forgot_emailLoginDialog" className="" onClick={onClickForgotPassword}>
                      Forgot Password
                    </span>
                  </div>
                  {submitting && <div id="logInSpinner"><Spinner /></div>}
                </div>
              </div>
          </form>
        </div>
        <div className="loginDialogDividerLine"/>
        <div className="signInNewUsers">
            <div className="row-fluid">
              <p className="heading">New Users</p>
            </div>
            <div id="signInWithEmail_Dlg" className="buttonUX2 buttonSecondaryUX2black"><a onClick={showSignup} tabIndex="4"> Sign up with Email </a> </div>
        </div>
        <div className="loginDialogDividerLine"/>
        <div className="signWithInButtons">
            <div className="row-fluid">
              <p className="heading">OR</p>
            </div>
            <div id="signInWithGoogle_Dlg" className="buttonUX2 buttonSecondaryUX2black">
          <a tabIndex="2" href="/login/google-oauth2/">
            <img className="logoGoogle" src="/static/img/google-logo_64px.png"/>
            Continue with Google</a>
        </div>
        </div>

      </Modal.Body>
      <div className="row-fluid" id="alertArea" />
    </div>
  )
}

export default compose(
  withYsAlerts,
  graphql(schema.LogIn, {
    props: ({ mutate }) => ({
      login: (attrs) => mutate({
        variables: attrs,
        // this isn't really doing anything since we're reloading below for now, but eventually this will be the right thing to do
        // update: (proxy, {data: {logIn: { user }}}) => {
        //   console.log('Post Login Update..')
        //   proxy.writeQuery({query: schema.CurrentUserQuery, data: {currentUser: user}});
        // }
      })
        .then(res => res && res.data && res.data.logIn)
    })
  }),
  graphql(schema.ForgotPassword, {
    props: ({ mutate }) => ({
      forgotPassword: (auth) => mutate({
        variables: {auth}
      })
    })
  })
)(LoginDialog)
