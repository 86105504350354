import validator from 'validator'
export const titleMinCharacterCount = 10
export const titleMaxCharacterCount = 400

export function validateTitle(title){
  if (!title || title.trim() === '') {
    return "This point doesn't seem to have any words.";
  } else if (title.length > titleMaxCharacterCount){
    return "This is over the character limit. Try dividing your idea into multiple points. Brevity is the soul of wit!";
  } else if (title.length < titleMinCharacterCount){
    return "This is too short. Please be more specific!";
  } else {
    return null
  }
}

export const captionMaxCharacterCount = 1000

export function validateCaption(caption){
  if (caption && caption.length <3) {
    return "Please enter a longer caption."
  }
  else if (caption && caption.length > titleMaxCharacterCount){
    return "This is over the character limit."
  } else {
    return null
  }
}

export function validateSourceName(name){
  return null
}

export function validateSourceURL(url){
  if (!url) {
    return "URL is required."
  } else if (!validator.isURL(url)) {
    return "This doesn't look a valid URL."
  } else {
    return null;
  }
}

export function validateSourceUrlAllowNull(url){
  if (!url) {
    return null;
  } else {
    return validateSourceURL(url);
  }
}

export function validateSourceForEvidence(url, name){
  if (!url) {
    if (!name) {
      // Allowed for no evidence
      return null
    }
    return "URL is required."
  } else if (!validator.isURL(url)) {
    return "This doesn't look a valid URL."
  } else {
    return null;
  }
}

export function validateTagId(url){
  if (!url || !/^\w+$/.test(url)) {
    return "Only characters/numbers are permitted"
  } else {
    return null;
  }
}