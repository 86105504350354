import React, { useMemo } from 'react'
import {
  Editor as DraftEditor,
  EditorState,
  convertFromRaw,
  CompositeDecorator,
} from 'draft-js';

import { isValidContent, findMentionEntities } from './common/utils';
import Mention from './renderer/Mention';

const decorator = new CompositeDecorator([
  {
    strategy: findMentionEntities,
    component: Mention,
  },
]);

const Renderer = ({
  content,
}) => {
  const editorState = useMemo(
    () => {
      if (!isValidContent(content)) {
        return null;
      }
      const contentState = convertFromRaw(content);
      return EditorState.createWithContent(
        contentState,
        decorator,
      );
    },
    [content],
  );

  if (!editorState) {
    return null;
  }

  return (
    <div className="ys-editor-renderer">
      <DraftEditor
        readOnly
        editorState={editorState}
      />
    </div>
  );
};

export default Renderer;