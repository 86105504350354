import React from 'react';
import Form from './formNew';
import { graphql } from '@apollo/client/react/hoc';
import * as schema from '../schema';
import PropTypes from 'prop-types'

const EditEditorsPick = props => {
  const setEditorsPick = (editorsPick, sort) => {
    //notification only sent if editorsPick is changing to true
    const notify = editorsPick && !props.point.root.editorsPick
    props.setEditorsPick(props.point.id, editorsPick, sort, notify)
      .then(() => props.close())
  }

  const onSubmitEditorsPick = (values) => {
    console.log(values)
    const { editorsPick, sort } = values;
    setEditorsPick(editorsPick, sort);
  }

  const {point, close} = props

  return <div className="editEditorsPick">
    <div className="modal-header">
      <h3>Edit Editor's Pick</h3>
    </div>
    <div className="modal-body">
      <div className="model-section">
        <div className="heading">Point</div>
        {point.title}
      </div>
      <Form onSubmit={onSubmitEditorsPick} defaultValues={{sort: point.root.editorsPickSort, editorsPick: point.root.editorsPick}} >
        {() => {
          return <>
            <div className="model-section">
              <Form.Checkbox name="editorsPick" />Editor's Pick
            </div>
            <div className="model-section">
              <div className="heading">Sort</div>
              <Form.Text name="sort" />
              <button type="submit">Save</button>
            </div>
          </>
        }}
      </Form>
    </div>
    <div className="modal-footer">
      <a onClick={close}>Cancel</a>
    </div>
  </div>

}

EditEditorsPick.propTypes = {
  point: PropTypes.object.isRequired,
  setEditorsPick: PropTypes.func,
  close: PropTypes.func
}

export default graphql(schema.SetEditorsPick, {
  props: ({ mutate }) => ({
    setEditorsPick: (id, editorsPick, sort, notify) => mutate({variables: {id, editorsPick, sort, notify},
                                                              refetchQueries: [{query: schema.EditorsPicks}]})
  })
})(EditEditorsPick)
