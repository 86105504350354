import React, {Component} from 'react';

/**
 * Component that alerts if you click outside of it
 * thanks, https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */
export default class OutsideClickDetector extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.props.action()
    }
  }

  render() {
    // destructure action here to avoid passing it to the component
    const {component = 'div', children, action, ...componentProps} = this.props;
    return React.createElement(component,
                               {ref: this.wrapperRef, ...componentProps},
                               children)
  }
}
