import React from 'react'
import Form from './formNew.js'
import PropTypes from 'prop-types'

import * as validations from '../validations'

import CharCount from './CharCount'
import ClaimSuggestor from './ClaimSuggestor'
import Spinner from "./Spinner";
import {editorUtils} from "./Editor";

// use onmousedown here to try to get in before blur hides the UI (see note in TitleText onBlur below)
// TODO: think about ways to make the "suggestion UI hide" condition be "clicking on anything that is not the text input or suggestion ui itself"
// <li>example claim</li>
const ExistingClaimPicker = ({claims, onSelectClaim}) =>
  <div className="existingClaimPicker">
    <div className="existingClaimPickerHeading">Or, link an existing point:</div>
    <ul className="existingClaimList">
          {claims && claims.map((claim) => <li onMouseDown={e => onSelectClaim(claim, e)} key={claim.id}>
                                {claim.title}
                                </li>)}

    </ul>
  </div>


class TitleText extends React.Component {
  static propTypes = {
    suggestExistingClaims: PropTypes.bool,
    point: PropTypes.object,
    evidenceType: PropTypes.string,
    addExistingClaim: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.state = {titleTextFocused: false}
  }

  selectExistingClaim = (claim) =>
    this.props.addExistingClaim(this.props.evidenceType, claim)

  feedbackArea = (error, suggestions, searching) => {
    let errorClasses = `titleTextErrorArea ${error && "titleTextErrorAreaContent"}`
    let claimFeedbackAreaClasses = `claimFeedbackArea ${this.props.evidenceType==="counter" && "counter" }`
    return <div className={claimFeedbackAreaClasses}>
      <div className={errorClasses}>{error}</div>
      {suggestions && suggestions.length > 0 && <ExistingClaimPicker claims={suggestions} onSelectClaim={this.selectExistingClaim}/>}
    </div>
  }

  showFeedbackArea = (error, suggestions) =>
    this.state.titleTextFocused && (error || (this.props.suggestExistingClaims && suggestions && suggestions.length > 0 ))

  onTextAreaKeyDown = (e, title) => {
    if (e.keyCode === 13){
      e.preventDefault()
      if (title && (title !== "")) {
        this.props.onSubmit();
      }
    }
  }

  renderCountedTextField = (title, textProps, error, suggestions, searching) =>
    <CharCount countedValue={title || ""} maxChars={validations.titleMaxCharacterCount} render={({charsLeft}) => (
      <span>
        {/*<Form.TextArea*/}
        <Form.Editor
          name="title"
          field="title"
          {...textProps}
          onKeyDown={(e) => this.onTextAreaKeyDown(e, title)}
          onFocus={() => {this.setState({titleTextFocused: true})}}
          // use the setTimeout here to allow the mousedown event in existingclaimpicker to fire consistently
          // right now this fires before the onClick in ExistingClaimPicker and hides that UI before the click event can be fired
          // TODO: think about ways to make the "suggestion UI hide" condition be "clicking on anything that is not the text input or suggestion ui itself"

          // comment this out to make FeedbackArea persistant for styling
          onBlur={() => {setTimeout(() => this.setState({titleTextFocused: false}), 100)}}
          />
          <span className={"charCounter " + (charsLeft && charsLeft < 0 ? ' overMaxChars' : '')}>{charsLeft}</span>
          {searching && <Spinner />}
          {this.showFeedbackArea(error, suggestions) && this.feedbackArea(error, suggestions, searching)}
      </span>
    )}/>


  // To make error area persistant change this line:
  //   const { value, error, warning, success, setValue, setTouched } = fieldApi
  // to:
  //   let { value, error, warning, success, setValue, setTouched } = fieldApi
  //   error = "THIS IS AN ERORR"
  render(){
    // `field` is here to strip out the field prop since we set it manually
    const { field, title, point, evidenceType, addExistingClaim, suggestExistingClaims, ...restOfProps } = this.props
    // TODO: Gene: How do we handle error propagation here?
    const error = false;
    const titleText = title ? editorUtils.getTextFromRawContent(title) : ""
    // add focused prop here to make Field.shouldComponentUpdate return true when focus changes
    return <>
          <div className="claimTitleField">
            {this.props.suggestExistingClaims ? <ClaimSuggestor
                                                    query={titleText}
                                                    point={point}
                                                    evidenceType={evidenceType}
                                                    render={({suggestions, searching}) => this.renderCountedTextField(titleText, restOfProps, error, suggestions, searching)}/> :
              this.renderCountedTextField(titleText, restOfProps, error)}
          </div>
    </>
  }
}

export default TitleText
