import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Spinner from "./Spinner";
import {flowRight as compose} from "lodash";
import {graphql} from '@apollo/client/react/hoc';
import * as schema from "../schema";
import {CommentText, CommentType, QuickCommentBadgeComponent} from "./Comments";
import FontAwesomeIcon from "./FontAwesomeIcon";
import {editorUtils} from "./Editor";
import MediaQuery from "react-responsive";
import config from "../config";
import Form from "./formNew";
import {HashLink} from "react-router-hash-link";
import {withYsAlerts} from "../AlertProvider";
import {withAuth} from "./Authorization";

export class ProposedEditComponent extends React.Component {
  static propTypes = {
    editId: PropTypes.string.isRequired,
  }

  // PROPOSAL RENDERED FOR CONSIDERATION
  render(){
    const {loadingProposedEdit, proposedEdit, comment} = this.props
    if (loadingProposedEdit){
        return <div><Spinner /></div>
    }
    if (!proposedEdit) {
        // TODO: Gene: Error Handling
        return <div>INVALID</div>
    }
    let commentClasses = `commentText`
    let proposalHeadingClasses = `commentText proposalHeading`
    const badgeText = "Proposed Edit" + ((loadingProposedEdit || !proposedEdit || !proposedEdit.complete) ? "" : (proposedEdit.accept ? " - Accepted" : " - Declined"))
    return <div className="commentText proposal">
      <QuickCommentBadgeComponent quickCommentType={comment.quickCommentType} badgeText={badgeText} />
      <div className="proposalCopy">
        <div className="proposalSection">
          <span className={proposalHeadingClasses}>PROPOSED TEXT:</span>
          <div>{proposedEdit.editText}</div>
        </div>
        <div className="proposalSection">
          <span className={proposalHeadingClasses}>COMMENT:</span>
          <CommentText comment={comment} />
        </div>
        <div>
          <div className="quickCommentInstruction">This proposal can be accepted by the original poster or a moderator and should
            follow <HashLink to="/faq#HowAreClaimsScored">our community guidelines</HashLink> for proposed edits.
            Do you think it's a good proposal?</div>
        </div>
      </div>
    </div>
  }
}

export const ProposedEdit = compose(
  withYsAlerts,
  graphql(schema.ProposedEditQuery, {
    options: ({editId}) => ({
      variables: {editId}
    }),
    props: ({ownProps, data}) => {
      const {loading, error, proposedEdit} = data
      if (error) {
        ownProps.handleError(error)
      }
      return {
        ...ownProps,
        loadingProposedEdit: loading,
        proposedEdit: proposedEdit,
      }
    }
  }),
)(ProposedEditComponent)

class ProposedEditActionsComponent extends React.Component {
  static propTypes = {
    comment: PropTypes.object.isRequired,
    currentUser: PropTypes.object
  }

  state = {voting: false}

  onProposedEditVote = (vote) => {
    const {comment, currentUser, showLoginDialog, proposedEdit, proposedEditVote} = this.props
    const userUrl = currentUser && currentUser.url
    if (!userUrl) {
      showLoginDialog()
      return
    }
    const editId = comment.editId
    if (!editId) throw "Invalid editId: " + editId
    if (!proposedEdit) {
      console.log('No Existing Edit?!')
    } else if (proposedEdit.complete) {
      throw "Vote Ineligible For Complete Edit: " + editId
    } else if (currentUser && proposedEdit.creatorURL === currentUser.url) {
      console.log('Creator Vote')
    }
    console.log("Voting: " + vote)
    this.setState({voting: true})
    proposedEditVote(editId, userUrl, vote)
      .then(this.setState({voting: false}))
  }

  onApprove = () => {
    const {comment, proposedEdit, currentUser, proposedEditAccept} = this.props
    const editId = comment.editId
    if (!editId) throw "Invalid editId: " + editId
    if (!proposedEdit) throw "Unable to accept without active edit state: " + editId
    if (!currentUser) throw "Unable to accept without active user: " + editId
    console.log("Accepting Edit" + editId)
    proposedEditAccept(editId, currentUser && currentUser.url)
      // .then(this.setState({voting: false}))
  }

  onReject = () => {
    const {comment, currentUser, proposedEditReject} = this.props
    const editId = comment.editId
    if (!editId) throw "Invalid editId: " + editId
    console.log("Rejecting Edit" + editId)
    proposedEditReject(editId, currentUser && currentUser.url)
      // .then(this.setState({voting: false}))
  }

  render() {
    const {comment, loadingProposedEdit, proposedEdit, currentUser} = this.props
    const isUserComment = currentUser && proposedEdit && proposedEdit.creatorUrl === currentUser.url
    const isUserAdmin = currentUser && currentUser.admin
    const isVotingDisabled = loadingProposedEdit || (proposedEdit && proposedEdit.complete) || isUserComment
    if (comment.quickCommentType !== CommentType.PROPOSED_EDIT) return <span />
    if (this.state.voting) return <Spinner />
    if (!loadingProposedEdit && !proposedEdit) return <span className="commentActionsEdit" />

    return <span className="commentActionsEdit">
      <a className="proposedEditVoteFor" style={isVotingDisabled ? {pointerEvents: "none"} : null} onClick={() => this.onProposedEditVote(1)}><FontAwesomeIcon name="thumbsUp" type="far fa-thumbs-up" />
        {loadingProposedEdit ? <Spinner /> : <span className="proposedEditVoteCount">{proposedEdit.votesFor}</span>}
      </a>
      <a className="proposedEditVoteAgainst" style={isVotingDisabled ? {pointerEvents: "none"} : null} onClick={() => this.onProposedEditVote(-1)}><FontAwesomeIcon name="thumbsDown" type="far fa-thumbs-down" />
        {loadingProposedEdit ? <Spinner /> : <span className="proposedEditVoteCount">{proposedEdit.votesAgainst}</span>}
      </a>
      {(loadingProposedEdit || proposedEdit.complete) ? <Fragment /> :
          (isUserComment || isUserAdmin) ?
              <span>
                <a className="editAreaLink admin" onClick={this.onApprove}>Approve</a>
                {isUserAdmin ? <a className="editAreaLink admin" onClick={this.onReject}>Reject</a> : <Fragment/>}
              </span>
          : <Fragment/>}
    </span>
  }
}

export const ProposedEditActions = compose(
  graphql(schema.ProposedEditQuery, {
    options: ({comment}) => ({
      variables: {editId: comment.editId}
    }),
    props: ({ownProps, data: {loading, proposedEdit }}) => ({
      ...ownProps,
      loadingProposedEdit: loading,
      proposedEdit,
    })
  }),
  graphql(schema.ProposedEditVote, {
    props: ({ mutate }) => ({
      proposedEditVote: (editId, userUrl, vote) =>
        mutate({variables: {editId, userUrl, vote},
                refetchQueries: [{query: schema.ProposedEditQuery, variables: {editId}}]})
    })
  }),
  graphql(schema.ProposedEditAccept, {
    props: ({ mutate }) => ({
      proposedEditAccept: (editId, userUrl) => {
        return mutate({variables: {editId, userUrl},
                       refetchQueries: [{query: schema.ProposedEditQuery, variables: {editId}}]})
      }
    })
  }),
  graphql(schema.ProposedEditReject, {
    props: ({ mutate }) => ({
      proposedEditReject: (editId, userUrl) => {
        return mutate({variables: {editId, userUrl},
                       refetchQueries: [{query: schema.ProposedEditQuery, variables: {editId}}]})
      }
    })
  }),
)(ProposedEditActionsComponent)


export class NewProposedEditComponent extends React.Component {
  componentDidMount() {
    const textarea = document.getElementById('newCommentTextField')
    if (textarea) textarea.focus()
  }

  state = {submitting: false}

  submit = (values) => {
    this.setState({submitting: true});
    const payload = {
      ...values,
      richText: JSON.stringify(values.richText),
      text: editorUtils.getTextFromRawContent(values.richText),
    }
    this.props.onSubmit(payload);
  }

  submitButtonLabel = () => {
    return <span>
        <MediaQuery minWidth={config.extraSmallScreenThreshold}>
          Publish
        </MediaQuery>
        <MediaQuery maxWidth={config.extraSmallScreenThreshold}>
          <span className="">Add</span>
        </MediaQuery>
      </span>
  }

  submitButton = ({...rest}) => {
    if (this.state.submitting) {
      return <span className="newCommentFeedback">
        <Spinner /><span className="spinnerLabel">Adding your proposal...</span>
      </span>;
    } else {
      return <div>
        <button className="buttonUX2 newCommentPublishButton" type="submit" {...rest}>{this.submitButtonLabel()}</button>
        <button className="cancelButton cancelButtonNewComment" onClick={this.props.onCancel}>Cancel</button>
      </div>
    }
  }

  // PROPOSAL CREATION FORM
  render(){
    const placeholderEditText = this.props.pointText;
    return (
      <Form
        onSubmit={this.submit}
        className="newCommentCreationForm"
        defaultValues={{
          richText: {},
          editText: placeholderEditText
        }}
      >
        {({ errors }) => {
          const hasErrors = Object.keys(errors).length > 0;

          return (
            <>
              <span className="quickCommentBadgeSpan">
                <QuickCommentBadgeComponent quickCommentType={this.props.commentType} />
              </span>
              <div className="newCommentFormFieldBlock">
                <div className="formInstruction">Propose an edit. A badge will be applied to the point card. Contributors to the point will be notified. Your proposal can be accepted by the original poster or a moderator.
                    It should follow <HashLink to="/faq#HowAreClaimsScored">our community guidelines</HashLink> for proposed edits.
                </div>
                <div>
                  <label className="commentHeading">Edit the text</label>
                </div>
                <Form.TextArea
                  name="editText"
                  className="newCommentTextField inputFieldUX2"
                  placeholder={placeholderEditText}
                />
                <div>
                  <label className="commentHeading">Comment (required)</label>
                </div>
                <Form.Editor
                  name="richText"
                  placeholder={"Fixing a typo, editing for clarity, etc"}
                />
              </div>
              <div className="newCommentFormButtonBlock">
                {this.submitButton({ disabled: hasErrors && this.props.commentType === 0 })}
              </div>
            </>
          );
        }}
      </Form>
    );
  }
}
