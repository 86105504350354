import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash';

import * as schema from '../schema'
import Spinner from './Spinner'
import {withExpandedIndex} from './ExpandedIndex'
import {withRouter} from "react-router";
import {Link} from "react-router-dom";

class ModTagComponent extends React.Component {
  setActive = (e) => {
    let tag = this.props.tag
    this.props.setTagActive(tag.url, true)
  }

  setTop = (e) => {
    let tag = this.props.tag
    this.props.setTagIsTop(tag.url, true)
  }

  unsetTop = (e) => {
    let tag = this.props.tag
    this.props.setTagIsTop(tag.url, false)
  }

  render(){
    let tag = this.props.tag
    return <tr>
      <td>{tag.text}</td>
      <td><a href={"/y/" + tag.url}>{tag.url}</a></td>
      <td>{String(tag.active)}</td>
      <td>{String(tag.isTop)}</td>
      <td>{String(tag.isCategory)}</td>
      <td><a  name="setActive" onClick={this.setActive}>Set Active</a></td>
      <td><a  name="setTop" onClick={this.setTop}>Set Top</a></td>
      <td><a  name="unsetTop" onClick={this.unsetTop}>Unset Top</a></td>
    </tr>
  }
}

const ModTag = compose(
  graphql(schema.SetTagActive, {
    props: ({ mutate }) => ({
      setTagActive: (id, active) => mutate({variables: {id, active}})
    })
  }),
  graphql(schema.SetTagIsTop, {
    props: ({ mutate }) => ({
      setTagIsTop: (id, isTop) => mutate({variables: {id, isTop}})
    })
  }),
)(ModTagComponent)

class ModTagListComponent extends React.Component {
  renderTag = (tag) => {
    return <ModTag key={tag.url} tag={tag} />
  }

  renderTags = (tags) => tags.map((tag, i) => this.renderTag(tag))

  render(){
    if (this.props.tags) {
      return <div>
        <div>
          <table>
            <thead key="thead">
            <tr>
              <th>Text</th>
              <th>URL</th>
              <th>Active</th>
              <th>Top</th>
              <th>Category</th>
              <th>Set Active</th>
              <th>Set Top</th>
              <th>Unset Top</th>
            </tr>
            </thead>
            <tbody key="tbody">
              {this.renderTags(this.props.tags)}
            </tbody>
          </table>
        </div>
      </div>
    } else if (this.props.loading || (this.props.data && this.props.data.loading)) {
      return <div className="spinnerPointList"><Spinner /></div>
    } else {
      return <div></div>
    }
  }
}

const ModTagList = withRouter(ModTagListComponent);

const AllModTagsList = graphql(schema.AllTags, {
  props: ({ownProps, data: { loading, moderatorPage }}) => ({
    loading: loading,
    tags: moderatorPage && moderatorPage.tags
  })
})(ModTagList);

class ModeratorPage extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    tabIndex: 0
  }

  componentDidMount(){

  }

  addTag = (e) => {
    // e.stopPropagation();

    var tagId = prompt("New Tag Id/Url? (Case Sensitive)");
    if (tagId == null || tagId == "") {
        console.log("Tag Add Bypassed!")
        return;
    }
    console.log('Admin: Attempting To Create Tag: ' + tagId)

    this.props.addNewTag(tagId)
  }

  render(){
    return <div className="infiniteWidth">
      <div className="adminTopButtons">
        <Link to="/needsclarification">NeedsClarification Points</Link>
      </div>
      <div className="mainTagArea">
          <button id='addTag' onClick={this.addTag}>Add Tag</button>
      </div>
      <div className="tagsContentArea">
        <div id="mainPageMainArea">
          <AllModTagsList/>
        </div>
      </div>
    </div>
  }
}

export default compose(
  withExpandedIndex,
  graphql(schema.ModeratorPage),
  graphql(schema.AddNewTag, {
    props: ({ mutate }) => ({
      addNewTag: (id, isTop) => mutate({variables: {id, isTop}})
    })
  }),
)(ModeratorPage);
