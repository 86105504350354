import PropTypes from "prop-types";
import {graphql} from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import {withAuth} from "./Authorization";
import {Link, withRouter} from "react-router-dom";
import * as schema from "../schema";
import React from "react";

class TopTagsComponent extends React.Component {
  static propTypes = {
    tagsTop: PropTypes.array
  }

  renderTag = (tag) => (
    <Link to={`/y/${tag.url}`} className="tabUX2" key={tag.url}>#{tag.text}</Link>
  )

  render(){
    let tags = this.props.tagsTop;
    return <div className="fullWidth topTagsListArea">
      <div className="topTagsList">
        <Link to="/editorspicks" className="tabUX2">Editor's Picks</Link>
        {tags && tags.map((tag, i) => this.renderTag(tag))}
      </div>
    </div>
  }
}

export default compose(
  graphql(schema.TopTags, {
    props: ({ownProps, data: { loading, moderatorPage }}) => ({
      tagsTop: moderatorPage && moderatorPage.tagsTop
    })
  })
)(TopTagsComponent);