import React from 'react'
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';

import * as schema from '../schema';
import AddEvidenceForm from './AddEvidenceForm'
import Spinner from './Spinner'
import { withAuth } from './Authorization'
import { CloseLinkX } from './common'

import { fbq } from '../fb'
import {gtmEvent} from "../gtm";

class AddEvidence extends React.Component {
  state = {addingSupport: false, addingCounter: false, addingAnswer: false }

  get point() {
    return this.props.point;
  }

  get uiType(){
    return this.props.type
  }

  get adding() {
    return this.state.addingSupport || this.state.addingCounter || this.state.addingAnswer
  }

  handleClickAddEvidenceSupport = (e) => {
    if (this.props.currentUser){
      this.setState({addingSupport: true, addingCounter: false, addingAnswer: false})
    } else {
      this.props.showLoginDialog()
      gtmEvent('RequiredLogin', 'Require login add evidence supporting')
      fbq('trackCustom', 'RequiredLogin')
    }
  }

  handleClickAddEvidenceCounter = (e) => {
    if (this.props.currentUser){
      this.setState({addingSupport: false, addingCounter: true, addingAnswer: false})
    } else {
      this.props.showLoginDialog()
      gtmEvent('RequiredLogin', 'Require login add evidence counter')
      fbq('trackCustom', 'RequiredLogin')
    }
  }

  handleClickAddEvidenceAnswer = (e) => {
    if (this.props.currentUser){
      this.setState({addingSupport: false, addingCounter: false, addingAnswer: true})
    } else {
      this.props.showLoginDialog()
      gtmEvent('RequiredLogin', 'Require login add evidence counter')
      fbq('trackCustom', 'RequiredLogin')
    }
  }

  handleClickCancel = (e) => {
    e.stopPropagation();
    this.setState({addingSupport: false, addingCounter: false, addingAnswer: false})
    console.log("AddEvidenceCard : handleCancel")
  }

  handleClickSave = (evidenceType, values, e, formApi) => {
    let parentURL = this.point.url
    values.parentURL = parentURL
    values.linkType = evidenceType
    if (values.sourceUrl) {
      values.sourceURLs = [values.sourceUrl];
      console.log('Source URL: ' + values.sourceUrl);
    }
    else {
      console.log('No Source URL!');
    }
    if (values.sourceName) {
      values.sourceNames = [values.sourceName];
    }
    else if (values.sourceUrl) {
      values.sourceNames = [values.sourceUrl];
    }
    this.setState({saving: true})
    //ga('send', 'event', 'Point', 'Add ' + evidenceType + ' point', 'Point Dialog Add ' + evidenceType + ' point');
    //ga('send', 'event', 'Point Dialog', 'Publish to Library', 'Add ' + evidenceType + ' point');
    gtmEvent('Point', 'Add ' + evidenceType + ' point', 'Point Dialog Add ' + evidenceType + ' point');
    gtmEvent('Point Dialog', 'Publish to Library', 'Add ' + evidenceType + ' point');
    fbq('trackCustom', 'AddEvidence', {
      type: evidenceType,
      urlParent: parentURL,
    });
    fbq('trackCustom', 'CreateAny');
    this.props.save(parentURL, evidenceType, values).
      then( res => {
        this.setState({saving: false,
                       addingSupport: false,
                       addingCounter: false,
                       addingAnswer: false})
      })
  }

  addExistingClaim = (evidenceType, claim) => {
    let parentURL = this.point.url
    this.setState({saving: true})
    this.props.linkExisting(parentURL, evidenceType, claim.url)
        .then(res => {
          this.setState({saving: false,
            addingSupport: false,
            addingCounter: false,
            addingAnswer: false});
        })
        .catch((e) => {
          // TODO: Proper error handling/withYsAlerts notification
          console.log('AddExisting Exception: ')
          console.log(e)
        })
  }

  handleClickSaveSupport = (values, e, formApi) => {
    this.handleClickSave("supporting", values, e, formApi)
  }

  handleClickSaveCounter = (values, e, formApi) => {
    this.handleClickSave("counter", values, e, formApi)
  }

  handleClickSaveAnswer = (values, e, formApi) => {
    this.handleClickSave("answer", values, e, formApi)
  }

  renderAddEvidenceForm = (evidenceType) => {
    console.log("AddEvidenceCard : renderAddEvidenceForm : " + evidenceType)
    let progressFeedbackClasses = `progressStateFeedback ${evidenceType=="counter" && "counter"}`
    let onClickSave = evidenceType=="supporting" ? this.handleClickSaveSupport :
      (evidenceType=="counter" ? this.handleClickSaveCounter :
        (evidenceType=="answer" ? this.handleClickSaveAnswer :
          null))
    let offsetClasses = ""
    return <span>
      { this.state.saving ? <div className={progressFeedbackClasses}><Spinner />Saving...</div> :
      <span className={offsetClasses}>
        <AddEvidenceForm point={this.point}
                         evidenceType={evidenceType}
                         addExistingClaim={this.addExistingClaim}
                         onSubmit={onClickSave}
                         onCancel={this.handleClickCancel}/>
      </span>}
    </span>
  }

  addText = (evidenceType) => {
    switch (evidenceType){
      case "supporting":
        return "Add Evidence For"
      case "counter":
        return "Add Evidence Against"
      case "answer":
        return "Add Answer"
      default:
        return "Add Evidence"
    }
  }

  numSupportingPlusCounter = () => {
    return (this.point.numSupporting + this.point.numCounter)
  }

  renderAddEvidenceFormBasedOnState = () => {
    if (this.state.addingSupport) {
      return <span>
        {this.renderAddEvidenceForm("supporting")}
      </span>
    } else if (this.state.addingCounter) {
      return <span>
        {this.renderAddEvidenceForm("counter")}
      </span>
    } else if (this.state.addingAnswer) {
      return <span>
        {this.renderAddEvidenceForm("answer")}
      </span>
    }
    else return (null)
  }

  // consider + icon: <i className="icon fas fa-plus"></i>
  renderAddEvidenceButton = (evidenceType) => {
    let classesButton = `buttonUX2 ${evidenceType=="counter" ? "buttonSecondaryUX2red" : "buttonSecondaryUX2black"}  buttonUX2marginR`
    let nameButton = `${evidenceType=="counter" ? "addCounterEvidenceButton" : "addSupportingEvidenceButton" }`
    let buttonLabel = this.addText(evidenceType)
    let classesAnchor = "buttonUX2responsiveStack"
    let onClick = evidenceType == 'supporting' ? this.handleClickAddEvidenceSupport :
      evidenceType == 'counter' ? this.handleClickAddEvidenceCounter :
      evidenceType == 'answer' ? this.handleClickAddEvidenceAnswer : null
    return <a className={classesAnchor} onClick={onClick}>
      <button type="button" name={nameButton} tabIndex="0" className={classesButton}>{buttonLabel}</button>
    </a>
  }

  renderSupportButton = () => this.renderAddEvidenceButton("supporting")

  renderCounterButton = () => this.renderAddEvidenceButton("counter")

  renderAnswerButton = () => this.renderAddEvidenceButton("answer")

  renderDualButtons = () => <span className="dualButtons">
    {this.renderAddEvidenceButton("supporting")}
    {this.renderAddEvidenceButton("counter")}
  </span>

  // Removing for now, but might bring back later -JF
  renderAddEvidencePrompt = () => {
    if (this.numSupportingPlusCounter() > 0) {
      return <div className="addEvidencePrompt">Add to this list</div>
    }
  }

  renderEvidenceArrow = () => {
    let isCounter = (this.state.addingCounter || this.uiType == "COUNTER")
    let arrowGrpClass = `arrowEvidence`
    let arrowHeadClass = `arrowHeadUp ${isCounter && "counter"}`
    let arrowStemClass = `arrowStemEvidence ${(this.numSupportingPlusCounter() == 0) && "arrowStemEvidenceShort" } ${isCounter && "counter"}`
    return <div className={arrowGrpClass}>
      <div className={arrowStemClass}></div>
      <div className={arrowHeadClass}></div>
    </div>
  }

  render() {
    let topDivClass = `addEvidenceUI`
    let controlsAreaClass = `addEvidenceControlsArea ${(this.numSupportingPlusCounter() == 0) && "addEvidenceControlsAreaNoEvidence"}`
    switch (this.uiType) {
    case "DUAL":
      topDivClass = topDivClass + ` buttonsDual`
      return <div className={topDivClass}>
        { this.renderEvidenceArrow() }
        <span className={controlsAreaClass}>
          { (this.adding) ? this.renderAddEvidenceFormBasedOnState() : <span className="addEvidenceButtonsArea">{this.renderDualButtons()}</span> }
        </span>
      </div>
    case "SUPPORT":
      topDivClass = topDivClass + `  buttonsSupport`
      return <div className={topDivClass}>
        { this.renderEvidenceArrow() }
        <span className={controlsAreaClass}>
         { this.state.addingSupport ? this.renderAddEvidenceForm("supporting") : <span className="addEvidenceButtonsArea">{this.renderSupportButton()}</span> }
        </span>
      </div>
    case "COUNTER":
      topDivClass = topDivClass + `  buttonsCounter`
      return <div className={topDivClass}>
        { this.renderEvidenceArrow() }
        <span className={controlsAreaClass}>
          { this.state.addingCounter ? this.renderAddEvidenceForm("counter") :  <span className="addEvidenceButtonsArea">{this.renderCounterButton()}</span> }
        </span>
      </div>
    case "ANSWER":
      topDivClass = topDivClass + `  buttonsAnswer`    
      return <div className={topDivClass}>
        { this.renderEvidenceArrow() }
        <span className={controlsAreaClass}>
          { this.state.addingAnswer ? this.renderAddEvidenceForm("answer") :  <span className="addEvidenceButtonsArea">{this.renderAnswerButton()}</span> }
        </span>
      </div>
    default:
      console.log("AddEvidenceCard : render() : something's wrong!")
      return <div className={topDivClass}>
      </div>
    }
  }
}

function evidenceTypeToPoints(evidenceType) {
  switch (evidenceType) {
    case "supporting":
      return "supportingPoints"
    case "counter":
      return "counterPoints"
    case "answer":
      return "answerPoints"
    default:
      console.error('Invalid EvidenceType: ' + evidenceType)
      return "supportingPoints"
  }
}

function updateGetPointWithEdges(proxy, url, evidenceType, edges) {
  const data = proxy.readQuery({ query: schema.GetPoint, variables: {url: url} })
  data.point.relevantPoints.edges = edges.edges.concat(data.point.relevantPoints.edges)
  let points = data.point[evidenceTypeToPoints(evidenceType)]
  points.edges = edges.edges.concat(points.edges)
  proxy.writeQuery({ query: schema.GetPoint,
                     variables: {url: url},
                     data: data })
}

export default compose(
  graphql(schema.AddEvidenceQuery, {
    props: ({mutate}) => ({
      save: (parentURL, evidenceType, values) => mutate({
        variables: values,
        refetchQueries: [{query: schema.GetPoint, variables: {url: parentURL}}],
        // update: (proxy, { data: { addEvidence: { newEdges } }}) =>
        //  updateGetPointWithEdges(proxy, parentURL, evidenceType, newEdges)
      })
    })
  }),
  graphql(schema.LinkPoint, {
    props: ({mutate}) => ({
      linkExisting: (parentURL, evidenceType, url) => mutate({
        variables: {parentURL: parentURL, linkType: evidenceType, url: url},
        refetchQueries: [{query: schema.GetPoint, variables: {url: parentURL}}],
        // update: (proxy, { data: { linkPoint: { newEdges } }}) =>
        //   updateGetPointWithEdges(proxy, parentURL, evidenceType, newEdges)
      })
    })
  }),
  withAuth
)(AddEvidence)
