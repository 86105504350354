import React from 'react';
import 'url-search-params-polyfill'

import SearchResults from './SearchResults';

export default class SearchPage extends React.Component {
  render(){
    const params = new URLSearchParams(this.props.location.search)
    return <div id="mainContainer">    
      <div className="row pointPageContainer infiniteWidth">
        <div id="infiniteOrFiniteWidth" className="">
          <SearchResults q={params.get("q")}/>
        </div>
      </div>
    </div>
  }
}
