import React from 'react';
import PropTypes from 'prop-types'
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { withYsAlerts } from './../AlertProvider'

import * as schema from '../schema';
import {CloseLinkX, Hover, HoverTip, timestampLastEdit} from './common'
import NewComment from './NewComment'
import {NewProposedEditComponent, ProposedEdit, ProposedEditActions} from './ProposedEdit'
import {Author, PointTypeQuestion} from "./Point";
//import NewQuickComment from './NewQuickComment'
import { withAuth } from "./Authorization";
import {gtmCustom, gtmEvent} from '../gtm'
import { fbq } from '../fb'
import Spinner from "./Spinner";
import FontAwesomeIcon from './FontAwesomeIcon';
import { Renderer } from './Editor';

export const CommentType = Object.freeze({
    OTHER: 0,
    EVIDENCE:  1,
    CLARIFICATION: 2,
    PROPOSED_EDIT: 4,
    QUESTION: 5,
});
// export const CommentType = Object.freeze({
//   CLARIFICATION: Symbol("clarification"),
//   EVIDENCE:  Symbol("needs_evidence"),
//   OTHER: Symbol("other")
// });

// TODO: Ideally, this should store both of the inputs taken by <FontAwesomeIcon>: "type" as well as "name"
export const needsEvidenceIcon = "balance-scale-right";
export const needsClarificationIcon = "pen-nib";
export const questionIcon = "question-circle";
export const proposedEditIcon = "edit";

// TODO: Gene: What's the right way to share this between the backend schema? Should it be a lookup?
export const QuickCommentType = [
  { id: 0, text: "", icon: null }, // CC_NONE
  { id: 1, text: "Needs Evidence", icon: needsEvidenceIcon }, // CC_NEEDS_EVIDENCE
  { id: 2, text: "Needs Clarification", icon: needsClarificationIcon }, // CC_NEEDS_CLARIFICATION
  { id: 4, text: "Proposed Edit", icon: proposedEditIcon }, // CC_PROPOSED_EDIT
  { id: 5, text: "Question", icon: questionIcon }, // CC_PROPOSED_EDIT
];

export class QuickCommentBadgeComponent extends React.Component {
  static defaultProps = {
    badgeText: null
  }

  toText(ccType) {
    if (!(ccType > 0)) {
      // Handle error?
      return ""
    }
    const val = QuickCommentType.find(x => x.id == ccType)
    if (val === undefined) {
      // Handle error?
      return ""
    }
    return val.text
  }

  toIcon(ccType) {
    if (!(ccType > 0)) {
      // Handle error?
      return null
    }
    const val = QuickCommentType.find(x => x.id == ccType)
    if (val === undefined) {
      // Handle error?
      return null
    }
    return val.icon
  }

  // // Removed the quick Add/Remove for now
  // handleRemoveQuickComment = (e) => {
  //   const {id, pointUrl} = this.props
  //   e.stopPropagation()
  //   // console.log("RemoveQuickComment: " + id + " Point: " + pointUrl)
  //   this.props.removeQuickComment(id, pointUrl).then(
  //       (success) => {
  //         console.log("remove quick comment success")
  //       },
  //       (error) => {
  //         // TODO: Actual error handling!
  //         console.error("Remove quick comment error: " + error)
  //       })
  // }
  //
  // handleAddQuickComment = (e) => {
  //   const {id, pointUrl} = this.props
  //   e.stopPropagation()
  //   // console.log("AddQuickComment: " + id + " Point: " + pointUrl)
  //   this.props.addQuickComment(id, pointUrl).then(
  //       (success) => {
  //         console.log("Add quick comment success")
  //       },
  //       (error) => {
  //         // TODO: Actual error handling!
  //         console.error("Remove quick comment error: " + error)
  //       })
  // }

  handleQuickCommentClick = (e) => {
    e.stopPropagation();
    if (this.props.onClickComments) {
      this.props.onClickComments(e);
      gtmEvent('CommentView', 'QuickCommentClick', this.props.quickCommentType);
      fbq('track', 'QuickCommentClick', {
        quickCommentType: this.props.quickCommentType,
      });
    } else {
      console.warn('No onClickComments for expansion');
    }
  }

  // TO DO: make logic to check quickCommentType more efficient -JF
  render(){
    let classesQuickCommentBadge = `quickCommentBadge ${this.props.isUserComment && "myQuickComment"}`
    let quickCommentBadgeIcon = this.toIcon(this.props.quickCommentType) ?? needsClarificationIcon
    const text = this.props.badgeText ?? this.toText(this.props.quickCommentType);
    return <Hover delay="1" onHover={<HoverTip title='Click To Discuss' />}>
      <span className={classesQuickCommentBadge} onClick={this.handleQuickCommentClick}>
        <span className="icon">
          <FontAwesomeIcon name={quickCommentBadgeIcon} />
        </span>
        <span className="quickCommentBadgeText">{text}</span>
        {this.props.count > 1 && <span className="number quickCommentBadgeCount">{this.props.count}</span>}
        {/*{this.props.isUserComment &&  <a onClick={this.handleRemoveQuickComment} data-toggle="tooltip" title="Remove My Comment"><span className="quickCommentBadgeClose"><CloseLinkX/></span></a>}*/}
        {/*{this.props.isUserComment == false && <a onClick={this.handleAddQuickComment} data-toggle="tooltip" title="+1 this Comment"><span className="fas fa-plus" aria-hidden="true" /></a>}*/}
      </span>
    </Hover>;
  }
}

const QuickCommentBadge = compose(
  graphql(schema.RemoveQuickComment, {
    props: ({ mutate }) => ({
      removeQuickComment: (id, pointUrl) => mutate({variables: {id},
        refetchQueries: [{query: schema.GetPoint, variables: {url: pointUrl}}]})
    })
  }),
  graphql(schema.AddQuickComment, {
    props: ({ mutate }) => ({
      addQuickComment: (id, pointUrl) => mutate({variables: {id},
        refetchQueries: [{query: schema.GetPoint, variables: {url: pointUrl}}]})
    })
  }),
)(QuickCommentBadgeComponent)

export class QuickCommentBadgeList extends React.Component {
  render() {
    const {aggregateComments, pointUrl} = this.props
    return <div className="quickCommentsList">
      {aggregateComments && aggregateComments.filter(x => x.quickCommentType !== CommentType.QUESTION)
          .map(x => <QuickCommentBadge key={x.id} id={x.id} quickCommentType={x.quickCommentType} count={x.countUnresolved} isUserComment={x.isUserComment} pointUrl={pointUrl} onClickComments={this.props.onClickComments} />)}
    </div>;
  }
}

export class CommentText extends React.Component {
  static propTypes = {
    comment: PropTypes.object.isRequired
  }

  render(){
    const {comment} = this.props
    let commentClasses = `commentText`
    const {text, richText} = comment
    const rawContent = JSON.parse(richText || null);
    return <div>
      {rawContent
      ? (
        <div className={commentClasses}>
          <Renderer content={rawContent} />
        </div>
      ) : (
        <div className={commentClasses}>{text}</div>
      )}
    </div>
  }
}

export class CommentComponent extends React.Component {
  static propTypes = {
    comment: PropTypes.object.isRequired,
    addReply: PropTypes.func,
    replies: PropTypes.array
  }

  state = {replying: false, resolving: false}

  handleClickReply = () => {
    if (this.props.currentUser) {
      this.setState({replying: true})
    } else {
      this.props.showLoginDialog()
    }
  }

  handleClickResolve = () => {
    this.setState({resolving: true})
    this.props.resolve()
      .then(this.setState({resolving: false}))
  }

  commentActions = () => {
    const {currentUser, showLoginDialog, comment, replies, addReply, archive} = this.props
    const isUserComment = currentUser && currentUser.url === comment.userUrl
    const isUserAdmin = currentUser && currentUser.admin
    if (this.props.comment.level === 0){
      if (this.state.replying) {
        return <NewComment parent={comment} onSubmit={({ text, richText }) => addReply(text, richText).then(() => this.setState({replying: false}))} onCancel={() => this.setState({replying: false})} />
      } else {
        let classes = `commentBottomRowActions ${comment.resolved ? "commentResolvedActions" : ""}`
        // ${replies ? "commentIndent" : ""}
        return <div className={classes}>
          {comment.editId && <ProposedEditActions comment={comment} currentUser={currentUser} showLoginDialog={showLoginDialog} />}
          {!comment.archived && <a className="editAreaLink" onClick={this.handleClickReply}>Reply</a>}
          {/*TODO: Support for OP as well*/}
          {this.state.resolving && <div><Spinner /><div className="editAreaLink">Resolving..</div></div>}
          {(isUserComment || isUserAdmin) && !comment.resolved && !this.state.resolving &&
            <Hover delay="1" onHover={<HoverTip title='Mark complete and remove warning badge' />}>
              <a className="editAreaLink admin" onClick={this.handleClickResolve}>Resolve</a>
            </Hover>}
          {isUserAdmin && !comment.archived && <a className="editAreaLink admin" onClick={archive}>Archive</a>}
        </div>
      }
    }
  }

  handleClickReportComment = (e) => {
    const {comment, point} = this.props
    const {id, text} = comment
    e.stopPropagation()
    const r = window.confirm(`Are you sure you want to REPORT comment '${text}'?  Moderators will be notified that this point might be spam, trolling or other bad stuff.`)
    if (r === true) {
      this.props.reportComment(id, point.id).then(
        (success) => {
          console.log("report comment success")
          this.props.alert.show(`Comment has been reported — '${text}'`)
        },
        (error) => {
          console.log("report comment error: " + error)
        })
    }
  }

  render(){
    const {comment, replies, point} = this.props
    const {userName, userUrl, date, text, richText} = comment
    const rawContent = JSON.parse(richText || null);
    let classes = `comment ${(this.props.comment.level > 0) ? "commentIndent" : ""} ${comment.resolved ? "commentResolved" : ""}  ${comment.archived && "commentArchived"}`
    let commentClasses = `commentText`
    return <div className={classes}>
      { (this.props.comment.level === 0) && <div className="divider"></div> }
      <div className="byline">
        <Author userUrl={userUrl} userName={userName} />&nbsp;&nbsp;&nbsp;·&nbsp;
        {timestampLastEdit(date, false)}·&nbsp;&nbsp;
        <a onClick={this.handleClickReportComment}><span className="reportCommentIcon easierToClickOn" title="Report this Comment"><FontAwesomeIcon name="flag" /></span></a>
        { comment.resolved && <span>&nbsp;·&nbsp;<span className="cardTopRowItem cardTopRowItemExtraMargin easierToClickOn ">Resolved</span></span> }
        { comment.archived && <span>&nbsp;·&nbsp;<span className="cardTopRowItem cardTopRowItemExtraMargin easierToClickOn ">Archived</span></span> }
      </div>
      {(comment.quickCommentType > 0 && !comment.editId) && <div><QuickCommentBadge quickCommentType={comment.quickCommentType} /></div>}
      {comment.editId ?
          <ProposedEdit editId={comment.editId} comment={comment} /> :
          <CommentText comment={comment} />}
      {replies && replies.sort((a, b) => a.date > b.date).map(reply => <Comment key={reply.id} comment={reply} point={point}/>)}
      {this.commentActions()}
    </div>
  }
}

const Comment = compose(
  withYsAlerts,
  withAuth,
  graphql(schema.ReportComment, {
    props: ({ mutate }) => ({
      reportComment: (id, pointId) => mutate({variables: {id, pointId}})
    })
  })
)(CommentComponent)

export class CommentsListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commenting: false,
      commentType: CommentType.OTHER
    }
  }

  static propTypes = {
    pointUrl: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    add: PropTypes.func.isRequired,
    toggleArchived: PropTypes.func.isRequired,
    comments: PropTypes.array,
    isRelevance: PropTypes.bool,
    wideCard: PropTypes.bool
  }

  static defaultProps = {
    isRelevance: false
  }

  buildRepliesIndex = (comments) => comments && comments.reduce((a, c) => {
    const parentID = c.parentID
    if (parentID) {
      const r = (a[parentID] || [])
      r.push(c)
      a[parentID] = r
    }
    return a
  }, {})

  onAddContinue = () => {
    this.setState({commenting: false})
    //ga('send', 'event', 'Comment', 'Submit Comment', this.pointUrl);
    gtmEvent('Comment', 'Submit Comment', this.pointUrl);
    fbq('trackCustom', 'Comment', {
      url: this.pointUrl,
    });
    console.log('GA Comment Event Sent')
  }

  onNewCommentClick = (commentType) => {
    if (this.props.currentUser){
      this.setState({commenting: true, commentType: commentType})
    } else {
      console.log('Login Required To Comment')
      this.props.showLoginDialog()
      gtmEvent('RequiredLogin', 'Require login create comment')
      fbq('trackCustom', 'RequiredLogin');
    }
  }

  onNewQuickCommentClick = (commentType, eventName) => {
    if (!this.props.currentUser){
      console.log('Login Required To Comment')
      this.props.showLoginDialog()
      gtmEvent('RequiredLogin', 'Require login create comment')
      fbq('trackCustom', 'RequiredLogin');
      return
    }
    if (eventName) {
      gtmEvent('QuickCommentClick', eventName, this.pointUrl)
    }
    this.setState({commenting: true, commentType: commentType})
  }

  newComment = () => {
    const {pointUrl, add, addProposedEdit} = this.props;
    if (!pointUrl) throw "newComment: null pointUrl";
    const isClaimComment = !this.props.isRelevance && (this.props.pointType !== 2);
    if (this.state.commenting) {
      if (this.state.commentType === CommentType.PROPOSED_EDIT) {
        return <NewProposedEditComponent
            pointUrl={pointUrl} commentType={this.state.commentType} pointText={this.props.pointText}
            onSubmit={({text, richText, editText}) => addProposedEdit(pointUrl, text, richText, editText).then(this.onAddContinue)}
            onCancel={() => this.setState({commenting: false})} />
      } else {
        return <NewComment pointUrl={pointUrl} commentType={this.state.commentType}
                           onSubmit={({text, richText}) => add(pointUrl, text, richText, null, this.state.commentType).then(this.onAddContinue)}
                           onCancel={() => this.setState({commenting: false})}/>
      }
    } else {
      return <span>
        <span className="commentButtons">
          <div className="claimEditAreaNote">Help us make this point more effective</div>
          <button className="buttonSecondaryUX2blue commentButton" onClick={() => this.onNewCommentClick(CommentType.OTHER, "qcOther")}><FontAwesomeIcon name="comment" type="far" />Comment</button>
          {isClaimComment && <button className="buttonSecondaryUX2blue commentButton" onClick={() => this.onNewQuickCommentClick(CommentType.EVIDENCE, "qcEvidence")}><FontAwesomeIcon name={needsEvidenceIcon} />Needs Evidence</button>}
          {isClaimComment && <button className="buttonSecondaryUX2blue commentButton" onClick={() => this.onNewQuickCommentClick(CommentType.CLARIFICATION, "qcClarification")}><FontAwesomeIcon name={needsClarificationIcon} />Needs Clarification</button>}
          {isClaimComment && <button className="buttonSecondaryUX2blue commentButton" onClick={() => this.onNewCommentClick(CommentType.PROPOSED_EDIT, "qcProposeEdit")}><FontAwesomeIcon type="far" name={proposedEditIcon} />Propose Edit</button>}
          <button className="buttonSecondaryUX2blue commentButton" onClick={() => this.onNewCommentClick(CommentType.QUESTION, "qcAskQuestion")}><FontAwesomeIcon name={questionIcon} />Ask A Question</button>
          {/*<button className="buttonSecondaryUX2blue commentButton" onClick={() => this.onNewCommentClick(CommentType.OTHER)}>Other Note</button>*/}
        </span>
      </span>
    }
  }


  toggleArchivedControl = () => {
    const {showArchived, toggleArchived} = this.props
    if (showArchived) {
      return <span><div className="divider"></div><a className="editAreaLink" onClick={toggleArchived}>Hide Archived Comments</a></span>
    } else {
      return <span><div className="divider"></div><a className="editAreaLink" onClick={toggleArchived}>Show Archived Comments</a></span>
    }
  }

  closeCommentButton = () => {
    if (this.props.isRelevance) {
      return <div className="noCommentsAreaClose"></div>
    }
    return <span className="editAreaClose"><a onClick={this.props.onCancel}><CloseLinkX/></a></span>
  }

  header = () => {
    if (this.props.isRelevance) {
      return <span className="heading">COMMENTS ON THIS LINK</span>
    } else if (this.props.pointType === PointTypeQuestion) {
      return <span className="heading">COMMENTS ON THIS QUESTION</span>
    } else {
      return <span className="heading">COMMENTS ON THIS POINT</span>
    }
  }

  //JF: trying without this for now, could bring it back <div className="claimEditAreaNote">Discuss how to improve this claim.</div>
  render(){
    const {comments, loadingComments, pointUrl, add, onCancel, hasArchived, archive, resolve, aggregateComments, aggregateCommentCount} = this.props
    const replies = this.buildRepliesIndex(comments)
    return <div>
      <span className="claimEditAreaHeading">
        {this.header()}
        {this.closeCommentButton()}
      </span>
      {this.newComment()}
      <div className="commentsList">
        {loadingComments && <Spinner />}
        {comments && comments.filter(comment => comment.level === 0).map(comment =>
          <Comment key={comment.id} comment={comment} pointUrl={pointUrl} replies={replies[comment.id]}
                   addReply={(text, richText) => add(pointUrl, text, richText, comment.id, 0)}
                   archive={() => archive(pointUrl, comment.id)}
                   resolve={() => resolve(pointUrl, comment.id)} />)}
      </div>
      {hasArchived && this.toggleArchivedControl()}
    </div>
  }
}


const CommentsList = compose(
  withAuth,
  graphql(schema.Comments, {
    options: ({pointUrl, showArchived}) => ({
      variables: {pointID: pointUrl, showArchived: !!showArchived}
    }),
    props: ({ownProps, data: {loading, comments, refetch}}) => ({
      ...ownProps,
      loadingComments: loading,
      comments: comments && comments.comments,
      aggregateComments: comments && comments.aggregateComments,
      aggregateCommentCount: comments && comments.aggregateComments && comments.aggregateComments.length || 0,
      hasArchived: comments && (comments.archivedCount > 0),
      refetchComments: refetch
    })
  }),
  graphql(schema.NewComment, {
    props: ({ mutate, ownProps: {showArchived} }) => ({
      add: (pointID, text, richText, parentCommentID, quickCommentType) => {
        return mutate({variables: {pointID, text, richText, parentCommentID, quickCommentType: quickCommentType},
                       refetchQueries: [{query: schema.Comments, variables: {pointID, showArchived: !!showArchived}}]})
      }
    })
  }),
  graphql(schema.ProposeEdit, {
    props: ({ mutate, ownProps: {showArchived} }) => ({
      addProposedEdit: (pointUrl, text, richText, editText) => {
        return mutate({variables: {pointUrl, text, richText, editText},
                       refetchQueries: [{query: schema.Comments, variables: {pointID: pointUrl, showArchived: !!showArchived}}]})
      }
    })
  }),
  graphql(schema.ArchiveComment, {
    props: ({ mutate, ownProps: {showArchived} }) => ({
      archive: (pointID, commentID) =>
        mutate({variables: {pointID, commentID},
                refetchQueries: [{query: schema.Comments, variables: {pointID, showArchived: !!showArchived}}]})
    })
  }),
  graphql(schema.ResolveComment, {
    props: ({ mutate, ownProps: {showArchived} }) => ({
      resolve: (pointID, commentID) =>
        mutate({variables: {pointID, commentID},
                refetchQueries: [{query: schema.Comments, variables: {pointID, showArchived: !!showArchived}}]})
    })
  })
)(CommentsListComponent)

export default class Comments extends React.Component {
  static propTypes = {
    point: PropTypes.object.isRequired,
    indent: PropTypes.bool,
    wideCard: PropTypes.bool,
    onCancel: PropTypes.func.isRequired
  }

  state = {showArchived: false}

  toggleShowArchived = () => {
    this.setState({showArchived: !this.state.showArchived})
  }

  render(){
    let classesCommentsIndent = `${this.props.indent ? "addOneIndent" : ""} `
    let classesCommentDiv = `row-fluid claimEditArea pointCardPaddingH commentsArea hideBorderTop ${this.props.wideCard ? "wideCard": ""}`
    return <div className={classesCommentsIndent}>
      <div className={classesCommentDiv} onClick={e => e.stopPropagation()}>
        <CommentsList pointUrl={this.props.point.id}
                      pointType={this.props.point.pointType}
                      pointText={this.props.point.title}
                      onCancel={this.props.onCancel}
                      showArchived={this.state.showArchived}
                      toggleArchived={this.toggleShowArchived}
                      wideCard={this.props.wideCard} />
      </div>
    </div>
  }
}
