// Opera 8.0+
const isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]" 
const isSafari = /constructor/i.test(window.HTMLElement) || ((p) => p.toString() === "[object SafariRemoteNotification]")(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 79
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Edge (based on chromium) detection
const isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

const browsers = {
  opera: "Opera",
  firefox: "Firefox",
  safari: "Safari",
  internetExplorer: "InternetExplorer",
  edge: "Edge",
  chrome: "Chrome",
  edgeChromium: "EdgeChromium",
};

const currentBrowser = {
  [isOpera]: browsers.opera,
  [isFirefox]: browsers.firefox,
  [isSafari]: browsers.safari,
  [isIE]: browsers.internetExplorer,
  [isEdge]: browsers.edge,
  [isChrome]: browsers.chrome,
  [isEdgeChromium]: browsers.edgeChromium,
}.true;

export {currentBrowser, browsers}

