import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DraftEditor from 'draft-js-plugins-editor';
import { EditorState, convertToRaw } from 'draft-js';

import useMentions from './editor/useMentions';

import 'draft-js/dist/Draft.css';
import 'draft-js-mention-plugin/lib/plugin.css';

const Editor = ({
  onChange,
  placeholder,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const { mentionPlugin, suggestions, onSearchChange } = useMentions();
  const { MentionSuggestions } = mentionPlugin;

  const handleChange = (newEditorState) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    onChange(rawContent);
  };

  return (
    <div className="ys-editor">
      <DraftEditor
        editorState={editorState}
        onChange={handleChange}
        plugins={[mentionPlugin]}
        placeholder={placeholder}
      />
      <MentionSuggestions
        onSearchChange={onSearchChange}
        suggestions={suggestions}
      />
    </div>
  );
};

Editor.defaultProps = {
  placeholder: '',
};

Editor.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default Editor;