import React from 'react'
import TimeAgo from 'react-timeago'

export const CloseLinkX  = () => <span className="closeLinkX">&#xd7;</span>

// thanks, https://stackoverflow.com/questions/29981236/how-do-you-hover-in-reactjs-onmouseleave-not-registered-during-fast-hover-ove
export const Hover = ({ onHover, delay="", children }) => (
  <span className="hover">
    <span className="hover__no-hover">{children}</span>
    <span className={"hover__hover" + delay + " tipBringToFront"}>{onHover}</span>
  </span>
)

export const HoverTip = ({title}) => (
  <div className="hoverTip">
    <span>{title}</span>
    <div className="hoverTip-caret"><div className="caret-outer"></div></div>
  </div>
)

const timeAgoFormatter = (value, unit, suffix, date) => {
  let unitMap = {"second": "s", "minute": "m", "hour": "h", "day": "d", "week": "w", "month": "mo", "year": "y"}
  return <span><span className="number">{value}</span>{unitMap[unit]}</span>
}

const dateOnly = (date) => {
  date = date.replace(/-/g, "\u2011")  //nonbreaking hyphens, so tooltip doesn't end up being 2 lines
  let i = date.indexOf("T")
  if (i !== -1)
    return date.slice(0, i)
  else
    return date
}

export const timestampLastEdit = (date, showAsChanged) => {
  let timestampTooltip = `Last\xa0Updated\xa0${dateOnly(date)}`
  let timestampClasses = `easierToClickOn ${showAsChanged ? "bylineItemHighlighted recentlyUpdated" : ""}`
  return <Hover delay="1" onHover={<HoverTip title={timestampTooltip}/>}>
    <span className={timestampClasses}><TimeAgo date={date + "Z"} minPeriod={300} formatter={timeAgoFormatter} title=""/></span>
  </Hover>
}
