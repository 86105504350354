import React from 'react'
import {graphql} from '@apollo/client/react/hoc'
import {flowRight as compose} from 'lodash';
import {withRouter} from "react-router-dom";
import * as schema from '../schema'
import {withReactAlerts} from "./withReactAlerts";
import {withYsAlerts} from "../AlertProvider";
import Form from "./formNew";

const ForgotPasswordPage = props => {
  const {forgotPassword, alert, handleErrors} = props;

  const onForgotPassword = (values) => {
    return forgotPassword(values)
      .then(() => alert.show("We have sent you an email with a password reset link.  Please check your email."))
      .catch(({graphQLErrors}) => handleErrors(graphQLErrors))
  }

  return (
    <div id="mainContainer">
      <div className="fullPageForm">
        <Form
          onSubmit={onForgotPassword}
          className="settingsForm resetPasswordForm"
          validationMode="onSubmit"
        >
          {() => {
            return (
              <div>
                  <h2 className="fullPageFormHeading">Reset Password</h2>
                <div className="formInstruction">
                  Enter your username or the email you used to create your account. A link to reset your password will be emailed to you.
                </div>
                <div className="formInstruction">
                  If you haven’t visited Whysaurus recently your password may need be reset due to a recent infrastructure upgrade.
                </div>
                <div>
                  <Form.Text
                    id="changePassword_userName"
                    name="auth"
                    placeholder=""
                    label="Username or Email"
                  />
                </div>
                <div>
                  <input id="submit_changePasswordButton" className="buttonUX2 buttonLower" type="submit"
                         value="Reset Password"/>
                </div>
              </div>
            )
          }}
        </Form>
      </div>
    </div>
  )
}

export default compose(
  withReactAlerts,
  withYsAlerts,
  withRouter,
  graphql(schema.ForgotPassword, {
    props: ({mutate}) => ({
      forgotPassword: ({auth}) => mutate({
        variables: {auth}
      })
    })
  })
)(ForgotPasswordPage)
