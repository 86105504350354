import React from 'react'
import {Notification} from './Notifications'
import { withAuth } from './Authorization'
import { graphql } from '@apollo/client/react/hoc'
import { flowRight as compose } from 'lodash';
import * as schema from '../schema'
import Spinner from "./Spinner";

const renderNotificationsSection = (notifications) => {
  return <ul id="notificationsAllArea">
    {notifications && notifications.map((notification, i) => (
      <Notification notification={notification} key={i}/>
    ))}
  </ul>
}

const renderNotificationsOrSpinner = (loading, notifications) => {
  if (loading) {
    return <Spinner />
  } else {
    return renderNotificationsSection(notifications)
  }
}

const NotificationsPage = ({loading, error, notifications}) => (
  <div id="mainContainer">
    <div id="notificationsPage" className="fullPageForm">
      <h2 className="fullPageFormHeading">Notifications</h2>
      {renderNotificationsOrSpinner(loading, notifications)}
      </div>
    </div>
)

export default compose(
  graphql(schema.Notifications, {
    props: ({data: {loading, error, notifications}}) => ({loading, error, notifications})
  })
)(NotificationsPage)